import { useState, useEffect } from "react";
import { Button } from "Components/Common/Button/Button";
import { EMAIL_INVALID } from "./ErrorMsgs";
import { useLoginContext } from "Context/Login";
import { InputField } from "Components/Common/InputField/InputField";
import { SendLogInReq } from "Services/API/Login.service";
import { ReactComponent as EmailIcon } from "Assets/Mail.svg";
import { ReactComponent as PasswordIcon } from "Assets/Password.svg";
import { FORGOT_PASSWORD_VIEW, VERIFICATION_VIEW } from "Constants/Views";
import ClassNames from "Helpers/Common";
import { ApiError } from "Components/Common/ApiError";
import "Colors.css";

export default function SignIn() {
  const { setActiveView, setCredentials } = useLoginContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setIsDisabled(!(email && password));
  }, [email, password]);
  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
      setIsDisabled(true);
    };
  }, []);
  const handleSubmit = () => {
    if (email && password) {
      setIsLoading(true);
      SendLogInReq({
        email: email,
        password: password,
      })
        .then((res) => {
          const { authCode } = res;

          setCredentials({ email, password, verification: authCode });
          console.log(`[SendLogInReq][API_RESPONSE]`, authCode);
          setIsLoading(false);
          setActiveView(VERIFICATION_VIEW);
        })
        .catch((err) => {
          setIsDisabled(true);
          setIsLoading(false);
          setApiError(err?.response?.data?.errors);
          console.error(
            "[SendLogInReq][API_ERROR]  - ",
            err?.response?.data?.errors || err
          );
        });
    }
  };
  return (
    <>
      <div>
        <span className="sapphire mt-6 text-3xl font-extrabold cursor-pointer">
          Log In
        </span>
        <br />
        <div className="text-blue-chill text-left font-medium w-72 cursor-pointer">
          Enter email and password to login to your dashboard
        </div>
      </div>
      <div className="pt-4">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      </div>

      <div className="lg:space-y-2 2xl:space-y-4 mt-8">
        <InputField
          id="email"
          type="email"
          label="Email"
          isRequired={true}
          onChange={(fieldValue) => setEmail(fieldValue.trim())}
          onBlur={(fieldValue, setInputFieldError) => {
            if (!(fieldValue.includes("@") && fieldValue.includes(".com"))) {
              setInputFieldError(EMAIL_INVALID);
              setIsDisabled(true);
            } else if (fieldValue) {
              setEmail(fieldValue.trim());
            }
          }}
        >
          <EmailIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />
        </InputField>
        <InputField
          id="password"
          label="Password"
          type="password"
          isRequired={true}
          onChange={(fieldValue) => {
            setPassword(fieldValue ? fieldValue : "");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && email && password) {
              handleSubmit();
            }
          }}
        >
          <PasswordIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />
        </InputField>
        <span className="flex items-center justify-end">
          <span
            onClick={() => setActiveView(FORGOT_PASSWORD_VIEW)}
            className="text-sm font-medium text-gray-400 hover:text-blue-light cursor-pointer"
          >
            Forgot your password?
          </span>
        </span>

        <Button
          btnText="Continue"
          className={ClassNames("w-full py-2", !isDisabled && "text-white")}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
