import { InputField } from "Components/Common/InputField/InputField";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { formatDate } from "Helpers/DateFormat";
import { Divider } from "Components/Common/Divider/Divider";
import { MedMalPracticeInsuranceForm } from "Components/Provider/AddProvider/Forms/MedMalpracticeInsForm/Form";
import { useState } from "react";

export const EditForm = ({ providerFormDetail, setProviderFormDetail }) => {
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <div className="flex flex-col gap-3">
      {providerFormDetail.length > 0 &&
        providerFormDetail.map((form, index) => (
          <div className="grid grid-cols-2 gap-6 pt-3" key={index}>
            <span className="col-span-2 font-semibold text-base sapphire">
              Policy {index + 1}
            </span>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                id={`company-name-${index}`}
                label="Name of Company"
                value={form?.companyName || form?.nameOfCompany}
                onBlur={(fieldValue) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            companyName: fieldValue && fieldValue.trim(),
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                id={`policy-name-${index}`}
                label="Agent Issuing Policy Name"
                value={form?.policyName || form?.agentIssuingPolicyName}
                onBlur={(fieldValue) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            policyName: fieldValue && fieldValue.trim(),
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                id={`policy-number-${index}`}
                label="Policy Number"
                value={form?.policyNumber || form?.agentIssuingPolicyNumber}
                onBlur={(fieldValue) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            policyNumber: fieldValue && fieldValue.trim(),
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <DatePicker
                label="Current Expiration Date"
                value={form?.expiryDate || form?.currentExpirationDate}
                onBlur={(expDate) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            expiryDate:
                              expDate && formatDate(expDate, "MM/DD/YYYY"),
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
            <div className="col-span-2">
              <UploadFile
                mode="Edit"
                id={`certificate-${index}`}
                value={
                  form?.certificate || form?.malpracticeCertificateDocument
                }
                label="Malpractice Certificate/Verification"
                onResponse={({ data }) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            certificate: data,
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
          </div>
        ))}
      {Array(count)
        .fill("")
        .map((_, index) => (
          <MedMalPracticeInsuranceForm
            key={providerFormDetail.length + 1}
            id={providerFormDetail.length + 1}
            postfix={providerFormDetail.length + 1}
            formDetail={providerFormDetail}
            setFormDetail={(forms) => {
              setProviderFormDetail(forms);
              setCount(0);
            }}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setProviderFormDetail(
                providerFormDetail.filter(
                  (formInstance) => formInstance.id !== id
                )
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}
      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
