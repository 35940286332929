import { useState } from "react";
import { ReactComponent as SearchIcon } from "Assets/Search.svg";
import ClassNames from "Helpers/Common";

export default function SearchBar({
  onFocus,
  onSearch,
  onKeyDown,
  searchQuery,
  searchResults,
  setSearchQuery,
  label = "Search",
  setSearchResults,
  filterKey = false,
  isFiltered = false,
  placeholder = "Search",
  setIsSearchFieldFocused,
  isHealthConditionSearch = false,
}) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  return (
    <div className="w-full">
      <label htmlFor={label} className="sr-only">
        Search
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={({ target: { value } }) => {
            setSearchQuery(value);
            setShowSuggestions(true);
          }}
          className="block w-full bg-white border border-gray-300 rounded-md py-1 pl-10  pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={placeholder}
          type="search"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setShowSuggestions(false);
            }
            onKeyDown?.(e);
          }}
          onFocus={() => {
            setShowSuggestions?.(true);
            setIsSearchFieldFocused?.(true);
            onFocus?.();
          }}
          onBlur={() => {
            setShowSuggestions(false);
            setIsSearchFieldFocused?.(false);
          }}
        />
        {showSuggestions && searchResults?.length > 0 && (
          <div
            className={ClassNames(
              "absolute mt-1 flex flex-col gap-2 bg-white shadow-xl z-20 p-2  w-64 h-44 overflow-y-auto",
              isHealthConditionSearch ? "w-full" : "w-64",
            )}
          >
            {searchResults?.map((user, index) => (
              <div
                key={index}
                className="text-sm cursor-pointer  hover:bg-gray-200"
                onMouseDown={() => {
                  onSearch?.(isHealthConditionSearch ? user : user?.id);
                  setSearchResults([]);
                }}
              >
                {isHealthConditionSearch ? (
                  user?.name + "   (" + `${user?.ICDCode ?? "no-code"}` + ")"
                ) : (
                  <>
                    {!isFiltered && user?.username}
                    {isFiltered &&
                      filterKey.includes("userName") &&
                      `${user?.username ?? ""}`}
                    {isFiltered &&
                      filterKey.includes("phoneNumber") &&
                      `${user?.phoneNumber ?? ""}`}

                    {isFiltered &&
                      filterKey.includes("id") &&
                      `${user?.cognitoId ?? ""}`}
                    {isFiltered &&
                      filterKey.includes("name") &&
                      `${user?.firstName + " " + user?.lastName}`}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
