import { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { ReactComponent as DollarIcon } from "Assets/Dollar.svg";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { GetMembershipPlans } from "Services/API/Membership.Service";
import { TableHeading } from "Components/Provider/ProviderMainPage/TableHeading/TableHeading";
import { PriceUpdateModal } from "./PriceUpdateModal";

const HEADINGS = ["Name", "Price"];

export const MembershipListing = () => {
  const [memberships, setMemberships] = useState([]);
  const [priceModal, setPriceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planId, setPlanId] = useState("");

  const getMembershiPlans = () => {
    setLoading(true);

    GetMembershipPlans()
      .then((res) => {
        setMemberships([...res?.data]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("[GetMembershipPlans][API_ERROR] ===>", err);
        setLoading(false);
      });
    return () => {
      setMemberships([]);
    };
  };
  useEffect(() => {
    getMembershiPlans();
  }, []);

  return (
    <div className="p-4">
      {loading && <Spinner />}

      {!loading && (
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            !loading && memberships.length > 0 ? (
              <TableBody
                data={memberships}
                setPriceModal={setPriceModal}
                setPlanId={setPlanId}
              />
            ) : (
              !loading && "No Data Found"
            )
          }
        />
      )}
      {priceModal && (
        <PriceUpdateModal
          onSuccess={() => getMembershiPlans()}
          setPriceModal={setPriceModal}
          modalOpen={priceModal}
          planId={planId}
        />
      )}
    </div>
  );
};

const TableBody = ({ data, setPriceModal, setPlanId }) => {
  return data?.map((item, index) => (
    <tr key={index}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm eclipse">{item.name}</div>
      </td>
      <td className="px-6 py-4 flex items-center justify-between whitespace-nowrap">
        <div className="flex items-center text-sm eclipse">
          <DollarIcon className="w-5 h-5 light_grayish_navy pointer-events-none" />
          {item.price}
        </div>
        <div
          className="text-sm text-blue-chill cursor-pointer"
          onClick={() => {
            setPlanId(item.id);
            setPriceModal(true);
          }}
        >
          Update Pricing
        </div>
      </td>
    </tr>
  ));
};
