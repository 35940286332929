import { PieChart, Pie, Cell } from "recharts";

const COLORS = ["#DDEAEB", "#000000", "#37828B"];

export function PieCharts({ data, width, height, innerRadius, outerRadius }) {
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        dataKey={data.id}
      >
        {data.map((_, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}
