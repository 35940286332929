import Axios from "Services/Auth/AxiosInterceptors";
import axios from "axios";
const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};
const DownloadFileConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, HEAD, POST",
    "Access-Control-Max-Age": "3000",
  },
};

export const UploadFiles = async (payload) => {
  return await Axios.post(`doctor/doctor/upload-file`, payload, config);
};
export const UploadOtherFile = async (payload) => {
  return await Axios.post(`/customfile`, payload);
};
export const DownloadFile = async (url) => {
  return await axios.get(
    url,
    {
      responseType: "blob",
    },
    DownloadFileConfig
  );
};
