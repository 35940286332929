import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as HealthIcon } from "Assets/HealthCondition.svg";
import { InputField } from "Components/Common/InputField/InputField";
import { useState } from "react";
import { useEffect } from "react";

export const MedReorderModal = ({
  isModalOpen = false,
  isLoading = false,
  medConditionValue,
  onConfirm,
  onCancel,
}) => {
  const [medConditionName, setMedConditionName] = useState("");
  useEffect(() => {
    if (medConditionValue) {
      setMedConditionName(medConditionValue);
    }
    if (medConditionValue === 0) {
      setMedConditionName("0");
    }
  }, [medConditionValue]);
  return (
    <ModalBasic
      icon={<HealthIcon className="text-blue-chill bg-white" />}
      btnText="Confirm"
      heading="Medical Health Condition"
      content="Type in a serial number to change the order of a medical health condition"
      open={isModalOpen}
      loading={isLoading}
      setOpen={(value) => {
        if (value === "Confirm") {
          onConfirm?.(medConditionName);
        } else {
          onCancel?.();
        }
      }}
      isDisabled={!(medConditionName.length > 0)}
    >
      <InputField
        id="Medical Condition Name"
        placeholder="Serial Number"
        isRequired={true}
        value={medConditionName}
        onChange={(value) => {
          setMedConditionName(value);
        }}
      />
    </ModalBasic>
  );
};
