import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { editTabIndexes, tabIndexes } from "Constants/Provider";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  GetGeneralInformation,
  UpdateGeneralInformation,
} from "Services/API/ProviderEditView.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { formatDate } from "Helpers/DateFormat";

const formatDetails = (data) => {
  return {
    firstName: data.firstName || "-",
    lastName: data.lastName || "-",
    gender: data.gender || "-",
    userName: data.username || "-",
    DOB: data.birthdate || "",
    NPI: data.NPI || "-",
    providerType: data.typeOfProvider || "-",
    file: data.resume || "",
    referenceName1: data.referenceName1 || "-",
    referenceName2: data.referenceName2 || "-",
    referenceName3: data.referenceName3 || "-",
    referenceNumber1: data.referenceNumber1 || "-",
    referenceNumber2: data.referenceNumber2 || "-",
    referenceNumber3: data.referenceNumber3 || "-",
    clinicianID: data.dosespotId || "",
  };
};

export const GeneralInfoForm = ({ setActiveTabIndex }) => {
  const { getProviderData } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = getProviderData();
  useEffect(() => {
    if (id) {
      setLoading(true);
      GetGeneralInformation(id)
        .then((res) => {
          setLoading(false);
          console.log("General info res is", res, id);
          setProviderDetail(formatDetails(res));
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetGeneralInformation][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    } else {
      // TODO: Proper Fallback after confirming with the UX
      <span>Can not retrieve data at the moment</span>;
    }
  }, [id]);
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={isEditMode ? "Save" : "Edit Profile"}
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                if (isEditMode) {
                  setIsLoading(true);

                  UpdateGeneralInformation(id, {
                    firstName: providerDetail.firstName,
                    lastName: providerDetail.lastName,
                    birthdate: formatDate(providerDetail.DOB, "MM/DD/YYYY"),
                    NPI: providerDetail.NPI,
                    typeOfProvider: providerDetail.providerType,
                    referenceName1: providerDetail.referenceName1,
                    referenceNumber1: providerDetail.referenceNumber1,
                    referenceName2: providerDetail.referenceName2,
                    referenceNumber2: providerDetail.referenceNumber2,
                    referenceName3: providerDetail.referenceName3,
                    referenceNumber3: providerDetail.referenceNumber3,
                    resume: providerDetail.file,
                    gender: providerDetail.gender,
                    dosespotId: providerDetail.clinicianID,
                  })
                    .then((res) => {
                      if (res) {
                        setActiveTabIndex(editTabIndexes?.STATE_MED_LIC);
                        setIsLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.error(
                        "[UpdateGeneralInformation][API_ERROR] - ",
                        err
                      );
                      setIsLoading(false);
                      setApiError(err?.response?.data?.errors || err?.message);
                    });
                } else {
                  setIsEditMode(true);
                }
              }}
            />
          </div>
        )}
        <br />
        {isEditMode ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
    </>
  );
};
