import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import Table from "Components/Common/Table/Table";
import { useProviderContext } from "Context/Provider";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { ReactComponent as StarIcon } from "Assets/Star.svg";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { FiltersRow, Header, patientRecordFormatter } from "./helpers";
import { GetPatientsRecord } from "Services/API/ProviderEditView.service";
import {
  VISIT_FEEDBACK_VIEW,
  VISIT_TABLE_VIEW,
  VISIT_NOTES_VIEW,
  VISIT_ORDER_VIEW,
} from "Components/Patients/EditViewPatient/Forms/VisitTable/ConstantsViews";
import { VisitFeedback } from "Components/Patients/EditViewPatient/Forms/VisitTable/VisitFeedback";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { VisitNotes } from "Components/Patients/EditViewPatient/Forms/VisitTable/VisitNotes";
import { VisitOrder } from "Components/Patients/EditViewPatient/Forms/VisitTable/VisitOrder";
import "Colors.css";

const ACTIVE_VISITS = "Active Visits";
const SIGNED = "Signed";
const UNSIGNED = "Unsigned";
const ALL = "All";
const NAME = "name";
const EMAIL = "username";
const FILTERS_INIT_STATE = {
  name: true,
  userName: false,
  all: true,
  signed: false,
  unsigned: false,
};

export const PatientRecordTable = () => {
  const { getProviderData } = useProviderContext();
  const [activeTab, setActiveTab] = useLocalStorage(
    "providerVisits",
    ACTIVE_VISITS
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [providerId, setProviderId] = useState("");
  const [filterView, setFilterView] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [patientNotes, setPatientNotes] = useState({});
  const [patientOrders, setPatientOrders] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [patientsRecord, setPateintsRecord] = useState([]);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [filter, setFilters] = useState(FILTERS_INIT_STATE);
  const [patientFeedback, setPatientFeedback] = useState({});
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [currentView, setCurrentView] = useState(VISIT_TABLE_VIEW);
  const [isPatientSelected, setIsPatientSelected] = useState(false);

  const getPatientRecords = (enterPressed = false) => {
    if (searchQuery && searchQuery.length >= 1 && !isPatientSelected) {
      GetPatientsRecord(providerId, pageNumber, 50, {
        visitStatus: activeTab === ACTIVE_VISITS ? "Inprogress" : "Closed",
        visitFilter: filter?.signed ? SIGNED : filter.unsigned ? UNSIGNED : ALL,
        queryString: searchQuery ? searchQuery : searchQuery,
        criteria: filter?.userName ? EMAIL : NAME,
      })
        .then((res) => {
          if (enterPressed) {
            setSearchResults([]);
            setPateintsRecord(patientRecordFormatter(res?.data));
          } else {
            setSearchResults(patientRecordFormatter(res?.data));
            setPateintsRecord(patientRecordFormatter(res?.data));
          }
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetPatientsRecord][SEARCH][API_ERROR] => ", err);
          setApiError(err?.response?.data?.errors || err?.message);
        });
    }

    if (!searchQuery) {
      setLoading(true);
      setIsPatientSelected(false);
      GetPatientsRecord(providerId, pageNumber, 50, {
        visitStatus: activeTab === ACTIVE_VISITS ? "Inprogress" : "Closed",
        visitFilter: filter?.signed ? SIGNED : filter.unsigned ? UNSIGNED : ALL,
        queryString: "",
        criteria: filter?.userName ? EMAIL : NAME,
      })
        .then((res) => {
          setPateintsRecord(patientRecordFormatter(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
          setShowFilterRow(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetPatientsRecord][API_ERROR] => ", err);
          setApiError(err?.response?.data?.errors || err?.message);
        });
    }

    return () => {
      setPateintsRecord([]);
    };
  };

  useEffect(() => {
    const { id, name } = getProviderData();
    setProviderId(id);
    setProviderName(name);
  }, []);
  useEffect(() => {
    if (providerId) {
      getPatientRecords();
    }
  }, [providerId, pageNumber, activeTab, searchQuery, JSON.stringify(filter)]);

  useEffect(() => {
    if (isPatientSelected) {
      setSearchQuery(
        filter?.name
          ? patientsRecord?.[0]?.fullName
          : patientsRecord?.[0]?.username
      );
    }
  }, [isPatientSelected]);
  return (
    <div className="bg-white flex flex-col gap-3">
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}

      {showFilterRow && currentView === VISIT_TABLE_VIEW && (
        <FiltersRow
          filter={filter}
          activeTab={activeTab}
          setFilters={setFilters}
          filterView={filterView}
          searchQuery={searchQuery}
          setFilterView={setFilterView}
          searchResults={searchResults}
          setSearchQuery={setSearchQuery}
          setSearchResults={setSearchResults}
          setSelectedPatient={setPateintsRecord}
          setIsPatientSelected={setIsPatientSelected}
          onTabChange={(tab) => setActiveTab(tab)}
          onEnterPressed={() => {
            getPatientRecords(true);
          }}
        />
      )}
      <div
        className="h-fulls overflow-y-auto"
        onClick={() => setFilterView(false)}
      >
        {currentView === VISIT_TABLE_VIEW && !loading && (
          <Table
            heading={<Header />}
            body={
              !loading && patientsRecord?.length > 0 ? (
                <PatientTable
                  patientsRecord={patientsRecord}
                  setCurrentView={setCurrentView}
                  setPatientNotes={setPatientNotes}
                  setPatientOrders={setPatientOrders}
                  setPatientFeedback={setPatientFeedback}
                />
              ) : (
                !loading && patientsRecord?.length === 0 && "No Data Found"
              )
            }
          />
        )}
        {currentView === VISIT_FEEDBACK_VIEW && (
          <div className="mt-5">
            <VisitFeedback
              docName={providerName}
              patientFeedback={patientFeedback}
              setActiveView={setCurrentView}
            />
          </div>
        )}
        {currentView === VISIT_NOTES_VIEW && (
          <div className="mt-5">
            <VisitNotes
              docName={providerName}
              patientNotes={patientNotes}
              setActiveView={setCurrentView}
            />
          </div>
        )}
        {currentView === VISIT_ORDER_VIEW && (
          <div className="mt-5">
            <VisitOrder
              docName={providerName}
              patientOrders={patientOrders}
              setActiveView={setCurrentView}
            />
          </div>
        )}
      </div>
      {loading && <Spinner />}
      {!loading &&
        patientsRecord?.length > 0 &&
        currentView === VISIT_TABLE_VIEW && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
    </div>
  );
};

const PatientTable = ({
  patientsRecord,
  setCurrentView,
  setPatientOrders,
  setPatientNotes,
  setPatientFeedback,
}) => {
  return patientsRecord?.map((patient, index) => (
    <tr key={index}>
      <td className="px-6 py-4 whitespace-nowrap eclipse flex flex-col cursor-pointer">
        <span> {patient.fullName}</span>
        <span className="bali_hai"> {patient.email}</span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
        <span> {patient.startDate}</span>
        <br />
        <span className="bali_hai"> {patient.startTime}</span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
        <span> {patient.endDate}</span>
        <br />
        <span className="bali_hai"> {patient.endTime}</span>
      </td>
      <td
        className={ClassNames(
          "px-4 inline-flex text-sm leading-5  rounded-full mt-4",
          patient?.signed
            ? "bg-green-200 text-green-cyan"
            : "bg-gray-100 text-blue-medium"
        )}
      >
        {patient?.signed ? "Signed" : "Unsigned"}
      </td>
      <td
        onClick={() => {
          if (patient?.orders.length > 0) {
            setPatientOrders(patient?.orders);
            setCurrentView(VISIT_ORDER_VIEW);
          }
        }}
        className={ClassNames(
          "px-6 py-4 whitespace-nowrap text-sm  cursor-pointer",
          patient?.orders.length > 0
            ? "text-blue-chill"
            : "text-blue-medium cursor-not-allowed"
        )}
      >
        Order
      </td>
      <td
        onClick={() => {
          if (patient?.notes?.notes?.id) {
            setPatientNotes(patient?.notes);
            setCurrentView(VISIT_NOTES_VIEW);
          }
        }}
        className={ClassNames(
          "px-6 py-4 whitespace-nowrap text-sm  cursor-pointer",
          patient?.notes?.notes?.id
            ? "text-blue-chill"
            : "text-blue-medium cursor-not-allowed"
        )}
      >
        Notes
      </td>
      <td
        onClick={() => {
          if (patient?.feedback.length > 0) {
            setPatientFeedback(patient?.feedback);
            setCurrentView(VISIT_FEEDBACK_VIEW);
          }
        }}
        className={ClassNames(
          "px-6 py-4 whitespace-nowrap text-sm  cursor-pointer",
          patient?.feedback.length > 0
            ? "text-blue-chill"
            : "text-blue-medium cursor-not-allowed"
        )}
      >
        <div
          className={ClassNames(
            "flex items-center justify-center gap-1",
            patient?.feedback.length > 0
              ? "border-b border-blue-chill rounded-none p-1"
              : ""
          )}
        >
          {patient?.feedback?.[0]?.rating ?? "0"}/5{" "}
          {patient?.feedback.length <= 0 ? (
            <StarIcon className="h-5 w-5 text-gray-200" />
          ) : (
            <StarIcon className="h-5 w-5 text-blue-chill " />
          )}
        </div>
      </td>
    </tr>
  ));
};
