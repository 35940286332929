export const MED_CONDITIONS_HEADINGS = [
  "S.No",
  "Medical Health Condition",
  "ICD - 10",
  "Status",
  "Actions",
  " ",
  " ",
];
export const MED_CONDITIONS_DROPDOWN_OPTIONS = [
  { title: "Activate" },
  { title: "Deactivate" },
];
