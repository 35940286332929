import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      {providerFormDetail.length > 0 &&
        providerFormDetail.map((form, index) => (
          <>
            <span className="col-span-2 font-semibold text-base light_grayish_navy">
              Fellowship {index + 1}
            </span>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Specialty</span>
              <span className={SUB_HEADING_CSS}>{form?.speciality}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Program Name</span>
              <span className={SUB_HEADING_CSS}>{form?.programName}</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Program Street Address</span>
              <span className={SUB_HEADING_CSS}>{form?.streetAddress}</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Program City</span>
              <span className={SUB_HEADING_CSS}>{form?.city}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Zip Code</span>
              <span className={SUB_HEADING_CSS}>{form?.zipCode}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Fellowship Program Director</span>
              <span className={SUB_HEADING_CSS}>{form?.directorName}</span>
            </div>
            <div className="flex flex-col col-span-2 gap-2">
              <UploadFile
                mode="View"
                value={form?.certificate}
                label="Fellowship Certificate"
              />
            </div>
          </>
        ))}
    </div>
  );
};
