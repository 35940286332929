import { MembershipListing } from "Components/Membership/membershipListing";
import { FreeVisits } from "Components/Membership/freeVisits"
import MainLayout from "Layouts/MainLayout/MainLayout";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import ClassNames from "Helpers/Common";

const MEMBERSHIP_LISTING = "MembershipList"
const FREE_VISITS = "FreeVisits"

export const MembershipPricing = () => {
  const [activeTab, setActiveTab] = useLocalStorage("membershipTable", MEMBERSHIP_LISTING);
  return (
    <MainLayout>
      <div className="p-8 flex flex-col gap-8">
        <Header
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
        />
        {activeTab === MEMBERSHIP_LISTING && (
          <MembershipListing/>
        )}
        {activeTab === FREE_VISITS && (
          <FreeVisits/>
        )}
      </div>
    </MainLayout>
  );
};

const Header = ({
  activeTab,
  onTabChange,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-6">
        {" "}
        <span className="font-semibold text-2xl sapphire">Membership</span>
        <span
          className={ClassNames(
            "light_grayish_navy text-sm font-medium sapphire cursor-pointer text-center mt-1 py-2 px-2 rounded-md",
            activeTab === MEMBERSHIP_LISTING && "bg-gray-100 font-semibold text-black"
            )}
          onClick={() => {
          onTabChange(MEMBERSHIP_LISTING);
        }}
          >
          Memberships
        </span>
        <span
          onClick={() => {
          onTabChange(FREE_VISITS);
        }}
          className={ClassNames(
            "light_grayish_navy text-sm font-medium cursor-pointer mt-1 py-2 px-2 rounded-md",
            activeTab === FREE_VISITS && "bg-gray-100 font-semibold text-black"
            )}
          >
          Free Visits
        </span>
      </div>
    </div>
    );
};