import { useState } from "react";
import { formatDate } from "Helpers/DateFormat";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import UploadOtherFiles from "Components/Common/UploadOtherFiles/UploadOtherFiles";
import { Divider } from "Components/Common/Divider/Divider";

export const EditForm = ({
  providerFormDetail,
  setProviderFormDetail,
  otherDocuments,
  setOtherDocuments,
}) => {
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="col-span-2 lg:col-span-1">
        <DatePicker
          label="Background Check Start Date"
          value={providerFormDetail?.backgroundCheckDate}
          onBlur={(checkDate) => {
            setProviderFormDetail({
              ...providerFormDetail,
              backgroundCheckDate:
                checkDate && formatDate(checkDate, "MM/DD/YYYY"),
            });
          }}
        />
      </div>

      <fieldset className="mt-2 space-y-6 col-span-2">
        <div className="space-y-2">
          <label className="font-semibold sapphire">
            Background Check Passed
          </label>
          <RadioButton
            id="1"
            label="Yes"
            value={true}
            isSelected={!!providerFormDetail?.securityCheckPassed === true}
            onChange={() => {
              setProviderFormDetail({
                ...providerFormDetail,
                securityCheckPassed: true,
              });
            }}
          />
          <RadioButton
            id="2"
            label="No"
            value={false}
            isSelected={!!providerFormDetail?.securityCheckPassed === false}
            onChange={() => {
              setProviderFormDetail({
                ...providerFormDetail,
                securityCheckPassed: false,
              });
            }}
          />
        </div>
      </fieldset>

      <div className="col-span-2">
        <UploadFile
          mode="Edit"
          id="background-authorization"
          value={providerFormDetail?.authorizationDocument}
          label="Background Authorization"
          onResponse={({ data }) => {
            setProviderFormDetail({
              ...providerFormDetail,
              authorizationDocument: data,
            });
          }}
        />
      </div>
      {providerFormDetail?.otherDocuments?.map((docs, index) => (
        <div className="col-span-2">
          <UploadFile
            mode="View"
            id={docs.id}
            value={docs.url}
            label={docs.name}
          />
        </div>
      ))}
      {otherDocuments?.map(
        (doc) =>
          doc?.completed && (
            <div className="flex flex-col col-span-2 gap-2">
              <UploadFile mode="View" value={doc?.url} label={doc.fileName} />
            </div>
          )
      )}
      {otherDocuments?.map((doc, index) => {
        if (!doc.completed) {
          return (
            <div className="col-span-2" key={count + index}>
              <UploadOtherFiles
                key={`new-${index}`}
                id={doc?.key}
                files={otherDocuments}
                fileName={doc?.fileName}
                fileUrl={doc?.url}
                setFiles={setOtherDocuments}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                title="Other Documents"
                placeHolder="Title"
                onResponseFileAdd={({ data }, id) => {
                  if (data) {
                    setOtherDocuments(
                      otherDocuments?.map((userDetail) =>
                        userDetail.key === id
                          ? {
                              ...userDetail,
                              id: data || "",
                              completed: true,
                            }
                          : userDetail
                      )
                    );

                    setIsLoading(false);
                  }
                }}
                handleCancelFile={(value) => {
                  let removedDocument = otherDocuments.filter(
                    (doc) => doc.key !== value
                  );
                  setOtherDocuments([...removedDocument]);
                }}
              />
            </div>
          );
        }
      })}
      <div className="col-span-2">
        <Divider
          onClick={() => {
            setCount(count + 1);
            setOtherDocuments([
              ...otherDocuments,
              {
                key: count + 1,
                id: "",
                url: "",
                fileName: "",
                completed: false,
              },
            ]);
          }}
          isDisabled={false}
        />
      </div>
    </div>
  );
};
