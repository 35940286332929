import { useState, useEffect } from "react";
import { tabIndexes } from "Constants/Patient";
import Table from "Components/Common/Table/Table";
import { usePatientContext } from "Context/Patient";
import { Button } from "Components/Common/Button/Button";
import {
  GetPaymentDetails,
  PaymentRefund,
} from "Services/API/Patients.service";
import { formatDate } from "Helpers/DateFormat";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { ApiError } from "Components/Common/ApiError";
import { ReactComponent as CheckIcon } from "Assets/Check.svg";
import { ReactComponent as CrossIcon } from "Assets/Deactivate.svg";
import { ReactComponent as RefundIcon } from "Assets/Refund.svg";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { RefundModal } from "./RefundModal";
import { PaymentDetailsModal } from "./PaymentDetailsModal";
import { ReactComponent as DollarIcon } from "Assets/Dollar.svg";

import "Components/Patients/EditViewPatient/Forms/FormCommonCss/Form.css";
import "./PaymentsTable.css";
import "Colors.css";

const TABLE_HEADINGS = ["Membership", "Date", "Balance", "Status", "", ""];

export const paymentFormatter = (data) => {
  const formatRefunds=(data) => {
return data.map((item)=>{
  return {
    id: item.id,
    refundAmount: item.refundedAmount||"",
    date:formatDate(item?.createdAt, "MM-DD-YYYY") || "",
    time:formatDate(item?.createdAt, "HH:mm A") || "",
    remainingAmount:item?.remainingAmount || ""
  }
})
  }
  return data.map((list) => {
    return {
      id: list?.id,
      amount: list?.amount || "",
      balance: list?.balance || "",
      date: formatDate(list?.createdAt, "MM-DD-YYYY") || "",
      paid: list?.status,
      memberShip: list?.membership?.name || "-",
      refundAmount: list?.refundedAmount || "",
      refunds: formatRefunds(list?.refunds)||[]
    };
  });
};

export const PaymentsTable = ({ setActiveTabIndex }) => {
  const { getPatientData } = usePatientContext();
  const [patientPayments, setPatientPayments] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refundModal, setRefundModal] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState({
    invoicelogId: "",
    memberShip: "",
    amount: "",
    refund: "",
    date: "",
    refunds:[]
  });

  const fetchPayments = () => {
    if (getPatientData) {
      const { id } = getPatientData();
      if (id) {
        setLoading(true);
        GetPaymentDetails(id, pageNumber)
          .then((res) => {
            setLoading(false);
            setPatientPayments(paymentFormatter(res?.data));
            setHasNextPage(res?.pagination?.hasNext);
            setHasPreviousPage(res?.pagination?.hasPrevious);
          })
          .catch((err) => {
            setLoading(false);
            console.error("[GetPaymentDetails][API_ERROR} -", err);
            setApiError(
              errorFormatter(err?.response?.data?.errors || err?.message)
            );
          });
      }
    }
    return () => {
      setPatientPayments([]);
    };
  };
  useEffect(() => {
    fetchPayments();
  }, [getPatientData, pageNumber]);

  return (
    <>
      <div className="TableWrapper flex flex-col gap-4">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}

        <Table
          heading={<TableHeading />}
          body={
            !loading && patientPayments.length > 0 ? (
              <TableBody
                data={patientPayments}
                setRefundModal={setRefundModal}
                setDetailsModal={setDetailsModal}
                setSelectedPatientDetails={setSelectedPatientDetails}
              />
            ) : (
              !loading && "No Data Found"
            )
          }
        />
        {loading && <Spinner />}
        {!loading && patientPayments.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {refundModal && (
        <RefundModal
          amount={selectedPatientDetails.amount}
          isModalOpen={refundModal}
          isLoading={isBtnLoading}
          setRefundAmount={setRefundAmount}
          onRefund={() => {
            if (selectedPatientDetails.invoicelogId) {
              setIsBtnLoading(true);
              PaymentRefund(selectedPatientDetails.invoicelogId, {
                amount: refundAmount,
              })
                .then((res) => {
                  fetchPayments();
                  setIsBtnLoading(false);
                  setRefundModal(false);
                })
                .catch((err) => {
                  console.error(
                    "[PROVIDERS_TABLE][MoveToArchive][API_ERROR - ",
                    err
                  );
                  setIsBtnLoading(false);
                  setRefundModal(false);
                  setApiError(err?.response?.data?.errors || err?.message);
                });
            }
          }}
          onCancel={() => {
            setIsBtnLoading(false);
            setRefundModal(false);
          }}
        />
      )}
      {detailsModal && (
        <PaymentDetailsModal
          isModalOpen={detailsModal}
          paymentDetails={selectedPatientDetails}
          onCancel={() => {
            setDetailsModal(false);
          }}
        />
      )}
      <div className="flex justify-between">
        <Button
          btnText="Back"
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.UPLOAD_FILE)}
        />
      </div>
    </>
  );
};
const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};
const TableBody = ({
  data,
  setRefundModal,
  setDetailsModal,
  setSelectedPatientDetails,
}) => {
  const TABLE_ROW = "px-6 py-4 whitespace-nowrap text-sm text-black";

  return (
    <>
      {" "}
      {data.map((invoice, index) => (
        <tr key={index}>
          <td className={TABLE_ROW}>{invoice.memberShip}</td>
          <td className={TABLE_ROW}>{invoice.date}</td>
          <td className={TABLE_ROW}>
            {invoice.balance ? 
            <span className="flex items-center"><DollarIcon className="w-5 h-5 light_grayish_navy pointer-events-none" /> {invoice.balance}</span>
            : "- - -"}
          </td>

          <td className={TABLE_ROW}>
            <span className="flex items-center gap-2">
              {invoice.paid === "Paid" ? (
                <CheckIcon className="h-4 w-4 green cursor-pointer" />
              ) : invoice.paid === "Refunded" ? (
                <RefundIcon className="h-4 w-4 green cursor-pointer" />
              ) : invoice.paid === "Failed" ? (
                <CrossIcon className="h-4 w-4 green cursor-pointer" />
              ) : (
                ""
              )}
              {invoice.paid}
            </span>
          </td>
          <td
            className={`cursor-pointer TABLE_ROW text-blue-chill`}
            onClick={() => {
              setRefundModal(true);
              setSelectedPatientDetails({
                memberShip: invoice.memberShip,
                refund: invoice.refundAmount,
                amount: invoice.amount,
                date: invoice.date,
                invoicelogId: invoice.id,
                
              });
            }}
          >
            Refund
          </td>
          <td
            className={`cursor-pointer TABLE_ROW text-blue-chill`}
            onClick={() => {
              setSelectedPatientDetails({
                memberShip: invoice.memberShip,
                refund: invoice.refundAmount,
                amount: invoice.amount,
                date: invoice.date,
                refunds:[...invoice?.refunds]
              });
              setDetailsModal(true);
            }}
          >
            Details
          </td>
        </tr>
      ))}
    </>
  );
};
