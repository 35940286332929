import { useState } from "react";
import ReactDatePicker from "react-date-picker";
import moment from "moment";
import "Colors.css";
import "./DatePicker.css";

export function DatePicker({ id, label, value, isRequired = false, onBlur }) {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");
  return (
    <>
      <label className="DatePicker_MainWrapper block text-sm sapphire font-semibold mb-1">
        {label}
      </label>
      <ReactDatePicker
        className="DatePicker_Input"
        format="MM-dd-y"
        id={id}
        value={value ? moment(value).toDate() : ""}
        dayPlaceholder={'dd'} 
        monthPlaceholder={'mm'} 
        yearPlaceholder={'yy'} 
        clearIcon={null} 
        
        onChange={(selectedDate) => {
          setFieldValue(selectedDate);
          setFieldError(false);
        }}
        onBlur={() => {
          if (fieldValue) {
            onBlur?.(fieldValue, setFieldError);
          } else if (isRequired && !fieldValue) {
            setFieldError(`${label} is required`);
          }
        }}
      />
      {fieldError && (
        <div className="pt-2 text-sm text-red-600" id={`${label}-error`}>
          {fieldError}
        </div>
      )}
    </>
  );
}
