import { useState, useEffect } from "react";
import { formatDate } from "Helpers/DateFormat";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { GetStates } from "Services/API/GetStates.service";
import { InputField } from "Components/Common/InputField/InputField";
import { formattedStates } from "Helpers/Providers";
import Select from "react-select";
import "./Form.css";

export function MedMalPracticeInsuranceForm({
  postfix,
  setIsDisabled,
  setFormDetail,
  formDetail,
  onRemove,
}) {
  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [agentPhoneNum, setAgentPhoneNum] = useState("");
  const [policyNum, setPolicyNum] = useState("");
  const [expiryDate, setExpirationDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [malpracticeFile, setMalpracticeFile] = useState("");
  useEffect(() => {
    GetStates()
      .then((res) => {
        setStates(formattedStates(res?.data));
      })
      .catch((err) => {
        console.error("[GetStates][API_ERROR] - ", err);
      });
  }, []);

  useEffect(() => {
    const updatedFormDetail = (arr, newformInstance) => [
      ...arr.filter((formInstance) => formInstance.id !== newformInstance.id),
      { ...newformInstance },
    ];
    if (expiryDate && malpracticeFile && companyName && state) {
      setFormDetail(
        updatedFormDetail(formDetail, {
          id: postfix,
          nameOfCompany: companyName,
          agentIssuingPolicyName: agentPhoneNum,
          agentIssuingPolicyNumber: policyNum,
          currentExpirationDate: formatDate(expiryDate, "MM/DD/YYYY HH:mm:ss"),
          statesCovered: state.map((states) => states.id),
          malpracticeCertificateDocument: malpracticeFile,
        })
      );
    }
    setIsDisabled(!(expiryDate && malpracticeFile && companyName && state));
  }, [expiryDate, malpracticeFile, companyName, state]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <span className="text-base font-medium sapphire">
          Policy {postfix}{" "}
        </span>
        {postfix > 1 && (
          <span
            onClick={() => onRemove(postfix)}
            className="text-blue-light text-sm cursor-pointer"
          >
            Remove
          </span>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="company-name"
            label="Name of Company"
            isRequired={true}
            onBlur={(fieldValue) => {
              setCompanyName(fieldValue ? fieldValue.trim() : "");
            }}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="agent-name"
            label="Agent Issuing Policy Name"
            onBlur={(fieldValue) => {
              setAgentPhoneNum(fieldValue ? fieldValue.trim() : "");
            }}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="policy-number"
            label="Policy Number"
            type="number"
            onBlur={(fieldValue) => {
              setPolicyNum(fieldValue ? fieldValue.trim() : "");
            }}
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <DatePicker
            label="Current Expiration Date"
            isRequired={true}
            value={expiryDate}
            onBlur={(expDate) => {
              setExpirationDate(expDate || "");
            }}
          />
        </div>
        <div className="col-span-2">
          <label
            htmlFor={postfix}
            className="block text-sm font-semibold sapphire mb-1"
          >
            States Covered
          </label>
          <Select
            id={`select-state-${postfix}`}
            className="MedMalpracticeIns"
            classNamePrefix="react-select"
            isMulti
            value={state}
            isClearable={false}
            closeMenuOnSelect={true}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={states}
            onChange={(value) => setState([...value])}
          />
        </div>
        <div className="col-span-2">
          <UploadFile
            id={`upload-file-${postfix}`}
            mode="Edit"
            label="Malpractice Certificate/Verification"
            onResponse={(response) => {
              setMalpracticeFile(response?.data || "");
            }}
          />
        </div>
      </div>
    </div>
  );
}
