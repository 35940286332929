import { useState, useEffect } from "react";
import { tabIndexes } from "Constants/Patient";
import Table from "Components/Common/Table/Table";
import { usePatientContext } from "Context/Patient";
import { Button } from "Components/Common/Button/Button";
import { patientMedicationsFormatter } from "Helpers/Patients";
import { GetPatientMedications } from "Services/API/Patients.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { ApiError } from "Components/Common/ApiError";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import "Components/Patients/EditViewPatient/Forms/FormCommonCss/Form.css";
import "Colors.css";

const TABLE_HEADINGS = ["Medication", "Dosage", "Frequency"];

export function MedicationsFormView({ setActiveTabIndex }) {
  const { getPatientData } = usePatientContext();
  const [patientMedications, setPatientMedications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (getPatientData) {
      const { id } = getPatientData();
      if (id) {
        setLoading(true);
        GetPatientMedications(id)
          .then((res) => {
            setLoading(false);
           if(res.data){
            setPatientMedications(patientMedicationsFormatter(res?.data));
            setHasNextPage(res?.pagination?.hasNext);
            setHasPreviousPage(res?.pagination?.hasPrevious);
           }
          })
          .catch((err) => {
            setLoading(false);
            console.error("[GetPatientMedication][API_ERROR} -", err);
            setApiError(
              errorFormatter(err?.response?.data?.errors || err?.message)
            );
          });
      }
    }
  }, [getPatientData, pageNumber]);
  return (
    <>
      <div className="FormWrapper flex flex-col gap-6">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}

        <Table
          heading={<TableHeading />}
          body={
            patientMedications.length > 0 ? (
              <TableBody data={patientMedications} />
            ) : (
              !loading && "No Data Found"
            )
          }
        />
        {loading && <Spinner />}

        {!loading && patientMedications.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>

      <div className="flex justify-between">
        <Button
          btnText="Back"
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.GENERAL_INFO)}
        />
        <Button
          btnText="Next"
          className="h-8 w-16 text-white"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.MEDICAL_HISTORY)}
        />
      </div>
    </>
  );
}
const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};
const TableBody = ({ data }) => {
  return data.map((patient, index) => (
    <tr key={index}>
      <td className="px-6 py-4 whitespace-nowrap sapphire">
        {patient.medicineName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
        {patient.dosage}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
        {patient.medicineUsages}
      </td>
    </tr>
  ));
};
