import ModalBasic from "Components/Modal/Modal";
import { InputField } from "Components/Common/InputField/InputField";
import { ReactComponent as DeleteIcon } from "Assets/Delete.svg";
import { useState, useEffect } from "react";

export const IntakeFormBaseModal = ({
  isModalOpen = false,
  isLoading = false,
  onDelete,
  onCancel,
  onConfirm,
  mode = "",
  heading = "",
  subHeading = "",
  btnText = "",
  onEdit,
  formValue,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  useEffect(() => {
    if (formValue) {
      setFieldValue(formValue);
    }
  }, [formValue]);

  switch (mode) {
    case "Delete":
      return (
        <ModalBasic
          icon={<DeleteIcon className="rounded-none bg-white w-full h-full" />}
          btnText="Delete"
          heading={heading}
          content={subHeading}
          open={isModalOpen}
          loading={isLoading}
          setOpen={(value) => {
            if (value === "Delete") {
              onDelete?.();
            } else {
              onCancel?.();
            }
          }}
        />
      );
    case "Edit":
      return (
        <ModalBasic
          btnText="Save"
          open={isModalOpen}
          loading={isLoading}
          heading={heading}
          setOpen={(value) => {
            if (value !== "Cancel") {
              onEdit?.(fieldValue);
            } else {
              onCancel?.();
            }
          }}
          isDisabled={!(fieldValue.length > 0)}
        >
          <InputField
            id="Intake-Form"
            placeholder="Name"
            isRequired={true}
            value={fieldValue}
            onChange={(value) => {
              setFieldValue(value);
            }}
          />
        </ModalBasic>
      );
    default:
      return (
        <ModalBasic
          btnText="Add"
          heading={heading}
          content={subHeading}
          open={isModalOpen}
          loading={isLoading}
          setOpen={(value) => {
            if (value !== "Cancel") {
              onConfirm?.(fieldValue);
              setFieldValue("")
            } else {
              onCancel?.();
            }
          }}
          isDisabled={!(fieldValue.length > 0)}
        >
          <InputField
            id="Intake-Form"
            placeholder="Name"
            isRequired={true}
            value={fieldValue}
            onChange={(value) => {
              setFieldValue(value);
            }}
          />
        </ModalBasic>
      );
  }
};
