import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { formatDate } from "Helpers/DateFormat";
import { editTabIndexes, tabIndexes } from "Constants/Provider";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  GetSecurityDetails,
  UpdateSecurityDetails,
} from "Services/API/ProviderEditView.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import "Components/Provider/FormsCommonCss/Form.css";
import { ProviderSecurityInfo } from "Services/API/CreateProvider.service";

const formatSecurityApiRes = (data) => {
  return {
    id: data?.id || "-",
    securityCheckPassed: data?.backgroundCheckPassed || false,
    authorizationDocument: data?.backgroundAuthorizationDocument || "",
    backgroundCheckDate:
      formatDate(data?.backgroundCheckStartDate, "MM/DD/YYYY") || "-",
    otherDocuments: data?.otherDocuments || [],
  };
};
var s = "record with id 619f76a9bae60f0ba7ce7746 not found";
console.log("Record Error ", s.includes("record", "not", "found"));
export const SecurityForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow = false,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([
    { key: 1, id: "", url: "", fileName: "", completed: false },
  ]);
  const [addView, setAddView] = useState(false);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetSecurityDetails(providerId)
        .then((res) => {
          setLoading(false);
          if (Object.keys(res).length === 0) {
            setAddView(true);
          } else {
            setProviderDetail(formatSecurityApiRes(res));
            setIsEditMode(true);
          }
        })
        .catch((err) => {
          console.error("[GetSecurityDetails][API_ERROR] - ", err);
          setLoading(false);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const addUpdateSecurityDetails = async () => {
    let previousDocs = providerDetail?.otherDocuments?.map((x) => x.id);
    let newDocs = documents?.map((x) => x.id);

    if (isEditMode && !addView) {
      setIsLoading(true);

      try {
        const res = await UpdateSecurityDetails(providerDetail?.id, {
          backgroundCheckStartDate: providerDetail?.backgroundCheckDate,
          backgroundCheckPassed: providerDetail?.securityCheckPassed,
          backgroundAuthorizationDocument:
            providerDetail?.authorizationDocument,
          otherDocuments: previousDocs
            ? [...previousDocs, ...newDocs.filter((docs) => docs !== "")]
            : [...newDocs.filter((docs) => docs !== "")],
        });
        if (res) {
          setIsLoading(false);
          setActiveTabIndex(
            !addProviderFlow
              ? editTabIndexes.MED_MALPRACTICE_INS
              : tabIndexes.MED_MALPRACTICE_INS
          );
        }
      } catch (err) {
        console.error("[UpdateSecurityDetails][API_ERROR] - ", err);
        setIsLoading(false);
        setApiError(err?.response?.data?.errors || err);
      }
    } else if (addView) {
      setIsLoading(true);

      try {
        const res = await ProviderSecurityInfo(providerId, {
          backgroundCheckStartDate: providerDetail?.backgroundCheckDate,
          backgroundCheckPassed: providerDetail?.securityCheckPassed,
          backgroundAuthorizationDocument:
            providerDetail?.authorizationDocument,
          otherDocuments: previousDocs
            ? [...previousDocs, ...newDocs.filter((docs) => docs !== "")]
            : [...newDocs.filter((docs) => docs !== "")],
        });
        if (res) {
          setIsLoading(false);

          setActiveTabIndex(
            !addProviderFlow
              ? editTabIndexes.MED_MALPRACTICE_INS
              : tabIndexes.MED_MALPRACTICE_INS
          );
        }
      } catch (err) {
        console.error("[ProviderSecurityInfo][API_ERROR] - ", err);
        setIsLoading(false);
        setApiError(err?.response?.data?.errors || err);
      }
    } else setIsEditMode(true);
  };

  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider > 0 && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={isEditMode || addView ? "Save" : "Edit Profile"}
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                addUpdateSecurityDetails();
              }}
            />
          </div>
        )}
        {isEditMode || addView ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
            otherDocuments={documents}
            setOtherDocuments={setDocuments}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow
                  ? editTabIndexes.FELLOWSHIP
                  : tabIndexes.FELLOWSHIP
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={false}
            onClick={() => {
              addUpdateSecurityDetails();
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
