import { useEffect, useState } from "react";
import { InputField } from "../InputField/InputField";
import { Button } from "Components/Common/Button/Button";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { UploadOtherFile } from "Services/API/UploadFile.service";
import "./Files.css";

export default function UploadOtherFiles({
  id,
  title,
  files,
  fileUrl,
  setFiles,
  fileName,
  onResponseFileAdd,
  handleCancelFile,
  placeHolder = "",
  isLoading = false,
  setIsLoading,
}) {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(!(fileUrl && fileName));
  }, [fileUrl, fileName]);

  return (
    <div className="flex flex-col gap-4" key={id}>
      <div className="block text-sm font-semibold sapphire">{title}</div>
      <div className="Input_Field_Width">
        <InputField
          id={`input ${id}`}
          placeholder={placeHolder}
          isRequired={true}
          value={fileName}
          onBlur={(fieldValue) => {
            setFiles(
              files?.map((userDetail) =>
                userDetail.key === id
                  ? {
                      ...userDetail,
                      fileName: fieldValue && fieldValue.trim(),
                    }
                  : userDetail
              )
            );
          }}
        />
      </div>

      <UploadFile
        mode="Edit"
        id={`upload-file ${id}`}
        value={fileUrl}
        onResponse={({ data }) => {
          setFiles(
            files?.map((userDetail) =>
              userDetail.key === id
                ? {
                    ...userDetail,
                    url: data || "",
                  }
                : userDetail
            )
          );
        }}
      />

      <div className="flex justify-start gap-3">
        <Button
          id={id}
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          btnText="Submit"
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={() => {
            // This URL is actually the File_Id
            setIsLoading(true);
            UploadOtherFile({ name: fileName, url: fileUrl })
              .then((res) => {
                console.log("Res of file upload is ", res);
                onResponseFileAdd?.({ data: res?.data?.result }, id);
              })
              .catch((err) => {
                onResponseFileAdd?.({ error: err });
                console.error("[UploadOtherFiles][APIError] is", err);
              });
          }}
        />
        <Button
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          btnText="Cancel"
          isDisabled={false}
          onClick={() => {
            handleCancelFile(id);
          }}
        />
      </div>
    </div>
  );
}
