import Axios from "Services/Auth/AxiosInterceptors";
/**
 * @description  An API for getting Providers
 * @param {Number} page Current Page Number of the pagination
 * @returns
 */
export const GetProviders = async (page, recordsPerPage = 50) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/doctor/get?page=${page}&perPage=${recordsPerPage}`
  );
  return result;
};
/**
 * @description  An API for getting Requested Providers
 * @param {Number} page Current Page Number of the pagination
 * @returns
 */
export const GetRequestedProviders = async (page, recordsPerPage = 8) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/doctor/requests?page=${page}&perPage=${recordsPerPage}`
  );
  return result;
};
/**
 * @description  An API for activating the provider
 * @param {String} email of the provider
 * @returns
 */
export const ProviderActivate = async (email) => {
  return await Axios.post(`/doctor/doctor/activate`, email);
};
/**
 * @description  An API for deactivate the provider
 * @param {String} email of the provider
 * @returns
 */
export const ProviderDeactivate = async (email) => {
  return await Axios.post(`/doctor/doctor/deactivate`, email);
};
/**
 * @description  An API for archiving the provider
 * @param {String} id of the provider
 */
export const MoveToArchive = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/admin/doctor/archive/${id}`);

  return result;
};
/**
 * @description  An API for getting the provider details
 * @param {String} providerId
 * @returns the provider details
 */
export const GetProviderById = async (providerId) => {
  const {
    data: { result },
  } = await Axios.get(`/admin/doctor/get/${providerId}`);

  return result;
};
