import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as ActivateIcon } from "Assets/Activate.svg";

export const ActivateModal = ({
  isModalOpen,
  isLoading,
  onActivate,
  onCancel,
}) => {
    return (

        <ModalBasic
          icon={<ActivateIcon />}
          btnText="Activate"
          heading="Activate Profile"
          content="Are you sure you want to Activate this profile? Activating a
        provider means they will be able to accept patient visits."
          open={isModalOpen}
          loading={isLoading}
          setOpen={(value) => {
            if (value === "Activate") {
              onActivate();
            } else {
              onCancel();
            }
          }}
        />
    )
};
