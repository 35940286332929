import { useState } from "react";
import { Divider } from "Components/Common/Divider/Divider";
import { MedMalPracticeInsuranceForm } from "Components/Provider/AddProvider/Forms/MedMalpracticeInsForm/Form";

export const AddForm = ({ formState, setFormState }) => {
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <div className="FormWrapper flex flex-col gap-4">
      <MedMalPracticeInsuranceForm
        id={1}
        postfix={1}
        formDetail={formState}
        setFormDetail={setFormState}
        setIsDisabled={setIsDisabled}
      />
      {Array(count)
        .fill("")
        .map((_, index) => (
          <MedMalPracticeInsuranceForm
            key={index + 1}
            id={count + 1}
            postfix={count + 1}
            formDetail={formState}
            setFormDetail={setFormState}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setFormState(
                formState.filter((formInstance) => formInstance.id !== id)
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}

      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
