import { InputField } from "Components/Common/InputField/InputField";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { formatDate } from "Helpers/DateFormat";

const MED_DOC = "Medical Doctor";
const PHYSICIAN_ASSTT = "Physician Assistant";
const NURSE_PRACTITIONER = "Nurse Practitioner";
const GENDER_OPTIONS = ["Male", "Female", "Unknown"];
const OSTEOPATHIC_DOC = "Doctor of Osteopathic Medicine";

export const EditForm = ({ providerFormDetail, setProviderFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="first-name"
          label="First Name"
          value={providerFormDetail?.firstName}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              firstName: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="last-name"
          label="Last Name"
          value={providerFormDetail?.lastName}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              lastName: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="email"
          label="Email"
          value={providerFormDetail?.userName}
          isDisabled={true}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="clinician-id"
          label="Clinician ID"
          value={providerFormDetail?.clinicianID}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              clinicianID: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <Dropdown
          id="selectGender"
          label="Gender"
          isRequired={true}
          onBlur={(value) => {
            setProviderFormDetail({
              ...providerFormDetail,
              gender: value,
            });
          }}
        >
          <Dropdown.Option
            id={-1}
            value={providerFormDetail?.gender || "-1"}
            label=""
            isDefaultOption={true}
          />
          {GENDER_OPTIONS.map((gender, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={gender}
              label={gender}
            />
          ))}
        </Dropdown>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <DatePicker
          label="Date of Birth"
          value={
            providerFormDetail?.DOB ? new Date(providerFormDetail.DOB) : ""
          }
          onBlur={(birthDate) => {
            setProviderFormDetail({
              ...providerFormDetail,
              DOB: formatDate(birthDate,"MM-DD-YYYY"),
            });
          }}
        />
      </div>
      <br />
      <fieldset className="mt-2 space-y-6 col-span-2">
        <div className="space-y-2">
          <label className="font-semibold sapphire">Type of Provider</label>
          <RadioButton
            id="1"
            label="Medical Doctor"
            value={MED_DOC}
            isSelected={providerFormDetail?.providerType === MED_DOC}
            onChange={() => {
              setProviderFormDetail({
                ...providerFormDetail,
                providerType: MED_DOC,
              });
            }}
          />
          <RadioButton
            id="2"
            label="Doctor of Osteopathic Medicine"
            value={OSTEOPATHIC_DOC}
            isSelected={providerFormDetail?.providerType === OSTEOPATHIC_DOC}
            onChange={() => {
              setProviderFormDetail({
                ...providerFormDetail,
                providerType: OSTEOPATHIC_DOC,
              });
            }}
          />
          <RadioButton
            id="3"
            label="Nurse Practitioner"
            value={NURSE_PRACTITIONER}
            isSelected={providerFormDetail?.providerType === NURSE_PRACTITIONER}
            onChange={() => {
              setProviderFormDetail({
                ...providerFormDetail,
                providerType: NURSE_PRACTITIONER,
              });
            }}
          />
          <RadioButton
            id="3"
            label="Physician Assistant"
            value={PHYSICIAN_ASSTT}
            isSelected={providerFormDetail?.providerType === PHYSICIAN_ASSTT}
            onChange={() => {
              setProviderFormDetail({
                ...providerFormDetail,
                providerType: PHYSICIAN_ASSTT,
              });
            }}
          />
        </div>
      </fieldset>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="NPI"
          label="National Provider Identifier"
          value={providerFormDetail?.NPI}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              NPI: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2">
        <UploadFile
          mode="Edit"
          id="med-diploma"
          value={providerFormDetail?.file}
          label="Medical School Diploma"
          onResponse={({ data }) => {
            setProviderFormDetail({
              ...providerFormDetail,
              file: data,
            });
          }}
        />
      </div>
      <span className="col-span-2 font-semibold text-base sapphire">
        References
      </span>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="name-1"
          label="Name 1"
          value={providerFormDetail?.referenceName1}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              referenceName1: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="phone-num-1"
          type="tel"
          label="Phone Number"
          value={providerFormDetail?.referenceNumber1}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              referenceNumber1: fieldValue?.replace(
                /[&/\\#,+( )$~%.'":*?<>{}-]/g,
                ""
              ),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="name-2"
          label="Name 2"
          value={providerFormDetail?.referenceName2}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              referenceName2: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="phone-num-2"
          type="tel"
          label="Phone Number"
          value={providerFormDetail?.referenceNumber2}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              referenceNumber2: fieldValue?.replace(
                /[&/\\#,+( )$~%.'":*?<>{}-]/g,
                ""
              ),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="name-3"
          label="Name 1"
          value={providerFormDetail?.referenceName3}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              referenceName3: fieldValue?.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="phone-num-3"
          type="tel"
          label="Phone Number"
          value={providerFormDetail?.referenceNumber3}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              referenceNumber3: fieldValue?.replace(
                /[&/\\#,+( )$~%.'":*?<>{}-]/g,
                ""
              ),
            });
          }}
        />
      </div>
    </div>
  );
};
