import { useState, useContext, createContext } from "react";
import { useHistory } from "react-router";

const ProviderContext = createContext();
const PROVIDER_INIT_STATE = {
  name: "",
  id: "",
};
const DoctorsProvider = ({ children }) => {
  const [provider, setProvider] = useState(PROVIDER_INIT_STATE);

  const [providerId, setProviderId] = useState("");
  let history = useHistory();

  const setActiveView = (view) => {
    history.push(view);
  };
  const setProviderID = (id) => {
    localStorage.setItem("ADDING_PROVIDER_ID", JSON.stringify(id));
    setProviderId(id);
  };
  const getProviderId = () => {
    const localStorageValue = JSON.parse(localStorage.getItem("ADDING_PROVIDER_ID"));
    
    if (localStorageValue) {
      return localStorageValue;
    }
    if (providerId) {
      return providerId;
    }
    return;
  };
  const resetProviderId = () => {
    setProviderId("");
    localStorage.setItem("ADDING_PROVIDER_ID", JSON.stringify(""));
  };

  const setProviderData = (providerData) => {
    localStorage.setItem("PROVIDER_DATA", JSON.stringify(providerData));
    setProvider({ ...provider, ...providerData });
  };
  const getProviderData = () => {
    const { name } = provider;
    const ProviderData = JSON.parse(localStorage.getItem("PROVIDER_DATA"));
    if (name) {
      return provider;
    }
    if (ProviderData) {
      return ProviderData;
    }
    return;
  };
  const resetProviderData = () => {
    setProvider({ PROVIDER_INIT_STATE });
    localStorage.setItem(
      "PROVIDER_DATA",
      JSON.stringify({ PROVIDER_INIT_STATE })
    );
  };

  return (
    <ProviderContext.Provider
      value={{
        setActiveView,
        setProviderID,
        getProviderId,
        resetProviderId,
        setProviderData,
        getProviderData,
        resetProviderData,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProviderContext = () => {
  return useContext(ProviderContext);
};

export { ProviderContext, DoctorsProvider };
