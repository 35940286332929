import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as DeleteIcon } from "Assets/Delete.svg";

export const DeleteModal = ({
  isModalOpen,
  isLoading,
  onDelete,
  onCancel,
}) => {
  return (
    <ModalBasic
      icon={<DeleteIcon className="rounded-none bg-white w-full h-full"/>}
      btnText="Delete"
      heading="Delete Profile"
      content="Are you sure you want to delete this profile?"
      open={isModalOpen}
      loading={isLoading}
      setOpen={(value) => {
        if (value === "Delete") {
          onDelete();
        } else {
          onCancel();
        }
      }}
    />
  );
};
