import { useState, useEffect } from "react";
import moment from "moment"
import { GetPatientsOfferRefunds } from "Services/API/Patients.service";

import Table from "Components/Common/Table/Table";
import { usePatientContext } from "Context/Patient";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { ReactComponent as ProfileImage } from "Assets/Profile.svg";
import { formatDate } from "Helpers/DateFormat";
import { PaymentRefund } from "Services/API/Patients.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { RefundModal } from "./RefundModal";
import { EDIT_PATIENT_PATH } from "Constants/Views";
import "Colors.css";

const TABLE_HEADINGS = [
  "Visit Started",
  "Name",
  "Email",
  "State",
  "DOB",
  "Status",
  "Charged",
  "Offer",
  "Waited",
  "Action",
];

function OfferRefunds() {
  const [offerRefunds, setOfferRefunds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState({
    invoicelogId: "",
    memberShip: "",
    amount: "",
    refund: "",
    date: "",
    refunds:[]
  });
  
  const getWaitTime = (waitStartedAt, waitEndedAt) => {
    const startTime = moment(waitStartedAt)
    const endTime = moment(waitEndedAt)
    const mins = endTime.diff(startTime, 'minutes')

    if (typeof mins !== 'number' || String(mins) === 'NaN') {
      return null
    }

    if (mins === 60) {
      return '1 hour'
    } else if (mins > 60) {
      const result = `${Math.floor(mins/60)} hours`
      return result === '1 hours' ? '1 hour' : result
    } else if (mins >= 1440) {
      return `${Math.floor(mins)} day(s)`
    } else {
      return `${Math.floor(mins)} mins`
    }
  }
  
  const fetchRefundsList = () => {
    setIsLoading(true);
    GetPatientsOfferRefunds()
      .then((res) => {
        setOfferRefunds(res)
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[GetPatientsOfferRefunds][API_ERROR] - ", err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message)
          );
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchRefundsList()
  }, []);

  const TableHeading = () => {
    return TABLE_HEADINGS.map((head, index) => (
      <th
        key={index}
        scope="col"
        className="bali_hai px-6 py-3 text-left text-sm font-medium"
        >
        {head}
      </th>
      ));
  };
  
  const TableBody = ({ data }) => {
    const { setActivePatient, setActiveView, setGeneralInfo } = usePatientContext();
    return (
      data.map((item, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap bali_hai">
            {formatDate(item.startedAt, "MM/DD/YYYY") || ""}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap cursor-pointer"
            onClick={() => {
              setActivePatient({
                name: item.patient?.firstName + " " + item.patient?.lastName,
                id: item.patient.id,
              });
              setGeneralInfo(item.patient);
              setActiveView(EDIT_PATIENT_PATH);
          }}
            >
            <div className="text-sm bali_hai">
              {`${item.patient.firstName} ${item.patient.lastName}`}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm bali_hai">{item.patient.username}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm bali_hai">{item.patient.state.shortName}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
            {formatDate(item.patient.dateOfBirth, "MM/DD/YYYY")}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
            {item.status}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
            {`$${item.charges}`}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
            {item.offer.minutes} mins
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai text-red-600">
            {getWaitTime(item.waitStartedAt, item.waitEndedAt)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
            <span
              className="text-blue-chill hover:text-blue-light cursor-pointer"
              onClick={() => {
                setRefundModal(true);
                setSelectedPatientDetails({
                  amount: item.charges,
                  invoicelogId: item.invoiceLog.id,
                  fetchRefundsList,
                });
              }}
            >
              Refund
            </span>
          </td>
        </tr>
        )))
  }
    
    return (
      <div>
      {apiError && (
        <>
        <div className="flex flex-col items-center justify-center h-full">
          <p className="mt-1 text-md font-medium animate-pulse text-red-800">
            {apiError}
          </p>
        </div>
        </>
        )}
      {isLoading && <Spinner />}
      {!isLoading && !apiError && offerRefunds?.length > 0 && (
        <Table
          heading={<TableHeading />}
          body={
          !loading &&
          offerRefunds?.length > 0 && (
            <TableBody data={offerRefunds}  />
            )
          }
        />
        )}
        {refundModal && (
          <RefundModal
            amount={selectedPatientDetails.amount}
            isModalOpen={refundModal}
            isLoading={isBtnLoading}
            onRefund={() => {
              if (selectedPatientDetails.invoicelogId) {
                setIsBtnLoading(true);
                PaymentRefund(selectedPatientDetails.invoicelogId, {
                  amount: selectedPatientDetails.amount,
                })
                  .then((res) => {
                    fetchRefundsList();
                    setIsBtnLoading(false);
                    setRefundModal(false);
                  })
                  .catch((err) => {
                    console.error(
                      "[REFUND_MODAL][API_ERROR - ",
                      err
                      );
                    setIsBtnLoading(false);
                    setRefundModal(false);
                    setApiError(err?.response?.data?.errors || err?.message);
                  });
              }
            }}
            onCancel={() => {
              setIsBtnLoading(false);
              setRefundModal(false);
            }}
          />
        )}
      </div>
    )

}

export default OfferRefunds
