import React from "react";
import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as RefundIcon } from "Assets/Refund.svg";

export const RefundModal = ({
  isModalOpen,
  isLoading,
  onRefund,
  onCancel,
}) => {
  return (
    <ModalBasic
      icon={<RefundIcon />}
      btnText="Confirm Refund"
      heading="Refund Payment"
      content="A refund will be issued to the patient within a few days"
      open={isModalOpen}
      isDisabled={false}
      loading={isLoading}
      setOpen={(value) => {
        if (value === "Confirm Refund") {
          onRefund();
        } else {
          onCancel();
        }
      }}
    >
    </ModalBasic>
  );
};
