import DetailsLayout from "Layouts/DetailsLayout/DetailLayout";
import { Content } from "Components/Patients/EditViewPatient/Content/Content";
import { TabsPanel } from "Components/Patients/EditViewPatient/TabsPanel/TabsPanel";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import "Pages/Patients/DetailPageCss/DetailPage.css";

export function TheEditPatientPage() {
  const [activeTabIndex, setActiveTabIndex] = useLocalStorage(
    "patientFormStep",
    0
  );
  return (
    <>
      <DetailsLayout
        leftColumn={
          <TabsPanel
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
          />
        }
      >
        <div className="DetailPage_Wrapper flex flex-col gap-4 overflow-x-hidden">
          <Content
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
          />
        </div>
      </DetailsLayout>
    </>
  );
}
