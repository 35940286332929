const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";
export const ViewForm = ({ adminFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>First Name</span>
        <span className={SUB_HEADING_CSS}>{adminFormDetail?.firstName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Last Name</span>
        <span className={SUB_HEADING_CSS}>{adminFormDetail?.lastName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Email</span>
        <span className={SUB_HEADING_CSS}>{adminFormDetail?.email}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Phone Number</span>
        <span className={SUB_HEADING_CSS}>{adminFormDetail?.phoneNumber}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Provider Profile Access Type</span>
        <span className={SUB_HEADING_CSS}>Can {adminFormDetail?.providerAccess}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Patient Profile Access Type</span>
        <span className={SUB_HEADING_CSS}>Can {adminFormDetail?.patientAccess}</span>
      </div>
    </div>
  );
};
