import DetailLayout from "Layouts/DetailsLayout/DetailLayout";
import { EditViewAdmin } from "Components/Admins/EditView/EditViewAdmin";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { BASE_ADMIN_PATH } from "Constants/Views";
import { useAdminContext } from "Context/Admin";

export const TheEditAdminPage = () => {
  return (
    <DetailLayout leftColumn={<LeftSection />}>
      <div className="AddProvider_Wrapper flex flex-col gap-4">
        <EditViewAdmin />
      </div>
    </DetailLayout>
  );
};
const LeftSection = () => {
  const { setActiveAdminView, getAdminDetails, resetAdminDetails } =
    useAdminContext();
  const { name } = getAdminDetails();
  return (
    <div className="pt-8 pl-6 pr-6">
      <div
        onClick={() => {
          resetAdminDetails();
          setActiveAdminView(BASE_ADMIN_PATH);
        }}
        className="flex pb-2"
      >
        <BackIcon className="h-6 w-6 sapphire cursor-pointer" />
        <span className="ml-2 sapphire text-base font-semibold cursor-pointer">
          {" "}
          {name}
        </span>
      </div>
    </div>
  );
};
