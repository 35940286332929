import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Background Check Start Date</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.backgroundCheckDate}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Background Check Passed</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.securityCheckPassed ? "Yes" : "NO"}
        </span>
      </div>
      <div className="flex flex-col col-span-2 gap-2">
        <UploadFile
          mode="View"
          value={providerFormDetail?.authorizationDocument}
          label="Background Authorization"
        />
      </div>
      {providerFormDetail?.otherDocuments?.map((docs, index) => (
        <div className="col-span-2">
          <UploadFile
            mode="View"
            id={docs.id}
            value={docs.url}
            label={docs.name}
          />
        </div>
      ))}
    </div>
  );
};
