import { Button } from "Components/Common/Button/Button";
import SearchBar from "Components/Common/SearchBar/SearchBar";
import { ReactComponent as FilterIcon } from "Assets/Filter.svg";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import ClassNames from "Helpers/Common";

import { formatDate } from "Helpers/DateFormat";

const TABLE_HEADINGS = ["Patients", "Start", "End", "Status", "", "", "Rating"];
const ACTIVE_VISITS = "Active Visits";
const COMPLETE_VISITS = "Completed Visits";
const SIGNED = "Signed";
const UNSIGNED = "Unsigned";
const ALL = "All";
const NAME = "name";
const EMAIL = "userName";

export const patientRecordFormatter = (patientsRecord) => {
  const ordersFormatter = (orders) => {
    return orders?.map((order) => {
      return {
        id: order.id,
        medName: order.genericDrugName,
        frequency: order.directions,
        dosage: order.strength,
        pharmacy: order.pharmacy?.name,
        date: formatDate(order.createdAt, "MM-DD-YYYY") || "",
        time: formatDate(order.createdAt, "HH:mm A") || "",
      };
    });
  };

  return patientsRecord.map((patient) => {
    return {
      id: patient?.id,
      username: patient?.patientId?.email,
      providerId: patient.doctorId,
      fullName: patient?.patientId?.name,
      email: patient?.patientId?.email,
      endDate: patient?.endedAt
        ? formatDate(patient?.endedAt, "MM-DD-YYYY")
        : "- - -",
      endTime: formatDate(patient?.endedAt, "HH:mm A") || "- - -",
      startDate: patient?.createdAt
        ? formatDate(patient?.createdAt, "MM-DD-YYYY")
        : "- - -",
      startTime: formatDate(patient?.createdAt, "HH:mm A") || "- - -",
      signed: patient?.signed ?? false,
      orders: ordersFormatter(patient?.orders) || [],
      feedback: patient?.feedback,
      notes: patient?.notes,
      firstName: patient?.patientId?.firstName ?? "",
      lastName: patient?.patientId?.lastName ?? "",
    };
  });
};
export const Header = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};
export const FiltersRow = ({
  filter,
  activeTab,
  filterView,
  setFilters,
  onTabChange,
  searchQuery,
  searchResults,
  setFilterView,
  onEnterPressed,
  setSearchQuery,
  setSearchResults,
  setSelectedPatient,
  setIsPatientSelected,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-6">
        {" "}
        <span
          className={ClassNames(
            "light_grayish_navy text-sm font-medium sapphire cursor-pointer text-center mt-1 py-2 px-2 rounded-md",
            activeTab === ACTIVE_VISITS &&
              "bg-gray-100 font-semibold text-black"
          )}
          onClick={() => {
            onTabChange(ACTIVE_VISITS);
          }}
        >
          {ACTIVE_VISITS}
        </span>
        <span
          onClick={() => {
            onTabChange(COMPLETE_VISITS);
          }}
          className={ClassNames(
            "light_grayish_navy text-sm font-medium cursor-pointer mt-1 py-2 px-2 rounded-md",
            activeTab === COMPLETE_VISITS &&
              "bg-gray-100 font-semibold text-black"
          )}
        >
          {COMPLETE_VISITS}
        </span>
      </div>
      <div className="flex items-center lg:gap-4 2xl:gap-6">
        <SearchBar
          isFiltered={true}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setSearchResults={setSearchResults}
          onFocus={() => {
            setFilterView(false);
          }}
          searchResults={!filterView ? searchResults : []}
          filterKey={Object.keys(filter).filter((key) => filter[key])}
          onKeyDown={(e) => {
            if (e.key === "Enter" && searchQuery.length >= 2) {
              setFilterView(false);
              onEnterPressed?.();
            }
          }}
          onSearch={(searchedPatientid) => {
            if (searchedPatientid) {
              setSelectedPatient(
                searchResults.filter(
                  (patient) => patient.id === searchedPatientid
                )
              );
              setIsPatientSelected(true);
            }
          }}
        />

        <Button
          variant="transparent"
          className="bg-white text-base font-normal sapphire border border-gray-200 py-2 px-4 inline-flex rounded-md w-28 h-8 relative"
          btnText="Filters"
          isDisabled={false}
          onClick={() => setFilterView((prevState) => !prevState)}
        >
          <FilterIcon className="h-5 w-5 text-black" />
        </Button>
        {filterView && <FilterView setFilters={setFilters} filter={filter} />}
      </div>
    </div>
  );
};
const FilterView = ({ filter, setFilters }) => {
  return (
    <div className="flex flex-col p-3 gap-2 w-96 border border-gray-200 bg-white absolute mt-75 z-50 shadow-lg rounded-md">
      <fieldset className="mt-4 space-y-3">
        <div>Filter Visits</div>
        <div className="space-y-2">
          <RadioButton
            id="1"
            label="All"
            className="text-sm font-medium"
            value={ALL}
            isSelected={!!filter?.all}
            onChange={() => {
              setFilters({
                ...filter,
                all: true,
                signed: false,
                unsigned: false,
              });
            }}
          />
          <RadioButton
            id="2"
            label="Signed Visits"
            className="text-sm font-medium"
            value={SIGNED}
            isDisabled={false}
            isSelected={!!filter?.signed}
            onChange={() => {
              setFilters({
                ...filter,
                all: false,
                unsigned: false,
                signed: true,
              });
            }}
          />
          <RadioButton
            id="3"
            label="Unsigned Visits"
            className="text-sm font-medium"
            value={UNSIGNED}
            isSelected={!!filter?.unsigned}
            onChange={() => {
              setFilters({
                ...filter,
                all: false,
                signed: false,
                unsigned: true,
              });
            }}
          />{" "}
        </div>
      </fieldset>
      <fieldset className="mt-4 space-y-3">
        <div>Filter Search</div>
        <div className="space-y-2">
          <RadioButton
            id="1"
            label="Name"
            className="text-sm font-medium"
            value={NAME}
            isSelected={!!filter?.name}
            onChange={() => {
              setFilters({ ...filter, name: true, userName: false });
            }}
          />
          <RadioButton
            id="2"
            label="Email"
            className="text-sm font-medium"
            value={EMAIL}
            isDisabled={false}
            isSelected={!!filter?.userName}
            onChange={() => {
              setFilters({
                ...filter,
                name: false,
                userName: true,
              });
            }}
          />
        </div>
      </fieldset>
    </div>
  );
};
