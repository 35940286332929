import { useState, useEffect } from "react";
import ClassNames from "Helpers/Common";
import "Colors.css";
export const InputFieldWithDropdown = ({
  id,
  label,
  maxLength,
  type = "text",
  placeholder = "",
  isRequired = false,
  options,
  value = "",
  onChange,
  onBlur,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");
  useEffect(() => {
    if (value) {
      setFieldValue(value);
    }
  }, [value]);
  return (
    <>
      <label htmlFor={id} className="sapphire block text-sm font-semibold mb-1">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor={id} className="sr-only">
            {label}
          </label>
          <select
            id={id}
            name={label}
            className="light_grayish_navy h-full pl-2 pr-2 border-transparent bg-transparent sm:text-sm rounded-md focus:outline-none"
          >
            {options.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
        <input
          id={id}
          type={type}
          name={label}
          value={fieldValue}
          maxLength={maxLength}
          className={ClassNames(
            "appearance-none block w-full px-3 pl-16 py-2 border rounded-md placeholder-gray-400 focus:outline-none sm:text-sm",
            fieldError
              ? "border-red-500 focus:ring-red-500 focus:border-red-500"
              : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
          )}
          placeholder={placeholder}
          onChange={({ target }) => {
            setFieldError(false);
            setFieldValue(target.value);
            onChange?.(target.value);
          }}
          onBlur={() => {
            if (fieldValue) {
              onBlur?.(fieldValue, setFieldError);
            } else if (isRequired && !fieldValue) {
              onBlur("", setFieldError);
              setFieldError(`${label} is required`);
            }
          }}
        />
      </div>
      {fieldError && (
        <div
          className="pt-2 text-sm text-red-600 font-poppins"
          id={`${label}-error`}
        >
          {fieldError}
        </div>
      )}
    </>
  );
};
