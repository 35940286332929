import { UploadFile } from "Components/Common/UploadFile/UploadFile";

export const EditForm = ({ providerFormDetail }) => {
  return providerFormDetail?.map((file, index) => (
    <div className="p-2" key={index}>
      <UploadFile
        mode="Edit"
        id={index}
        label={file.label}
        value={file.value}
        onResponse={async ({ data }) => {
          for (let i in providerFormDetail) {
            if (providerFormDetail[i].label == file.label) {
              providerFormDetail[i].value = data;
              break;
            }
          }
        }}
      />
    </div>
  ));
};
