import { useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import { Link } from "react-router-dom";
import { PieCharts } from "./PieChart";
import { AreaCharts } from "./AreaChart";
import { ProviderBarchart } from "./ProviderBarchart";
import { AdminDashboard } from "Services/API/Dashboard.service";
import {
  formattedDailyVisits,
  formattedPatientByState,
  formattedPatientMemberships,
  formattedProviderByState,
} from "Helpers/Dashboard";
import { PATIENT_REFUNDS_PATH } from "Constants/Views";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { ReactComponent as DotIcon } from "Assets/Dot.svg";
import { ReactComponent as ActiveUserIcon } from "Assets/Active Users.svg";
import { ReactComponent as TotalUserIcon } from "Assets/Total Users.svg";
import { ReactComponent as SadFaceIcon } from "Assets/SadFace.svg";
import { Card } from "./Card";
import "./Dashboard.css";
import "Colors.css";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

export function Dashboard() {
  const [dailyVisits, setDailyVisits] = useState([]);
  const [totalUsers, setTotalUsers] = useState("");
  const [activeUsers, setActiveUsers] = useState("");
  const [totalOfferRefunds, setTotalOfferRefunds] = useState("");
  const [patientMemberships, setPatientMeberships] = useState([]);
  const [patientPerState, setPatientPerState] = useState([]);
  const [providerPerState, setProviderPerState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    AdminDashboard()
      .then((res) => {
        setTotalUsers(res?.totalUsers);
        setActiveUsers(res?.activeUsers);
        setTotalOfferRefunds(res?.totalOfferRefundsRequired);
        setPatientMeberships(
          formattedPatientMemberships(res?.patientMembershipCounts)
        );
        setDailyVisits(formattedDailyVisits(res?.visitCounts));
        setProviderPerState(
          formattedProviderByState(res?.providerCountByStates)
        );
        setPatientPerState(formattedPatientByState(res?.patientsCountByStates));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[DASHBOARD][API_ERROR] - ", err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message)
        );
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {apiError && (
        <>
          <div className="flex flex-col items-center justify-center h-full">
            <SadFaceIcon className="h-16 w-16  animate-bounce text-red-800" />
            <p className="mt-1 text-md font-medium animate-pulse text-red-800">
              {apiError}
            </p>
          </div>
        </>
      )}
      {isLoading && <Spinner />}
      {!isLoading && !apiError && (
        <div className="flex flex-col gap-6 p-4">
          <span className="text-base leading-9 font-semibold sapphire">
            Dashboard
          </span>
          <div className="flex flex-wrap gap-4 justify-between">
            <div className="Daily_Visits flex flex-1 flex-col gap-6 rounded-md shadow-lg">
              <span className="text-sm leading-6 font-semibold sapphire pl-6 pt-3">
                Daily Visits
              </span>
              <ResponsiveContainer width="100%" height="100%">
                <AreaCharts
                  data={[
                    ...dailyVisits.sort(
                      (a, b) =>
                        new Date(...a.date.split("/").reverse()) -
                        new Date(...b.date.split("/").reverse())
                    ),
                  ]}
                />
              </ResponsiveContainer>
            </div>
            <div className="Total_Users flex flex-1 flex-col gap-2">
              <Card
                heading="Total Users"
                icon={<TotalUserIcon />}
                count={totalUsers}
              />
              <Card
                heading="Active Users"
                icon={<ActiveUserIcon />}
                count={activeUsers}
              />
              <Link to={PATIENT_REFUNDS_PATH}>
                <Card
                  heading="Total Offer Refunds"
                  icon={<TotalUserIcon />}
                  count={totalOfferRefunds}
                  textClass="text-red-600"
                />
              </Link>
              <div className="flex flex-col rounded-lg shadow-lg">
                <span className="text-sm leading-6 font-semibold sapphire pl-3 pt-3">
                  Patient Memberships
                </span>
                <div className="flex bg-white shadow-lg">
                  <PieCharts
                    data={patientMemberships}
                    width={200}
                    height={200}
                    innerRadius={60}
                    outerRadius={80}
                  />
                  <div className="flex flex-col gap-4 pt-12">
                    {" "}
                    {patientMemberships.map((membership, index) => (
                      <span className="flex justify-between gap-4" key={index}>
                        <span className="flex items-center gap-2">
                          {index === 0 ? (
                            <span className="text-zircon">{<DotIcon />}</span>
                          ) : index === 1 ? (
                            <span className="text-black">{<DotIcon />}</span>
                          ) : (
                            <span className="text-blue-chill">
                              {<DotIcon />}
                            </span>
                          )}
                          {membership.name}
                        </span>
                        <span className="pr-2"> {membership.value}</span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* */}
          <div className="flex justify-between">
            <div className="User_Per_State flex flex-col gap-32 rounded-lg shadow-lg">
              <span className="text-sm leading-6 font-semibold sapphire pl-3 pt-3">
                Providers in each state
              </span>

              <div>
                <ResponsiveContainer width="100%" height="100%">
                  <ProviderBarchart
                    data={providerPerState}
                    width={400}
                    height={400}
                  />
                </ResponsiveContainer>
              </div>
            </div>
            <div className="User_Per_State flex flex-col gap-32 rounded-lg shadow-lg">
              <span className="text-sm leading-6 font-semibold sapphire pl-3 pt-3">
                Patients in each state
              </span>

              <div>
                <ResponsiveContainer width="100%" height="100%">
                  <ProviderBarchart data={patientPerState} />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
