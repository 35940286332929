import { useState, useEffect } from "react";
import { tabIndexes } from "Constants/Provider";
import { Button } from "Components/Common/Button/Button";
import { useProviderContext } from "Context/Provider";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { AddUserFiles } from "Services/API/CreateProvider.service";
import { ApiError } from "Components/Common/ApiError";
import { BASE_PROVIDER_PATH } from "Constants/Views";
import "Components/Provider/AddProvider/Forms/FormsCommonCss/Form.css";
import ClassNames from "Helpers/Common";

const UPLOAD_FILES_LABELS = [
  "Provider Agreement",
  "Educational Commission for Foreign Medical Graduates (ECFMG) Certificate",
  "State Issued Driver License or State Issued Identification Card",
  "Passport",
  "Social Security Card",
  "IRS W9 Form",
  "Provider Completed Application",
  "HIPAA Confidentiality Agreement",
  "InstaCured Technology Security and Privacy Agreement",
  "InstaCured Provider Application",
  "InstaCured Confidentiality Agreement",
  "InstaCured ADA Policy",
  "InstaCured Sexual Harassment Policy",
  "InstaCured Prescribing Policy",
  "InstaCured Antibiotic Stewardship Policy",
  "InstaCured Non-disclosure Agreement",
];

export function UploadFilesForm({ setActiveTabIndex }) {
  const { setActiveView, getProviderId } = useProviderContext();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsDisabled(!(uploadedFiles.length > 15));
  }, [uploadedFiles]);
  const handleSubmit = () => {
    setIsLoading(true);
    AddUserFiles(getProviderId(), {
      providerAgreement:uploadedFiles[0],
      ECFMGcertificate: uploadedFiles[1],
      idCard: uploadedFiles[2],
      passport: uploadedFiles[3],
      socialSecurityCard: uploadedFiles[4],
      IRS_W9form: uploadedFiles[5],
      providerCompletedApplication: uploadedFiles[6],
      HIPAAconfidentialityAgreement: uploadedFiles[7],
      instacuredTechnologySecurityAndPrivacyAgreement: uploadedFiles[8],
      instacuredProviderApplication: uploadedFiles[9],
      instacuredConfidentialityAgreement: uploadedFiles[10],
      instacuredADApolicy: uploadedFiles[11],
      instacuredSexualHarassmentPolicy: uploadedFiles[12],
      instacuredPrescribingPolicy: uploadedFiles[13],
      instacuredAntibioticStewardshipPolicy: uploadedFiles[14],
      instacuredNonDisclosureAgreement: uploadedFiles[15],
    })
      .then((res) => {
        setIsLoading(false);
        setActiveView(BASE_PROVIDER_PATH);
      })
      .catch((err) => {
        console.error("[AddUserFiles][API_ERROR] -", err);
        setApiError(err?.response?.data?.errors);
        setIsLoading(false);
      });
  };
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper flex flex-col gap-8">
        {UPLOAD_FILES_LABELS.map((files, index) => {
          return (
            <div className="col-span-1" key={index}>
              <UploadFile
                mode="Edit"
                id={index}
                label={files}
                onResponse={({ data }) => {
                  setUploadedFiles([...uploadedFiles, data]);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="flex justify-between">
        <Button
          btnText="Back"
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          isDisabled={false}
          onClick={() => {
            setActiveTabIndex(tabIndexes.MED_MALPRACTICE_INS);
          }}
        />
        <Button
          btnText="Submit"
          className={ClassNames("h-8 w-16", !isDisabled && "text-white")}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
