import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "Components/Common/Button/Button";
import PatientsModal from "Components/Modal/BasicModal";
import ClassNames from "Helpers/Common";
import { InputField } from "Components/Common/InputField/InputField";
import { PatientRouteLogin } from "Services/API/Login.service";
import { ApiError } from "Components/Common/ApiError";

export const UserAuthenticationModal = ({
  icon,
  heading,
  content,
  btnText,
  modalOpen,
  setShowPatientsTable,
  setIsAuthModalOpen
}) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  return (
    <PatientsModal open={modalOpen} setOpen={modalOpen}>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div className="flex flex-col gap-2">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            {icon}
          </div>
          {apiError && <ApiError error={apiError} setApiError={setApiError} />}
          <div className="text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {heading}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{content}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-col gap-4">
          <InputField
            id="password"
            placeholder="Password"
            type="password"
            isRequired={true}
            onChange={(fieldValue) => {
              setPassword(fieldValue ? fieldValue : "");
            }}
          />

          <Button
            className={ClassNames(
              "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 0 text-sm font-medium   focus:outline-none sm:text-sm",
              btnText === "Deactivate" ? "bg-red-500" : "royal_blue",
              !password ?"text-gray-400 cursor-not-allowed":"text-white"
            )}
            btnText={btnText}
            isDisabled={!(password&&!apiError)}
            isLoading={loading}
            onClick={() => {
              setLoading(true);
              const email = JSON.parse(localStorage.getItem("USER_EMAIL"));
              if (email && password) {
                PatientRouteLogin({
                  email: email,
                  password: password,
                })
                  .then((res) => {
                    if (res) {
                      setShowPatientsTable(true);
                      setIsAuthModalOpen(false)
                      setLoading(false);
                    }
                  })
                  .catch((err) => {
                    console.error(
                      "[SendLogInReq][API_ERROR]  - ",
                      err?.response?.data?.errors || err
                    );
                    setLoading(false);
                    setApiError(err?.response?.data?.errors || err?.message);
                  });
              }
            }}
          />
          <Button
           variant="transparent"  
            className="text-sm font-medium sapphire border border-gray-200 py-2 px-4 inline-flex rounded-md w-full"
            btnText="Cancel"
            isDisabled={false}
            onClick={() => setIsAuthModalOpen(false)}
          />
        </div>
      </div>
    </PatientsModal>
  );
};
