import { useState, useEffect } from "react";
import { formatDate } from "Helpers/DateFormat";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { InputField } from "Components/Common/InputField/InputField";
import "Colors.css";

export const StateMedLicForm = ({
  id,
  LicensePostfix,
  states,
  setIsDisabled,
  formDetail,
  setFormDetail,
  onRemove,
  isEditMode,
}) => {
  const [state, setState] = useState("");
  const [licNum, setLicNum] = useState("");
  const [licExpDate, setLicExpDate] = useState("");
  const [licIssueDate, setLicIssueDate] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [Form, setForm] = useState([]);

  useEffect(() => {
    const updatedFormDetail = (arr, newformInstance) => [
      ...arr?.filter((formInstance) => formInstance.id !== newformInstance.id),
      { ...newformInstance },
    ];
    if (state && licNum && licIssueDate && licExpDate && uploadedFile) {
      setFormDetail(
        updatedFormDetail(formDetail, {
          id: id,
          licenseState: state,
          licenseNumber: licNum,
          licensedDate: formatDate(licIssueDate, "MM/DD/YYYY"),
          licenseExpiration: formatDate(licExpDate, "MM/DD/YYYY"),
          licenseDocument: uploadedFile,
        })
      );
    }
    setIsDisabled(
      !(state && licNum && licIssueDate && licExpDate && uploadedFile)
    );
  }, [state, licNum, licExpDate, licIssueDate, uploadedFile]);
  useEffect(() => {
    if (formDetail.length > 0) {
      setForm([...formDetail]);
    }
  }, []);

  return isEditMode ? (
    Form.map((val, index) => (
      <div className="flex flex-col gap-3" key={index}>
        <div className="flex justify-between">
          <span className="text-base font-medium sapphire">
            License {index + 1}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 lg:col-span-1">
            <Dropdown
              id="select-state"
              label="State"
              isRequired={true}
              onBlur={(fieldValue) => {
                setFormDetail(
                  formDetail.map((userDetail) =>
                    userDetail.id === val.id
                      ? {
                          ...userDetail,
                          licenseState: fieldValue.trim(),
                        }
                      : userDetail
                  )
                );
              }}
            >
              <Dropdown.Option
                id={-1}
                value={val.stateName}
                label=""
                isDefaultOption={true}
              />
              {states.length > 0 &&
                states.map((state) => (
                  <Dropdown.Option
                    id={state.id}
                    value={state.id}
                    label={state.name}
                  />
                ))}
            </Dropdown>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="LicNo"
              label="License Number"
              isRequired={true}
              value={val.licenseNumber}
              onBlur={(fieldValue) => {
                setFormDetail(
                  formDetail.map((userDetail) =>
                    userDetail.id === val.id
                      ? {
                          ...userDetail,
                          licenseNumber: fieldValue && fieldValue.trim(),
                        }
                      : userDetail
                  )
                );
              }}
            />
          </div>
          <div className="col-span-1">
            <DatePicker
              label="Date First Licensed"
              isRequired={true}
              value={val.licensedDate ? new Date(val.licensedDate) : ""}
              onBlur={(fieldValue) => {
                setFormDetail(
                  formDetail.map((userDetail) =>
                    userDetail.id === val.id
                      ? {
                          ...userDetail,
                          licensedDate: fieldValue,
                        }
                      : userDetail
                  )
                );
              }}
            />
          </div>
          <div className="col-span-1">
            <DatePicker
              label="Date of Expiration"
              isRequired={true}
              value={
                val.licenseExpiration ? new Date(val.licenseExpiration) : ""
              }
              onBlur={(fieldValue) => {
                setFormDetail(
                  formDetail.map((userDetail) =>
                    userDetail.id === val.id
                      ? {
                          ...userDetail,
                          licenseExpiration: fieldValue,
                        }
                      : userDetail
                  )
                );
              }}
            />
          </div>

          <div className="col-span-2">
            <UploadFile
              mode={val.licenseDocument ? "View" : "Edit"}
              id={id}
              value={val.licenseDocument}
              label="Medical License"
              onResponse={({ data }) => {
                setFormDetail(
                  formDetail.map((userDetail) =>
                    userDetail.id === val.id
                      ? {
                          ...userDetail,
                          licenseDocument: data,
                        }
                      : userDetail
                  )
                );
              }}
            />
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <span className="text-base font-medium sapphire">
          License {LicensePostfix}
        </span>
        {LicensePostfix > 1 && (
          <span
            onClick={() => onRemove(id)}
            className="text-blue-light text-sm cursor-pointer"
          >
            Remove
          </span>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <Dropdown
            id="select-state"
            label="State"
            isRequired={true}
            onBlur={(fieldValue) => (fieldValue ? setState(fieldValue) : "")}
          >
            <Dropdown.Option
              id={-1}
              value={"-1"}
              label=""
              isDefaultOption={true}
            />
            {states.length > 0 &&
              states.map((state) => (
                <Dropdown.Option
                  id={state.id}
                  value={state.id}
                  label={state.name}
                />
              ))}
          </Dropdown>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="LicNo"
            label="License Number"
            isRequired={true}
            value={licNum}
            onChange={(fieldValue) => setLicNum(fieldValue)}
          />
        </div>
        <div className="col-span-1">
          <DatePicker
            label="Date First Licensed"
            isRequired={true}
            value={licIssueDate ? new Date(licIssueDate) : ""}
            onBlur={(LicenseIssueDate) => setLicIssueDate(LicenseIssueDate)}
          />
        </div>
        <div className="col-span-1">
          <DatePicker
            label="Date of Expiration"
            isRequired={true}
            value={licExpDate ? new Date(licExpDate) : ""}
            onBlur={(LicenseExpDate) => setLicExpDate(LicenseExpDate)}
          />
        </div>

        <div className="col-span-2">
          <UploadFile
            mode={uploadedFile ? "View" : "Edit"}
            id={id}
            value={uploadedFile}
            label="Medical License"
            onResponse={({ data }) => setUploadedFile(data)}
          />
        </div>
      </div>
    </div>
  );
};
