/* eslint-disable jsx-a11y/alt-text */
import { FormattedDropdown } from "Components/Common/FormattedDropdown/FormattedDropDown";
import Table from "Components/Common/Table/Table";
import ClassNames from "Helpers/Common";
import { formattedProviders } from "Helpers/Providers";
import { useEffect, useState } from "react";
import { GetProviders, MoveToArchive } from "Services/API/Providers.service";
import { TableHeading } from "../TableHeading/TableHeading";
import { ReactComponent as ProfileImage } from "Assets/Profile.svg";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  ProviderActivate,
  ProviderDeactivate,
} from "Services/API/Providers.service";
import { useProviderContext } from "Context/Provider";
import { EDIT_PROVIDER_PATH } from "Constants/Views";
import { ActivateModal } from "./ActivateModal";
import { DeactivateModal } from "./DeactivateModal";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { DeleteModal } from "./DeleteModal";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

const HEADINGS = [
  "Name",
  "States",
  "Provider Type",
  "Profile Status",
  "Actions",
  " ",
  " ",
];
const DROPDOWN_OPTIONS = [{ title: "Activate" }, { title: "Deactivate" }];

export const ProvidersTable = ({ searchedProvider }) => {
  const { setActiveView, setProviderData } = useProviderContext();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveProviderId, setArchiveProviderId] = useState("");

  const fetchProviders = () => {
    setLoading(true);
    GetProviders(pageNumber)
      .then((res) => {
        setLoading(false);
        setProviders(formattedProviders(res?.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setApiError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error(`[ProvidersPage][GetProviders][Error] - `, err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message)
        );
      });

    return () => {
      setProviders([]);
    };
  };

  useEffect(() => {
    fetchProviders();
  }, [pageNumber]);

  return (
    <>
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}

      <Table
        heading={<TableHeading TableHeader={HEADINGS} />}
        body={
          !loading && !searchedProvider?.id && providers.length > 0 ? (
            providers.map((provider, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 text-gray-500">
                      {provider.image ? (
                        <img
                          className="h-10 w-10 rounded-full"
                          src={provider.image}
                        />
                      ) : (
                        <ProfileImage />
                      )}
                    </span>
                    <div
                      onClick={() => {
                        setProviderData({
                          id: provider.id,
                          name: provider.name,
                        });
                        setActiveView(EDIT_PROVIDER_PATH);
                      }}
                      className="ml-4"
                    >
                      <span className="text-sm font-medium eclipse cursor-pointer">
                        {provider.name}
                      </span>
                      <br />
                      <span className="bali_hai text-sm cursor-pointer">
                        {provider.username}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm bali_hai">{provider.states}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
                  {provider.providerType}
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <span
                    className={ClassNames(
                      "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bali_hai bg-gray-100",
                      provider.profileStatus === "Incomplete"
                        ? "bg-gray-100 "
                        : "bg-green-200"
                    )}
                  >
                    {provider.profileStatus}
                  </span>
                </td>

                <td className="px-4 py-4 whitespace-nowrap text-sm ">
                  <FormattedDropdown
                    status={provider.actionStatus}
                    dropDownOptions={DROPDOWN_OPTIONS}
                    onClick={(selectedOption) => {
                      setModalOpen(true);
                      setSelectedUserEmail(provider.username);
                      setSelectedOption(selectedOption);
                    }}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                  <span
                    onClick={() => {
                      setProviderData({
                        id: provider.id,
                        name: provider.name,
                      });
                      setActiveView(EDIT_PROVIDER_PATH);
                    }}
                    className="text-blue-chill hover:text-blue-light cursor-pointer"
                  >
                    Edit
                  </span>
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-left text-sm font-medium ">
                  <span
                    className="coral_red hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      setArchiveProviderId(provider.id);
                      setArchiveModalOpen(true);
                    }}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))
          ) : searchedProvider?.id ? (
            <tr key={searchedProvider?.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                    {searchedProvider?.image ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={searchedProvider?.image}
                      />
                    ) : (
                      <ProfileImage />
                    )}
                  </span>
                  <div
                    onClick={() => {
                      setProviderData({
                        id: searchedProvider?.id,
                        name: searchedProvider?.name,
                      });
                      setActiveView(EDIT_PROVIDER_PATH);
                    }}
                    className="ml-4"
                  >
                    <span className="text-sm font-medium eclipse cursor-pointer">
                      {searchedProvider?.name}
                    </span>
                    <br />
                    <span className="bali_hai text-sm cursor-pointer">
                      {searchedProvider?.username}
                    </span>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm bali_hai">
                  {searchedProvider?.states}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm bali_hai">
                {searchedProvider?.providerType}
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <span
                  className={ClassNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bali_hai bg-gray-100",
                    searchedProvider?.profileStatus === "Incomplete"
                      ? "bg-gray-100 "
                      : "bg-green-200"
                  )}
                >
                  {searchedProvider?.profileStatus}
                </span>
              </td>

              <td className="px-4 py-4 whitespace-nowrap text-sm w-64">
                <FormattedDropdown
                  status={searchedProvider?.actionStatus}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={(selectedOption) => {
                    setModalOpen(true);
                    setSelectedUserEmail(searchedProvider?.username);
                    setSelectedOption(selectedOption);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <span
                  onClick={() => {
                    setProviderData({
                      id: searchedProvider?.id,
                      name: searchedProvider?.name,
                    });
                    setActiveView(EDIT_PROVIDER_PATH);
                  }}
                  className="royal_blue hover:text-indigo-900 cursor-pointer"
                >
                  Edit
                </span>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-left text-sm font-medium ">
                <span
                  className="coral_red hover:text-red-800 cursor-pointer"
                  onClick={() => {
                    setArchiveProviderId(searchedProvider?.id);
                    setArchiveModalOpen(true);
                  }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ) : (
            !loading && !searchedProvider?.id && "No Data Found"
          )
        }
      />
      {loading && <Spinner />}

      {isModalOpen && selectedOption === "Deactivate" ? (
        <DeactivateModal
          isModalOpen={isModalOpen}
          isLoading={isBtnLoading}
          onDeactivate={() => {
            setIsBtnLoading(true);
            ProviderDeactivate({ email: selectedUserEmail })
              .then((res) => {
                setSelectedOption("");
                setIsBtnLoading(false);
                setModalOpen(false);
                fetchProviders();
              })
              .catch((err) => {
                setSelectedOption("");
                setIsBtnLoading(false);
                setModalOpen(false);
                setApiError(err?.response?.data?.errors || err?.message);
                console.error("[ProviderDeactivate][API_ERROR] - ", err);
              });
          }}
          onCancel={() => {
            setSelectedOption("");
            setModalOpen(false);
          }}
        />
      ) : selectedOption === "Activate" ? (
        <ActivateModal
          isModalOpen={isModalOpen}
          isLoading={isBtnLoading}
          onActivate={() => {
            setIsBtnLoading(true);
            ProviderActivate({ email: selectedUserEmail })
              .then((res) => {
                setSelectedOption("");
                setIsBtnLoading(false);
                setModalOpen(false);
                fetchProviders();
              })
              .catch((err) => {
                setSelectedOption("");
                setIsBtnLoading(false);
                setModalOpen(false);
                setApiError(err?.response?.data?.errors || err?.message);
                console.error("[ProviderActivate][API_ERROR] - ", err);
              });
          }}
          onCancel={() => {
            setSelectedOption("");
            setModalOpen(false);
          }}
        />
      ) : (
        ""
      )}
      {archiveModalOpen && (
        <DeleteModal
          isModalOpen={archiveModalOpen}
          isLoading={isBtnLoading}
          onDelete={() => {
            if (archiveProviderId) {
              setIsBtnLoading(true);
              MoveToArchive(archiveProviderId)
                .then((res) => {
                  fetchProviders();
                  setIsBtnLoading(false);
                  setArchiveModalOpen(false);
                })
                .catch((err) => {
                  console.error(
                    "[PROVIDERS_TABLE][MoveToArchive][API_ERROR - ",
                    err
                  );
                  setIsBtnLoading(false);
                  setArchiveModalOpen(false);
                  setApiError(err?.response?.data?.errors || err?.message);
                });
            }
          }}
          onCancel={() => {
            setIsBtnLoading(false);
            setArchiveModalOpen(false);
          }}
        />
      )}
      {!loading && providers.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
    </>
  );
};
