import { Route, Switch } from "react-router";
import { AdminMainPage } from "Pages/Admin/MainPage/MainPage";
import { CreateAdmins } from "Pages/Admin/CreateAdmins/CreateAdmins";
import { TheEditAdminPage } from "Pages/Admin/EditViewAdmin/EditViewAdmin";
import { AccessLogs } from "Pages/Admin/AccessLogs/AccessLogs";
import {
  BASE_ADMIN_PATH,
  CREATE_ADMIN_PATH,
  EDIT_ADMIN_PATH,
  ADMIN_ACCESS_LOGS,
} from "Constants/Views";

export default function Admin() {
  return (
    <Switch>
      <Route exact path={ADMIN_ACCESS_LOGS} render={() => <AccessLogs />} />
      <Route exact path={EDIT_ADMIN_PATH} render={() => <TheEditAdminPage />} />
      <Route exact path={CREATE_ADMIN_PATH} render={() => <CreateAdmins />} />
      <Route exact path={BASE_ADMIN_PATH} render={() => <AdminMainPage />} />
    </Switch>
  );
}
