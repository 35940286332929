import { useState, useEffect } from "react";
import Table from "Components/Common/Table/Table";
import MainLayout from "Layouts/MainLayout/MainLayout";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { ApiError } from "Components/Common/ApiError";
import { GetAccessLogs } from "Services/API/Admin.service";
import { useAdminContext } from "Context/Admin";
import { formatDate } from "Helpers/DateFormat";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";

const TABLE_HEADINGS = ["Accessed", "Accessed at", "Description", " "];
const logsFormatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      name:
        list?.accessedBy?.firstName + " " + list?.accessedBy?.lastName || "",
      email: list?.accessedBy?.email || "-",
      logCreatedAt: formatDate(list?.createdAt, "DD-MM-YYYY") || "-",
      description: list?.description || "-",
    };
  });
  return formattedResult;
};
export const AccessLogs = () => {
  const { getAdminDetails } = useAdminContext();
  const [accessLogs, setAccessLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    if (getAdminDetails) {
      const { id } = getAdminDetails();
      if (id) {
        setLoading(true);
        GetAccessLogs(id, pageNumber)
          .then((res) => {
            setLoading(false);
            setAccessLogs(logsFormatter(res.data));
            setHasNextPage(res?.pagination?.hasNext);
            setHasPreviousPage(res?.pagination?.hasPrevious);
          })
          .catch((err) => {
            setLoading(false);
            console.log("[GetAccessLogs][API_ERROR] - ", err);
            setApiError(
              errorFormatter(err?.response?.data?.errors || err?.message)
            );
          });
      }
    }
    return () => {
      setAccessLogs([]);
    };
  }, [getAdminDetails, pageNumber]);
  return (
    <MainLayout>
      <div className="p-8 flex flex-col gap-8">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}

        <Header />

        <Table
          heading={<TableHeading />}
          body={
            accessLogs?.length > 0 ? (
              <TableBody accessLogs={accessLogs} />
            ) : (
              !loading && "No Data Found"
            )
          }
        />

        {!loading && accessLogs.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
        {loading && <Spinner />}
      </div>
    </MainLayout>
  );
};
const Header = () => {
  return (
    <div className="flex items-center">
      <span className="font-semibold text-2xl sapphire">Access Logs</span>
    </div>
  );
};
const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};

const TableBody = ({ accessLogs }) => {
  return accessLogs.map((user, index) => (
    <tr key={index}>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="text-sm font-medium eclipse cursor-pointer">
          {user?.name}
        </span>
        <br />
        <span className="bali_hai text-sm cursor-pointer">{user?.email}</span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
        <span className="royal_blue hover:text-indigo-900 cursor-pointer">
          {user?.logCreatedAt}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
        <span className="royal_blue hover:text-indigo-900 cursor-pointer">
          {user?.description}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
        <span className="text-blue-chill hover:text-blue-light cursor-pointer">
          View Details
        </span>
      </td>
    </tr>
  ));
};
