import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "Components/Common/Button/Button";
import MembershipPriceUpdateModal from "Components/Modal/BasicModal";
import ClassNames from "Helpers/Common";
import { InputField } from "Components/Common/InputField/InputField";
import { ApiError } from "Components/Common/ApiError";
import { MembershipPriceUpdate } from "Services/API/Membership.Service";
import { ReactComponent as DollarIcon } from "Assets/Dollar.svg";
import { ReactComponent as CreditCard } from "Assets/CreditCard.svg";

export const PriceUpdateModal = ({
  icon,
  heading = "Update Pricing",
  content = "Enter the amount and press confirm to update membership pricing",
  btnText = "Confirm",
  modalOpen,
  setPriceModal,
  onSuccess,
  planId,
}) => {
  const [newPrice, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  return (
    <MembershipPriceUpdateModal open={modalOpen} setOpen={modalOpen}>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div className="flex flex-col gap-2">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-transparent">
            <CreditCard className="w-8 h-8 pointer-events-none" />
          </div>
          {apiError && <ApiError error={apiError} setApiError={setApiError} />}
          <div className="text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {heading}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{content}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-col gap-4">
          <InputField
            id="price-update"
            type="text"
            isRequired={true}
            onChange={(fieldValue) => {
              setPrice(fieldValue);
            }}
          >
            <DollarIcon className="w-5 h-5 light_grayish_navy pointer-events-none" />
          </InputField>
          <Button
            className={ClassNames(
              "inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 0 text-sm font-medium   focus:outline-none sm:text-sm"
            )}
            btnText={btnText}
            isDisabled={!newPrice}
            isLoading={loading}
            onClick={() => {
              setLoading(true);
              MembershipPriceUpdate(planId, {
                price: Number(newPrice),
                currency: "usd",
              })
                .then((res) => {
                  setPriceModal(false);
                  onSuccess?.();
                })
                .catch((err) => {
                  console.error("[MembershipPriceUpdate][API_ERROR] ===>", err);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          />
          <Button
            variant="transparent"
            className="text-sm font-medium sapphire border border-gray-200 py-2 px-4 inline-flex rounded-md w-full"
            btnText="Cancel"
            isDisabled={false}
            onClick={() => setPriceModal(false)}
          />
        </div>
      </div>
    </MembershipPriceUpdateModal>
  );
};
