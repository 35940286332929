export const TableHeading = ({TableHeader}) => {
    return TableHeader.map((head, index) => (
      <th
        key={index}
        scope="col"
        className="bali_hai px-6 py-3 text-left text-sm font-medium"
      >
        {head}
      </th>
    ));
  };