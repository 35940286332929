import Axios from "Services/Auth/AxiosInterceptors";
/**
 * @description API for getting provider General Information
 * @param {String} id The Id of the provider
 * @method {Get}
 * @returns {Object} return the General Information Object of the provider
 */
export const GetGeneralInformation = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/admin/doctor/get/${id}`);
  return result;
};
/**
 * @description API for posting provider General Information
 * @param {String} id The Id of the provider
 * @method {Patch}
 * @param {Object} payload Object containing General Information of the provider
 * Payload Example {
 *  "firstName": "Tenma",
    "lastName": "Kenzo",
    "birthdate": "1998-09-16",
    "NPI": "9438718543",
    "typeOfProvider": "Medical Doctor",
    "referenceName1": "Dr.Kureha1",
    "referenceNumber1": "923461315221",
    "referenceName2": "Dr.Kureha2",
    "referenceNumber2": "923461315222",
    "referenceName3": "Dr.Kureha3",
    "referenceNumber3": "923461315223",
    "resume": "22a3c4cf6422837c59de943063fd8341"
 * }
 * @returns
 */
export const UpdateGeneralInformation = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/admin/doctor/general/update/${id}`, payload);
  return result;
};
/**
 * @description API for fetching provider Training Information
 * @param {String} id The Id of the provider
 * @method {GET}
 * @returns return the Training Information Object of the provider
 */
export const GetTrainingInformation = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/training/training/get/${id}`);
  console.log("[GetTrainingInformation][API_RESPONSE] - ", result);
  return result;
};
/**
 *  @description API for getting provider Training Information
 *  @param {String} id The Id of the provider
 *  @method {Patch}
 *  @param {*} payload Object containing Training Information of the provider
 *  Payload Example {
  *  "residencyProgramName": "some name",
    "residencyStreetAddress": "some lame street name",
    "residencyCity": "some city",
    "residencyZipcode": "321456",
    "residencyGraduationDate": "2029-12-25T09:06:51.932Z",
    "residencyProgramDirector": "Some director",
    "residencyGraduationCertificateDocument": "93577fdf0f9d8c94b0016b993218f744",
    "internshipProgramName": "some name",
    "internshipStreetAddress": "some lame street name",
    "internshipCity": "some city",
    "internshipZipcode": "321456",
    "internshipCompletionDate": "2029-12-25T09:06:51.932Z",
    "internshipProgramDirector": "Some director",
    "internshipCertificateDocument": "93577fdf0f9d8c94b0016b993218f744"
  * }
  * @returns
  */
export const UpdateTrainingInformation = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/training/${id}`, payload);
  console.log("[UpdateTrainingInformation][API_RESPONSE] - ", result);
};

/**
 *
 * @description API for getting provider EducationDetails
 * @param {String} id The Id of the provider
 *  @method {Get}
 * @returns return the EducationDetails Object of the provider
 */
export const GetEducationDetails = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/education/education/get/${id}`);
  return result;
};
/**
 *
  * @description API for getting provider EducationDetails
  * @param {String} id The Id of the provider
  * @method {Patch}
  * @param {*} payload Object containing EducationDetials of the provider
  *  Payload Example {
  *  "boardCertified": true,
    "boardCertifiedWith": "321321321",
    "boardCertificateDocument": "93577fdf0f9d8c94b0016b993218f744",
    "medicalSchoolAttended": "Some Medical School",
    "medicalSchoolGraducationDate": "2021-12-25T09:06:51.932Z",
    "medicalDegreeDocument": "93577fdf0f9d8c94b0016b993218f744"
  * }
 */
export const UpdateEducationDetails = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/education/${id}`, payload);
  console.log("[UpdateEducationDetails][API_RESPONSE] - ", result);
  return result;
};

/**
 * @description API for getting the Provider FIles
 * @param {String} id The Id of the provider
 * @returns returns the Provider Files Object
 */

export const GetUserFiles = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/files/files/get/${id}`);
  console.log("[GetUserFiles][API_RESPONSE] - ", result);
  return result;
};
/**
 *
 * @description API for updating the Provider FIles
 * @param {String} id The Id of the provider
 * @param {Object} payload Object containing User Files of the provider
 * Example {
 *  "socialSecurityCard": "2b1a66d73b883d111816c0b61f901fee",
    "IRS_W9form": "2b1a66d73b883d111816c0b61f901fee",
    "providerCompletedApplication": "2b1a66d73b883d111816c0b61f901fee",
    "HIPAAconfidentialityAgreement": "08cfba8dea6f96442c414d462daffc3a",
    "instacuredTechnologySecurityAndPrivacyAgreement": "c8ec4502181d588bd42227df8b4a6308",
    "instacuredProviderApplication": "08cfba8dea6f96442c414d462daffc3a",
 * }
 * @returns
 */
export const UpdateUserFiles = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/files/${id}`, payload);
  console.log("[UpdateUserFiles][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description API for getting provider Security Details
 * @param {String} id The Id of the provider
 * @returns return the Security Details Object of the provider
 */
export const GetSecurityDetails = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/security/security/get/${id}`);
  console.log("[GetSecurityDetails][API_RESPONSE] - ", result);
  return result;
};
/**
  * @description API for updating provider Security Details
  * @param {String} id The Id of the provider
  * @param {*} payload Object containing Security Detials of the provider
  *  Example {
  *  "backgroundCheckStartDate": "2021-12-25T09:06:51.932Z",
    "backgroundCheckPassed": true,
    "backgroundAuthorizationDocument": "93577fdf0f9d8c94b0016b993218f744",
    "otherDocuments": ["619f76a9bae60f0ba7ce7746","619f76a9bae60f0ba7ce7746"]
  * }
 * @returns
 */
export const UpdateSecurityDetails = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/security/${id}`, payload);
  console.log("[UpdateSecurityDetails][API_RESPONSE] - ", result);
  return result;
};

/**
 * @description API for getting Provider Med Malpractice Information
 * @param {String} id id The Id of the provider
 * @returns
 */
export const GetMedMalpracticeDetails = async (id) => {
  const {
    data: { result },
  } = await Axios.get(
    `/medicalmalpracticeinsurance/medicalmalpracticeinsurance/get/${id}`
  );
  console.log("[GetMedMalpracticeDetails][API_RESPONSE] - ", result);
  return result;
};

/**
  * @description API for updating Provider Med Malpractice Information
 * @param {*} payload An array of objects containing MedMalpractice Information of the provider
 * Payload Example {
 * [{
    "nameOfCompany": "XYZ",
    "agentIssuingPolicyName": "ABC",
    "agentIssuingPolicyNumber": "090078601",
    "currentExpirationDate": "2021-12-25T09:06:51.932Z",
    "statesCovered": ["619f76a9bae60f0ba7ce7746"],
    "malpracticeCertificateDocument": "93577fdf0f9d8c94b0016b993218f744"
}]
 * }
 * @returns
 */
export const UpdateMedMalpracticeDetails = async (payload) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/medicalmalpracticeinsurance/medicalmalpracticeinsurance/update`,
    payload
  );
  console.log("[UpdateMedMalpracticeDetails][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description API for getting Provider Fellowship Information
 * @param {String} id id The Id of the provider
 * @returns
 */
export const GetFellowshipDetails = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/fellowship/fellowship/get/${id}`);
  console.log("[GetFellowshipDetails][API_RESPONSE] - ", result);
  return result;
};
/**
*  @description API for updating Provider Fellowship Information
 * @param {*} payload An array of objects containing Fellowship Information of the provider
 * Payload Example {
 * [{
    "speciality": "XYZ",
    "fellowshipDirectorName": "ABC",
    "fellowshipProgramName": "some program",
    "fellowshipProgramStreetAddress": "some street name",
    "fellowshipProgramCity": "GHI",
    "fellowshipProgramZipcode": "564321",
    "fellowshipCertificateDocument": "93577fdf0f9d8c94b0016b993218f744
}]
 * }
 * @returns
 */
export const UpdateFellowshipDetails = async (payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/fellowship/fellowship/update/`, payload);
  console.log("[UpdateFellowshipDetails][API_RESPONSE] - ", result);

  return result;
};

/**
 * @description API for getting Provider State Medical License
 * @param {String} id The Id of the provider
 * @returns
 */
export const GetStateMedLicense = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/statemedicallicense/statemedicallicense/get/${id}`);
  return result;
};
/**
 * @description API for updating Provider State Medical License
 * @param {Object} payload An array of objects containing State Medical Licenses of the provider
 * Payload Example {
 * [
    {
    "licenseState": "612ca24264a6ea401db2f261",
    "licenseNumber": "323145654",
    "licensedDate": "2021-12-25T09:06:51.932Z",
    "licenseExpiration": "2029-12-25T09:06:51.932Z",
    "licenseDocument": "93577fdf0f9d8c94b0016b993218f744"
    }
]
 * }
 */
export const UpdateStateMedLicense = async (payload) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/statemedicallicense/statemedicallicense/update`,
    payload
  );
  console.log("[UpdateStateMedLicense][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description API for getting Provider Drug Enforcement Administration Registeration Information
 * @param {String} id id The Id of the provider
 * @returns
 */
export const GetDEAInfo = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/dea/dea/get/${id}`);
  return result;
};
/**
 * @description API for updating Provider DEA Information
 * @param {Object} payload For updating Provider DEA Information
 * Payload Example {
 *  "deaState": "610a858279d028267a753113",
    "deaNumber": "321321321",
    "deaExpiration": "2021-12-25T09:06:51.932Z",
    "deaDocument": "93577fdf0f9d8c94b0016b993218f744"
 * }
 * @returns
 */
export const UpdateDEAInfo = async (payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/dea/dea/update`, payload);
  return result;
};

export const GetPatientsRecord = async (
  provider_Id,
  page = 1,
  perPage = 50,
  payload
) => {
  const {
    data: { result },
  } = await Axios.post(
    `admin/doctor/filter/visits/${provider_Id}?page=${page}&perPage=${perPage}`,payload
  );
  console.log("[GetPatientsRecord][API_RESPONSE] =>", result);
  return result;
};
