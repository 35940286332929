import { formatDate, formatDate2 } from "./DateFormat";

export function formatPatientStatus(status) {
  switch (status) {
    case "Disabled":
      return "Deactivated";
    case "Enabled":
      return "Activated";
    default:
      throw new Error(
        `Unregistered status "${status}" found in patient record`,
      );
  }
}
export const patientFormatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id,
      name: list?.name?.replaceAll("-","") || "",
      firstName: list?.firstName || "-",
      lastName: list?.lastName || "-",
      userName: list?.username || "",
      gender: list?.gender || "-",
      phoneNumber: list?.phone_number || "-",
      country: list?.country || "-",
      zipCode: list?.zipcode || "-",
      dosespotId: list?.dosespotId || "",
      cognitoId: list?.cognitoId || "",
      memberShipPlan: list?.membership?.name || "-",
      streetAddress: list?.address || "-",
      state: list?.state?.name || "- - -",
      stateId: list?.state?.id || "",
      DOB: formatDate(list?.dateOfBirth, "MM/DD/YYYY") || "-",
      image: list?.image || "",
      actionStatus: formatPatientStatus(list?.status) || "",
      city: list?.city || "-",
      address1: list?.address1 || "-",
      address2: list?.address2 || "-",
      prefix: list?.prefix || "-",
      suffix: list?.suffix || "-",
      middleName: list?.middleName || "-",
      phoneType: list?.primaryPhoneType || "-",
    };
  });
  return formattedResult;
};

export const patientMedicationsFormatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id,
      medicineName: list?.medicationId.name || "",
      dosage: list?.dosage || "",
      medicineUsages: list?.frequency || "",
    };
  });
  return formattedResult;
};
export const getMedicalHistoryFormatter = ({
  bmi,
  alcohol,
  tobacco,
  sexualHistory,
  userAllergies,
  familyHistory,
  surgicalHistory,
  immunizationHistory,
  userImmunization,
  userSurgical,
}) => {
  const formatFamilyHistory = (familyHistory) => {
    return familyHistory?.map((history) => {
      return history?.name;
    });
  };
  const formatImmunization = (immunizationHistory) => {
    return immunizationHistory?.map((immuniztion) => {
      return immuniztion?.name;
    });
  };
  const formatSurgicalHistory = (surgicalHistory) => {
    return surgicalHistory?.map((surgery) => {
      return surgery?.name;
    });
  };
  const formatUserAllergy = (userAllergy) => {
    return userAllergy?.map((allergy) => {
      return {
        name: allergy?.allergyId.name || "-",
        type: allergy?.allergyId.type || "-",
        reaction: allergy?.reaction || "-",
      };
    });
  };
  const formatUserImmunization = (userImmunization) => {
    const formatImmunizationForm = (immunizations) => {
      return immunizations?.map((immunization) => {
        return {
          id: immunization.id,
          name: immunization.name,
          subName: immunization?.children?.map((child) => {
            if (child.selected) {
              return { id: child?.id, name: child.name };
            }
          }),
        };
      });
    };
    return {
      id: userImmunization.id,
      immuniztionName: formatImmunizationForm(
        userImmunization?.immunizationForm,
      ),
    };
  };
  const formatUserSurgical = (userSurgical) => {
    return userSurgical?.custom?.map((surgery) => {
      return { name: surgery?.name ?? "" };
    });
  };
  return {
    heightFeet: bmi?.feet ?? "-",
    heightInches: bmi?.inches ?? "-",
    weight: bmi?.weightLbs ?? "-",
    allergies: formatUserAllergy(userAllergies) || "-",
    alcoholConsumption: alcohol || "-",
    tobacoPerWeek: tobacco?.tobaccoPerWeek || "",
    tobacoAnnual: tobacco?.tobaccoYears || "",
    surgicalHistory: formatSurgicalHistory(surgicalHistory) || "",
    familyHistory: formatFamilyHistory(familyHistory) || "-",
    immunizationHistory: formatImmunization(immunizationHistory) || "",
    userImmunization: formatUserImmunization(userImmunization) || "",
    userSurgical: formatUserSurgical(userSurgical) || "",
    sexualHistory: sexualHistory ? "Yes" : "No",
  };
};

export const patientVisitsFormatter = (data) => (
  data.map((item) => ({
    id: item?._id,
    status: item.status,
    states: item.states,
    offerActiveMinutes: item.offerActiveMinutes ? `${item.offerActiveMinutes} mins` : 'N/A',
    docName: item?.doctorFirstName && item?.doctorLastName ? `${item.doctorFirstName} ${item.doctorLastName}` : 'None',
    doctorUsername: item?.doctorUsername || '',
    createdAt: formatDate2(item?.createdAt),
    startedAt: formatDate2(item?.startedAt),
    endedAt: formatDate2(item?.endedAt),
    waitStartedAt: formatDate2(item?.waitStartedAt),
    waitEndedAt: formatDate2(item?.waitEndedAt),
  }))
)
