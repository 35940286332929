export const Card = ({ heading, count, icon, textClass }) => {
  return (
    <div className="rounded-lg bg-white flex items-center pl-6 gap-3 py-3 shadow-lg ">
      {icon}
      <div className="flex flex-col">
        <span className="text-base leading-6 font-normal sapphire">
          {heading}
        </span>
        <span className={`text-2xl leading-9 font-normal ${textClass ? textClass : 'text-blue-chill'}`}>
          {count}
        </span>
      </div>
    </div>
  );
};
