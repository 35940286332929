import { EDIT_TABS, TABS } from "Constants/Provider";
import ClassNames from "Helpers/Common";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import "Colors.css";

export const TabsPanel = ({
  onBack,
  activeTabIndex,
  setActiveTabIndex,
  name = "Profile",
  addProviderFlow = false,
}) => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <div onClick={onBack} className="flex items-center gap-4 cursor-pointer">
        <BackIcon className="h-6 w-4 sapphire" />
        <span className="sapphire text-base font-semibold"> {name}</span>
      </div>
      <>
        {addProviderFlow
          ? TABS.map((tab, index) => (
              <div
                onClick={() => setActiveTabIndex && setActiveTabIndex(index)}
                key={index}
                className={ClassNames(
                  "flex items-center p-4 gap-4 cursor-pointer",
                  tab === TABS[activeTabIndex] && "border-blue-chill border-l-2"
                )}
              >
                <span
                  className={ClassNames(
                    "font-normal text-xs",
                    tab === TABS[activeTabIndex]
                      ? "text-blue-chill"
                      : "light_grayish_navy"
                  )}
                >
                  {tab}
                </span>
              </div>
            ))
          : EDIT_TABS?.map((tab, index) => (
              <div
                onClick={() => {
                  setActiveTabIndex?.(index);
                }}
                key={index}
                className={ClassNames(
                  "flex items-center p-4 gap-4 cursor-pointer",
                  tab === EDIT_TABS[activeTabIndex] &&
                    "border-blue-chill border-l-2"
                )}
              >
                <span
                  className={ClassNames(
                    "font-normal text-xs",
                    tab === EDIT_TABS[activeTabIndex]
                      ? "text-blue-chill"
                      : "light_grayish_navy"
                  )}
                >
                  {tab}
                </span>
              </div>
            ))}
      </>
    </div>
  );                      
};
