import { useState, useEffect } from "react";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import MainLayout from "Layouts/MainLayout/MainLayout";
import {
  AddROS,
  DeleteROS,
  EnableDisableROS,
  ListROS,
  UpdateROS,
} from "Services/API/IntakeForms.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import Table from "Components/Common/Table/Table";
import { TableHeading } from "Components/Provider/ProviderMainPage/TableHeading/TableHeading";
import { FormattedDropdown } from "Components/Common/FormattedDropdown/FormattedDropDown";
import ClassNames from "Helpers/Common";
import { DROPDOWN_OPTIONS } from "Constants/DropDown";
import { IntakeFormBaseModal } from "./IntakeFormModal";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { useGlobalContext } from "Context/Global";
import { SECOND_LEVEL_ROS } from "Constants/Views";
import { useParams } from "react-router-dom";

const TABLE_HEADINGS = [
  "S.No",
  "Form",
  "Symptom",
  "Status",
  "Actions",
  " ",
  " ",
];
const ROS_INIT = { mode: "", name: "", id: "" };

export const IntakeForms = () => {
  const [addROS, setAddRos] = useState(false);
  const [rosList, setRosList] = useState([]);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const getROSListFL = () => {
    setLoading(true);
    ListROS(pageNumber)
      .then((res) => {
        setRosList([...res?.data]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("[ListROS][API_ERROR] ===>", err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message)
        );
        setLoading(false);
      });
    return () => {
      setRosList([]);
    };
  };

  useEffect(() => {
    getROSListFL();
  }, [pageNumber]);

  return (
    <MainLayout>
      <div className="p-8 flex flex-col gap-8">
        {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}

        <Header setAddRos={setAddRos} />

        <Table
          heading={<TableHeading TableHeader={TABLE_HEADINGS} />}
          body={
            <RosTableBody
              addROS={addROS}
              data={rosList}
              loading={loading}
              setAddRos={setAddRos}
              setApiError={setApiError}
              onSuccess={() => getROSListFL()}
            />
          }
        />
        {loading && <Spinner />}

        {/* {!loading && admins.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )} */}
      </div>
    </MainLayout>
  );
};
const Header = ({ setAddRos }) => {
  return (
    <div className="flex justify-between items-center ">
      <div className="flex items-center ">
        {" "}
        <span className="font-semibold text-2xl sapphire">
        Intake Forms
        </span>
      </div>
      <div className="flex items-center gap-4">
        <Button
          className="w-full bg_royal_blue hover:bg-blue-light text-white font-normal text-sm py-1 px-6 inline-flex rounded-md"
          btnText="Create a Form"
          isDisabled={false}
          onClick={() => setAddRos(true)}
        />
      </div>
    </div>
  );
};

export const RosTableBody = ({
  data,
  setApiError,
  setAddRos,
  addROS,
  onSuccess,
  loading,
}) => {
  const { limit } = useParams();
  const { setActiveView } = useGlobalContext();
  const [showModal, setShowModal] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [rosDetails, setRosDetails] = useState(ROS_INIT);

  useEffect(() => {
    if (addROS) {
      setShowModal(true);
    }
  }, [addROS]);
  return (
    <>
      {!loading && data?.length > 0
        ? data?.map((item, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium text-black-eclipse">
                {limit < 3 ? item?.name : !limit && index + 1}
              </td>
              {!limit && (
                <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium cursor-pointer text-black-eclipse">
                  {item?.name}
                </td>
              )}
              {limit!=2&&<td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <span
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                  onClick={() => {
                    if (!limit) {
                      setActiveView(
                        SECOND_LEVEL_ROS.replace(":id", item?.id)
                          .replace(":name", item?.name)
                          .replace(":limit", 1)
                      );
                    }
                    if (Number(limit) < 2) {
                      setActiveView(
                        SECOND_LEVEL_ROS.replace(":id", item?.id)
                          .replace(":name", item?.name)
                          .replace(":limit", Number(limit) + 1)
                      );
                    }
                  }}
                >
                  View
                </span>
              </td>}
              <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium text-black-eclipse">
                <span
                  className={ClassNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bali_hai bg-gray-100",
                    item?.disabled ? "bg-gray-100 " : "bg-green-200"
                  )}
                >
                  {item?.disabled ? "InActive" : "Active"}
                </span>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm w-64 text-gray-500">
                <FormattedDropdown
                  status={item?.disabled ? "Deactivated" : "Activated"}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={async () => {
                    try {
                      const res = await EnableDisableROS(item?.id);
                      if (res?.id) {
                        onSuccess?.();
                      }
                    } catch (err) {
                      console.error("[EnableDisableROS][API_ERROR] ===>", err);
                    }
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setRosDetails({
                      mode: "Edit",
                      name: item.name,
                      id: item.id,
                    });
                  }}
                >
                  Edit
                </span>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  className="coral_red hover:text-red-800 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setRosDetails({
                      mode: "Delete",
                      name: item.name,
                      id: item.id,
                    });
                  }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ))
        : !loading &&
          data?.length <= 0 && (
            <div className="flex h-80 items-center justify-center">
              No data found{" "}
            </div>
          )}
      <IntakeFormBaseModal
        mode={rosDetails?.mode}
        formValue={rosDetails?.mode === "Edit" ? rosDetails?.name : " "}
        isModalOpen={showModal}
        isLoading={isBtnLoading}
        heading={
          rosDetails?.mode === "Delete"
            ? "Delete in?"
            : rosDetails?.mode === "Edit"
            ? "Edit Form"
            : "Add a Form"
        }
        subHeading={
          rosDetails?.mode === "" &&
          "Enter a title for your form to add it to intake forms"
        }
        onCancel={() => {
          setRosDetails(ROS_INIT);
          setAddRos?.(false);
          setShowModal(false);
        }}
        onDelete={async () => {
          if (rosDetails?.id) {
            setIsBtnLoading(true);
            try {
              const res = await DeleteROS(rosDetails?.id);
              if (res) {
                setRosDetails(ROS_INIT);
                setIsBtnLoading(false);
                setShowModal(false);
                onSuccess?.();
              }
            } catch (err) {
              console.error("[DeleteROS][API_ERROR] ===>", err);
              setIsBtnLoading(false);
              setShowModal(false);
              setApiError?.(
                errorFormatter(err?.response?.data?.errors || err?.message)
              );
            }
          }
        }}
        onConfirm={async (formName) => {
          if (formName) {
            setIsBtnLoading(true);
            try {
              const res = await AddROS({
                name: formName.trim(),
                parentId: null,
              });
              if (res?.id) {
                setIsBtnLoading(false);
                setShowModal(false);
                setAddRos?.(false);
                onSuccess?.();
              }
            } catch (err) {
              console.error("[AddROS][API_ERROR] ===>", err);
              setIsBtnLoading(false);
              setShowModal(false);
              setAddRos?.(false);
              setApiError?.(
                errorFormatter(err?.response?.data?.errors || err?.message)
              );
            }
          }
        }}
        onEdit={async (formName) => {
          if (formName) {
            setIsBtnLoading(true);
            try {
              const res = await UpdateROS(rosDetails?.id, {
                name: formName,
              });
              console.log("UpdateROS result: " + res);
              if (res) {
                setIsBtnLoading(false);
                setShowModal(false);
                setAddRos?.(false);
                onSuccess?.();
              }
            } catch (err) {
              console.error("[UpdateROS][API_ERROR] ===>", err);
              setIsBtnLoading(false);
              setShowModal(false);
              setAddRos?.(false);
              setApiError?.(
                errorFormatter(err?.response?.data?.errors || err?.message)
              );
            }
          }
        }}
      />
    </>
  );
};
