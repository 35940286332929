import { useState, useContext, createContext } from "react";
import { LOGIN } from "Constants/LocalStorage";
import { useHistory } from "react-router";

const GlobalContext = createContext();
const INIT_TOKEN = {
  login: "",
  idToken: "",
  token: "",
  tokenExpiry: "",
  refreshToken: "",
};
const INIT_USER_RIGHTS = {
  providerRights: "",
  patientRights: "",
};
const ADMIN_DETAILS_INITIAL_STATE = {
  name: "",
  id: "",
};

const TokenProvider = ({ children }) => {
  let history = useHistory();
  const [tokenDetails, setToken] = useState(INIT_TOKEN);
  const [userRights, setUserRights] = useState(INIT_USER_RIGHTS);
  const [adminDetail, setAdminDetails] = useState(ADMIN_DETAILS_INITIAL_STATE);

  const setActiveView = (view) => {
    history.push(view);
  };
  const setTokenDetails = ({
    accessToken,
    tokenExpiry,
    refreshToken,
    idToken,
  }) => {
    const token = {
      login: true,
      idToken: idToken,
      token: accessToken,
      tokenExpiry: tokenExpiry,
      refreshToken: refreshToken,
    };
    localStorage.setItem(LOGIN, JSON.stringify(token));
    setToken(token);
  };
  const setAdminRights = (userPremission) => {
    setUserRights(userPremission);
    localStorage.setItem("USER_RIGHTS", JSON.stringify(userPremission));
  };
  const getToken = () => {
    const token = JSON.parse(localStorage.getItem(LOGIN));
    if (token) {
      return token.idToken;
    }
    if (tokenDetails) {
      return tokenDetails.idToken;
    }
    return;
  };
  const getAdminRights = () => {
    const rights = JSON.parse(localStorage.getItem("USER_RIGHTS"));
    if (rights) {
      return rights;
    }
    if (userRights.providerRights) {
      return userRights;
    }
  };
  const resetCredentials = () => {
    setToken(INIT_TOKEN);
    setAdminDetails(ADMIN_DETAILS_INITIAL_STATE);
    localStorage.setItem(LOGIN, JSON.stringify(INIT_TOKEN));
    localStorage.setItem(
      "USER_DETAILS",
      JSON.stringify(ADMIN_DETAILS_INITIAL_STATE)
    );
  };
  const setAdminDetail = (adminDetails) => {
    localStorage.setItem("USER_DETAILS", JSON.stringify(adminDetails));
    setAdminDetails(adminDetails);
  };
  const getAdminDetail = () => {
    const adminDetails = JSON.parse(localStorage.getItem("USER_DETAILS"));
    if (!adminDetails?.name) {
      resetCredentials()
      window.location.reload()
    }
    if (adminDetails?.name) {
      return adminDetails;
    }
    if (adminDetails?.name) {
      return adminDetails;
    }
    return;
  };

  const resetAdminRights = () => {
    setUserRights(INIT_USER_RIGHTS);
    localStorage.setItem("USER_RIGHTS", JSON.stringify(INIT_USER_RIGHTS));
  };

  return (
    <GlobalContext.Provider
      value={{
        getToken,
        setTokenDetails,
        resetCredentials,
        setActiveView,
        setAdminRights,
        getAdminRights,
        resetAdminRights,
        setAdminDetail,
        getAdminDetail,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export { GlobalContext, TokenProvider };
