import { useState, useEffect } from "react";
import { InputField } from "Components/Common/InputField/InputField";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { GetStates } from "Services/API/GetStates.service";
import { formattedStates } from "Helpers/Providers";
import { formatDate } from "Helpers/DateFormat";
import { DEAForm } from "Components/Provider/AddProvider/Forms/DrugEnfAdminReg/DEAForm";
import { Divider } from "Components/Common/Divider/Divider";

export const EditForm = ({ providerFormDetail, setProviderFormDetail }) => {
  const [states, setStates] = useState([]);
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    GetStates()
      .then((res) => {
        //TODO: Make global context for states and move formatter in that
        setStates(formattedStates(res?.data));
      })
      .catch((err) => {
        console.error("[GetStates][API_ERROR] - ", err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-6">
      {providerFormDetail.length > 0 &&
        providerFormDetail.map((form, index) => (
          <div className="grid grid-cols-2 gap-6 pb-4" key={index}>
            <span className="col-span-2 font-semibold text-base sapphire">
              DEA {index + 1}
            </span>
            <div className="col-span-2 lg:col-span-1">
              <Dropdown
                id="select-state"
                label="DEA State"
                onBlur={(fieldValue) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            deaState: fieldValue && fieldValue.trim(),
                          }
                        : userDetail
                    )
                  );
                }}
              >
                <Dropdown.Option
                  id={form?.licenseState}
                  value={form?.deaStateID}
                  label={form?.deaState}
                  isDefaultOption={false}
                />
                {states.length > 0 &&
                  states.map((state, index) => (
                    <Dropdown.Option
                      key={index}
                      id={state.id}
                      value={state.id}
                      label={state.name}
                    />
                  ))}
              </Dropdown>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                id={`license-number-${index}`}
                label="Registration Number"
                value={form?.deaNumber}
                onBlur={(fieldValue) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            deaNumber: fieldValue && fieldValue.trim(),
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <DatePicker
                label="DEA Issue Dated"
                value={form?.deaDate}
                onBlur={(licenseIssueDate) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            deaDate: licenseIssueDate,
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <DatePicker
                label="DEA Expiration"
                value={form?.deaExpiration}
                onBlur={(expDate) => {
                  console.log(expDate);
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            deaExpiration: expDate,
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>

            <div className="col-span-2">
              <UploadFile
                mode="Edit"
                id={`certificate-${index}`}
                value={form?.deaDocument}
                label="DEA Card"
                onResponse={({ data }) => {
                  setProviderFormDetail(
                    providerFormDetail.map((userDetail) =>
                      userDetail.id === form.id
                        ? {
                            ...userDetail,
                            deaDocument: data,
                          }
                        : userDetail
                    )
                  );
                }}
              />
            </div>
          </div>
        ))}
      {Array(count)
        .fill("")
        .map((_, index) => (
          <DEAForm
            key={providerFormDetail.length + 1}
            id={providerFormDetail.length + 1}
            postfix={providerFormDetail.length + 1}
            states={states}
            formDetails={providerFormDetail}
            setFormDetail={(forms) => {
              setProviderFormDetail(forms);
              setCount(0);
            }}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setProviderFormDetail(
                providerFormDetail.filter(
                  (formInstance) => formInstance.id !== id
                )
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}
      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
