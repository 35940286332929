import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";
import { formatDate } from "Helpers/DateFormat";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <span className="col-span-2 font-semibold text-base sapphire">
        Residency
      </span>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Program Name</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.programName}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Street Address</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.streetAddress}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>City</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.city}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Zip Code</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.zipCode}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Graduation Date</span>
        <span className={SUB_HEADING_CSS}>
          {formatDate(providerFormDetail?.graduationDate, "MM-DD-YYYY")}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Program Director</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.directorName}
        </span>
      </div>
      <div className="flex flex-col col-span-2 gap-2">
        <UploadFile
          value={providerFormDetail?.file}
          label="Residency Graduation Certificate"
        />
      </div>
      <span className="col-span-2 font-semibold text-base sapphire">
        Internship
      </span>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Program Name </span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.internProgramName}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Street Address</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.internStreetAddress}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>City</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.internCity}
        </span>
      </div>{" "}
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>ZIp Code</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.internZipCode}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Completion Date</span>
        <span className={SUB_HEADING_CSS}>
          {formatDate(providerFormDetail?.internCompletionDate, "MM-DD-YYYY")}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Program Director</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.internProgramDirector}
        </span>
      </div>
      <div className="flex flex-col col-span-2 gap-2">
        <UploadFile
          value={providerFormDetail?.internDocument}
          label="Internship Certificate"
        />
      </div>
    </div>
  );
};
