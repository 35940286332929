import ClassNames from "Helpers/Common";
import { useGlobalContext } from "Context/Global";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import { Button } from "Components/Common/Button/Button";
import MainLayout from "Layouts/MainLayout/MainLayout";
import { MedConditionTable } from "Components/MedConditions/MainPage/Tables/MedConditionTable";
import { FamilyHistoryTable } from "Components/MedConditions/MainPage/Tables/FamilyHistoryTable";
import "Colors.css";
import { AddMedConditionModal } from "./AddMedConditionModal";
import { useState } from "react";
import {
  AddFamilyConditions,
  AddMedCondition,
  SearchFamConditions,
  SearchMedConditions,
} from "Services/API/MEDConditions.service";
import SearchBar from "Components/Common/SearchBar/SearchBar";
import { useEffect } from "react";

const MED_CONDITIONS = "Medical Conditions";
const FAMILY_HISTORY = "Family History";

export const MedConditionsMainPg = () => {
  const [activeTab, setActiveTab] = useLocalStorage(
    "medsConditionsTable",
    MED_CONDITIONS,
  );
  const [addMedConditionModal, setAddMedConditionModal] = useState(false);
  const [addedNewMedCondition, setAddedNewMedCondition] = useState(false);
  const [selectedResult, setselectedResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <MainLayout>
      <div className="p-8 flex flex-col gap-8">
        <Header
          activeTab={activeTab}
          setselectedResult={setselectedResult}
          setAddMedConditionModal={setAddMedConditionModal}
          onTabChange={(tab) => setActiveTab(tab)}
        />
        {activeTab === MED_CONDITIONS && (
          <MedConditionTable
            selectedResult={selectedResult}
            addedNewMedCondition={addedNewMedCondition}
            setAddedNewMedCondition={setAddedNewMedCondition}
          />
        )}
        {activeTab === FAMILY_HISTORY && (
          <FamilyHistoryTable
            selectedResult={selectedResult}
            addedNewMedCondition={addedNewMedCondition}
            setAddedNewMedCondition={setAddedNewMedCondition}
          />
        )}
        {addMedConditionModal && (
          <AddMedConditionModal
            isModalOpen={addMedConditionModal}
            onCancel={() => setAddMedConditionModal(false)}
            isLoading={isLoading}
            onConfirm={(medConditionName, ICDcode) => {
              setIsLoading(true);
              if (activeTab === MED_CONDITIONS) {
                AddMedCondition({
                  name: medConditionName,
                  ICDCode: ICDcode,
                  active: true,
                })
                  .then((res) => {
                    if (res?.id) {
                      setIsLoading(false);
                      setAddMedConditionModal(false);
                      setAddedNewMedCondition(!addedNewMedCondition);
                    }
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.error("[AddMedCondition][API_ERROR] -", err);
                  });
              } else if (activeTab === FAMILY_HISTORY) {
                AddFamilyConditions({
                  name: medConditionName,
                  ICDCode: ICDcode,
                  active: true,
                })
                  .then((res) => {
                    if (res?.id) {
                      setIsLoading(false);
                      setAddMedConditionModal(false);
                      setAddedNewMedCondition(!addedNewMedCondition);
                    }
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.error("[AddMedCondition][API_ERROR] -", err);
                  });
              }
            }}
          />
        )}
      </div>
    </MainLayout>
  );
};
const Header = ({
  activeTab,
  onTabChange,
  setAddMedConditionModal,
  setselectedResult,
}) => {
  const { getAdminRights } = useGlobalContext();
  const { providerRights } = getAdminRights();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchHealthConditions = () => {
    if (activeTab === MED_CONDITIONS) {
      SearchMedConditions(searchQuery)
        .then((res) => {
          setSearchResults([...res?.data]);
        })
        .catch((err) => {
          console.log("[SearchMedConditions][API_ERROR]", err);
        });
    }
    if (activeTab === FAMILY_HISTORY) {
      SearchFamConditions(searchQuery)
        .then((res) => {
          setSearchResults([...res?.data]);
        })
        .catch((err) => {
          console.log("[SearchFamConditions][API_ERROR]", err);
        });
    }
    if (!searchQuery) {
      setselectedResult([]);
    }
  };
  useEffect(() => {
    if (!searchQuery) {
      setselectedResult([]);
    }
    if (searchQuery?.length > 1) {
      searchHealthConditions();
    }
  }, [searchQuery, activeTab]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-6">
        {" "}
        <span className="font-semibold text-2xl sapphire">
          Health Conditions
        </span>
        <span
          className={ClassNames(
            "light_grayish_navy text-sm font-medium sapphire cursor-pointer text-center mt-1 py-2 px-2 rounded-md",
            activeTab === MED_CONDITIONS &&
              "bg-gray-100 font-semibold text-black",
          )}
          onClick={() => {
            setSearchQuery("");
            onTabChange(MED_CONDITIONS);
          }}
        >
          {MED_CONDITIONS}
        </span>
        <span
          onClick={() => {
            setSearchQuery("");
            onTabChange(FAMILY_HISTORY);
          }}
          className={ClassNames(
            "light_grayish_navy text-sm font-medium cursor-pointer mt-1 py-2 px-2 rounded-md",
            activeTab === FAMILY_HISTORY &&
              "bg-gray-100 font-semibold text-black",
          )}
        >
          {FAMILY_HISTORY}
        </span>
      </div>
      <div className="flex items-center gap-6">
        <SearchBar
          searchQuery={searchQuery}
          searchResults={searchResults}
          isHealthConditionSearch={true}
          setSearchQuery={setSearchQuery}
          setSearchResults={setSearchResults}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              if (activeTab === MED_CONDITIONS) {
                try {
                  const res = await SearchMedConditions(searchQuery);
                  setselectedResult([...res?.data]);
                } catch (error) {
                  console.log("[SearchMedConditions][API_ERROR]==>", error);
                }
              }
              if (activeTab === FAMILY_HISTORY) {
                try {
                  const res = await SearchFamConditions(searchQuery);
                  setselectedResult([...res?.data]);
                } catch (error) {
                  console.log("[SearchFamConditions][API_ERROR]==>", error);
                }
              }
            }
          }}
          onSearch={(selectedResult) => {
            setSearchQuery(selectedResult?.name);
            setselectedResult([selectedResult]);
          }}
        />
        <Button
          className={`w-80  font-normal text-sm py-1 px-6 inline-flex rounded-md ${
            providerRights === "Edit" ? "text-white" : ""
          }`}
          btnText="Add Health Condition"
          isDisabled={providerRights !== "Edit"}
          onClick={() => {
            setAddMedConditionModal(true);
          }}
        />
      </div>
    </div>
  );
};
