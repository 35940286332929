import { ReactComponent as UploadIcon } from "Assets/FileUpload.svg";
import { DownloadFile } from "Services/API/UploadFile.service";
import fileDownload from "js-file-download";
import { useState } from "react";
import "Colors.css";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  const [downloading, setDownloading] = useState(false);

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>First Name</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.firstName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Last Name</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.lastName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Gender</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.gender}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Email</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.userName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Clinician ID</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.clinicianID}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Date of Birth</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.DOB}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>National Provider Identifier</span>
        <span className={SUB_HEADING_CSS}>{providerFormDetail?.NPI}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Type of Provider</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.providerType}
        </span>
      </div>
      <div className="flex flex-col col-span-2 gap-2">
        <span className={HEADING_CSS}>CV/Resume</span>

        <span className="flex justify-between items-center border border-gray-300 py-2 px-3 rounded-md">
          <span className="flex items-center gap-2 text-blue-chill cursor-pointer SUB_HEADING_CSS underline">
            <UploadIcon
              className="h-4 w-4 light_grayish_navy"
              aria-hidden="true"
            />
            <a
              href={providerFormDetail?.file}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-light underline"
            >
              {" "}
              Click here to view the file
            </a>
          </span>
          <span
            className={`SUB_HEADING_CSS text-blue-chill cursor-pointer`}
            onClick={() => {
              setDownloading(true);
              DownloadFile(providerFormDetail?.file)
                .then((res) => {
                  fileDownload(res.data, "CV/Resume");
                  setDownloading(false);
                })
                .catch((err) => {
                  console.error("[DownloadFile][API_ERROR] - ", err);
                  setDownloading(false);
                });
            }}
          >
            {downloading?<Spinner/>:"Download"}
          </span>
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Name 1</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.referenceName1}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Phone Number</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.referenceNumber1}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Name 2</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.referenceName2}
        </span>
      </div>{" "}
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Phone Number</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.referenceNumber2}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Name 3</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.referenceName3}
        </span>
      </div>{" "}
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Phone Number</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.referenceNumber3}
        </span>
      </div>
    </div>
  );
};
