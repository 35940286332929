/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { useProviderContext } from "Context/Provider";
import SearchBar from "Components/Common/SearchBar/SearchBar";
import { Button } from "Components/Common/Button/Button";
import { CREATE_PROVIDER_PATH } from "Constants/Views";
import MainLayout from "Layouts/MainLayout/MainLayout";
import ClassNames from "Helpers/Common";
import { ProvidersTable } from "Components/Provider/ProviderMainPage/Tables/ProvidersTable";
import { RequestedProvidersTable } from "Components/Provider/ProviderMainPage/Tables/RequestedProvidersTable";
import { useGlobalContext } from "Context/Global";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import "Colors.css";
import "./MainPage.css";
import { useProviderFormContext } from "Context/ProviderForm";
import { GetProviderById } from "Services/API/Providers.service";
import { formatStates } from "Helpers/Providers";
import { SearchProvider } from "Services/API/Search.service";
import { useEffect } from "react";

const PROVIDER = "Providers";
const REQ_PROVIDER = "New Providers";

export function ProvidersMainPage() {
  const [activeTab, setActiveTab] = useLocalStorage("providerTable", PROVIDER);
  const [providerSearchResult, setProviderSearchResult] = useState({});
  const [reqProviderSearchResult, setReqProviderSearchResult] = useState({});
  return (
    <MainLayout>
      <div className="p-8 flex flex-col gap-8">
        <Header
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
          setProviderSearchResult={setProviderSearchResult}
          setReqProviderSearchResult={setReqProviderSearchResult}
        />
        {activeTab === PROVIDER && (
          <ProvidersTable searchedProvider={providerSearchResult} />
        )}
        {activeTab === REQ_PROVIDER && (
          <RequestedProvidersTable
            reqProviderSearchResult={reqProviderSearchResult}
          />
        )}
      </div>
    </MainLayout>
  );
}

const Header = ({
  activeTab,
  onTabChange,
  setProviderSearchResult,
  setReqProviderSearchResult,
}) => {
  const { resetProviderId, setActiveView } = useProviderContext();
  const { resetProvderCtx } = useProviderFormContext();
  const { getAdminRights } = useGlobalContext();
  const { providerRights } = getAdminRights();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    if (searchQuery.length >= 2) {
      SearchProvider({ username: searchQuery })
        .then((res) => {
          setSearchResults([...res]);
        })
        .catch((err) => {
          console.error("[SearchProvider][API_ERROR] - ", err);
        });
    }
  }, [searchQuery]);
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-6">
        {" "}
        <span className="font-semibold text-2xl sapphire">Providers</span>
        <span
          className={ClassNames(
            "light_grayish_navy text-sm font-medium sapphire cursor-pointer text-center mt-1 py-2 px-2 rounded-md",
            activeTab === PROVIDER && "bg-gray-100 font-semibold text-black"
          )}
          onClick={() => {
            onTabChange(PROVIDER);
          }}
        >
          {PROVIDER}
        </span>
        <span
          onClick={() => {
            onTabChange(REQ_PROVIDER);
          }}
          className={ClassNames(
            "light_grayish_navy text-sm font-medium cursor-pointer mt-1 py-2 px-2 rounded-md",
            activeTab === REQ_PROVIDER && "bg-gray-100 font-semibold text-black"
          )}
        >
          {REQ_PROVIDER}
        </span>
      </div>
      <div className="flex items-center gap-6">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          onSearch={(searchedProviderid) => {
            if (searchedProviderid) {
              GetProviderById(searchedProviderid)
                .then((res) => {
                  if (activeTab === REQ_PROVIDER) {
                    setReqProviderSearchResult({
                      id: res?.id,
                      name: res?.name || "-",
                      username: res?.username || "-",
                      profileStatus:
                        `${
                          res?.status === "Disabled" ? "Inactive" : "Active"
                        }` || "",
                      providerType: res?.typeOfProvider,
                      actionStatus:
                        `${
                          res?.status === "Disabled"
                            ? "Deactivated"
                            : "Activated"
                        }` || "",
                      states: formatStates(res?.states) || [],
                      image: res?.image || "",
                    });
                  } else if (activeTab === PROVIDER) {
                    setProviderSearchResult({
                      id: res?.id,
                      name: res?.name || "-",
                      username: res?.username || "-",
                      profileStatus:
                        `${
                          res?.status === "Disabled" ? "Inactive" : "Active"
                        }` || "",
                      providerType: res?.typeOfProvider,
                      actionStatus:
                        `${
                          res?.status === "Disabled"
                            ? "Deactivated"
                            : "Activated"
                        }` || "",
                      states: formatStates(res?.states) || [],
                      image: res?.image || "",
                    });
                  }
                })
                .catch((err) => {
                  console.error("[GetProviderById][API_ERROR] -", err);
                });
            }
            //
          }}
        />

        <Button
          className={`w-full hover:bg-blue-light font-normal text-sm py-1 px-6 inline-flex rounded-md ${
            providerRights === "Edit" ? "text-white" : ""
          }`}
          btnText="Create New Provider"
          isDisabled={providerRights !== "Edit"}
          onClick={() => {
            resetProviderId();
            resetProvderCtx();
            setActiveView(CREATE_PROVIDER_PATH);
          }}
        />
      </div>
    </div>
  );
};
