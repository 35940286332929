import { FormattedDropdown } from "Components/Common/FormattedDropdown/FormattedDropDown";
import Table from "Components/Common/Table/Table";
import ClassNames from "Helpers/Common";
import { formattedNewProviders } from "Helpers/Providers";
import { useEffect, useState } from "react";
import {
  GetRequestedProviders,
  MoveToArchive,
} from "Services/API/Providers.service";
import { TableHeading } from "../TableHeading/TableHeading";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  ProviderActivate,
  ProviderDeactivate,
} from "Services/API/Providers.service";
import { ActivateModal } from "./ActivateModal";
import { DeactivateModal } from "./DeactivateModal";
import { useProviderContext } from "Context/Provider";
import { EDIT_PROVIDER_PATH } from "Constants/Views";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { DeleteModal } from "./DeleteModal";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

const HEADINGS = [
  "Name",
  "Profile Completion",
  "Actions",
  "",
  "",
];
const DROPDOWN_OPTIONS = [{ title: "Activate" }, { title: "Deactivate" }];

export const RequestedProvidersTable = ({ reqProviderSearchResult }) => {
  const { setActiveView, setProviderData } = useProviderContext();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveProviderId, setArchiveProviderId] = useState("");

  const fetchRequestedProviders = () => {
    setLoading(true);
    GetRequestedProviders(pageNumber)
      .then((res) => {
        setLoading(false);
        setProviders(formattedNewProviders(res?.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
      })
      .catch((err) => {
        setLoading(false);
        console.error(`[ProvidersPage][GetRequestedProviders][Error] - `, err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message)
        );
      });

    return () => {
      setProviders([]);
    };
  };
  useEffect(() => {
    fetchRequestedProviders();
  }, [pageNumber]);

  return (
    <>
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
      <Table
        heading={<TableHeading TableHeader={HEADINGS} />}
        body={
          !loading && !reqProviderSearchResult?.id && providers.length > 0 ? (
            providers.map((provider, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    onClick={() => {
                      setProviderData({
                        id: provider.id,
                        name: provider.name,
                      });
                      setActiveView(EDIT_PROVIDER_PATH);
                    }}
                    className="flex items-center cursor-pointer"
                  >
                    <div>
                      <span className="text-sm font-medium eclipse">
                        {provider.name}
                      </span>
                      <br />
                      <span className="bali_hai text-sm">
                        {provider.username}
                      </span>
                    </div>
                  </div>
                </td>
               
                <td className="px-4 py-4 whitespace-nowrap">
                  <span
                    className={ClassNames(
                      "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bali_hai bg-gray-100",
                      provider.profileStatus === "Incomplete"
                        ? "bg-gray-100 "
                        : "bg-green-200"
                    )}
                  >
                    {provider.profileStatus}
                  </span>
                </td>

                <td className="px-4 py-4 whitespace-nowrap text-sm">
                  <FormattedDropdown
                    status={provider.actionStatus}
                    dropDownOptions={DROPDOWN_OPTIONS}
                    onClick={(selectedOption) => {
                      setModalOpen(true);
                      setSelectedUserEmail(provider.username);
                      setSelectedOption(selectedOption);
                    }}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                  <span
                    onClick={() => {
                      setProviderData({
                        id: provider.id,
                        name: provider.name,
                      });
                      setActiveView(EDIT_PROVIDER_PATH);
                    }}
                    className="text-blue-chill hover:text-blue-light cursor-pointer"
                  >
                    Edit
                  </span>
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-left text-sm font-medium ">
                  <span
                    className="coral_red hover:text-red-800 cursor-pointer"
                    onClick={() => {
                      setArchiveProviderId(provider.id);
                      setArchiveModalOpen(true);
                    }}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))
          ) : reqProviderSearchResult?.id ? (
            <tr key={reqProviderSearchResult?.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  onClick={() => {
                    setProviderData({
                      id: reqProviderSearchResult?.id,
                      name: reqProviderSearchResult?.name,
                    });
                    setActiveView(EDIT_PROVIDER_PATH);
                  }}
                  className="flex items-center cursor-pointer"
                >
                  <div>
                    <span className="text-sm font-medium eclipse">
                      {reqProviderSearchResult?.name}
                    </span>
                    <br />
                    <span className="bali_hai text-sm">
                      {reqProviderSearchResult?.username}
                    </span>
                  </div>
                </div>
              </td>
             

              <td className="px-4 py-4 whitespace-nowrap">
                <span
                  className={ClassNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bali_hai bg-gray-100",
                    reqProviderSearchResult?.profileStatus === "Incomplete"
                      ? "bg-gray-100 "
                      : "bg-green-200"
                  )}
                >
                  {reqProviderSearchResult?.profileStatus}
                </span>
              </td>

              <td className="px-4 py-4 whitespace-nowrap text-sm">
                <FormattedDropdown
                  status={reqProviderSearchResult?.actionStatus}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={(selectedOption) => {
                    setModalOpen(true);
                    setSelectedUserEmail(reqProviderSearchResult?.username);
                    setSelectedOption(selectedOption);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                <span
                  onClick={() => {
                    setProviderData({
                      id: reqProviderSearchResult?.id,
                      name: reqProviderSearchResult?.name,
                    });
                    setActiveView(EDIT_PROVIDER_PATH);
                  }}
                  className="royal_blue hover:text-indigo-900 cursor-pointer"
                >
                  Edit
                </span>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-left text-sm font-medium ">
                <span
                  className="coral_red hover:text-red-800 cursor-pointer"
                  onClick={() => {
                    setArchiveProviderId(reqProviderSearchResult?.id);
                    setArchiveModalOpen(true);
                  }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ) : (
            !reqProviderSearchResult?.id && !loading && "No Data Found"
          )
        }
      />
      {loading && <Spinner />}
      {isModalOpen && selectedOption === "Deactivate" ? (
        <DeactivateModal
          isModalOpen={isModalOpen}
          isLoading={isBtnLoading}
          onDeactivate={() => {
            setIsBtnLoading(true);
            ProviderDeactivate({ email: selectedUserEmail })
              .then((res) => {
                setIsBtnLoading(false);
                setModalOpen(false);
                fetchRequestedProviders();
              })
              .catch((err) => {
                setIsBtnLoading(false);
                setSelectedOption("");
                setApiError(err?.response?.data?.errors);
                console.error(
                  "[Requested_ProviderDeactivate][API_ERROR] - ",
                  err
                );
              });
          }}
          onCancel={() => {
            setSelectedOption("");
            setModalOpen(false);
          }}
        />
      ) : selectedOption === "Activate" ? (
        <ActivateModal
          isModalOpen={isModalOpen}
          isLoading={isBtnLoading}
          onActivate={() => {
            setIsBtnLoading(true);
            ProviderActivate({ email: selectedUserEmail })
              .then((res) => {
                setIsBtnLoading(false);
                setModalOpen(false);
                fetchRequestedProviders();
              })
              .catch((err) => {
                setIsBtnLoading(false);
                setSelectedOption("");
                setApiError(err?.response?.data?.errors);
                console.error(
                  "[Requested_ProviderActivate][API_ERROR] - ",
                  err
                );
              });
          }}
          onCancel={() => {
            setSelectedOption("");
            setModalOpen(false);
          }}
        />
      ) : (
        ""
      )}
      {archiveModalOpen && (
        <DeleteModal
          isModalOpen={archiveModalOpen}
          isLoading={isBtnLoading}
          onDelete={() => {
            if (archiveProviderId) {
              setIsBtnLoading(true);
              MoveToArchive(archiveProviderId)
                .then((res) => {
                  fetchRequestedProviders();
                  setIsBtnLoading(false);
                  setArchiveModalOpen(false);
                })
                .catch((err) => {
                  console.error(
                    "[PROVIDERS_TABLE][MoveToArchive][API_ERROR - ",
                    err
                  );
                  setIsBtnLoading(false);
                  setArchiveModalOpen(false);
                  setApiError(err?.response?.data?.errors || err?.message);
                });
            }
          }}
          onCancel={() => {
            setIsBtnLoading(false);
            setArchiveModalOpen(false);
          }}
        />
      )}
      {!loading && providers.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
    </>
  );
};
