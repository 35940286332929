export const GENERAL_INFO = "GENERAL_INFO";
export const STATE_MED_LIC = "State Medical Licenses";
export const DRUG_ENF_ADMIN_REG =
  "Drug Enforcement Administration Registerations";
export const EDUCATION = "Education";
export const TRAINING = "Training";
export const FELLOWSHIP = "Fellowship";
export const SECURITY = "Security";
export const MED_MALPRACTICE_INS = "Medical Malpractice Insurance";
export const UPLOAD_FILE = "Upload of Files";
export const VISITS_RECORDS = "Visits"

export const TABS = [
  GENERAL_INFO,
  STATE_MED_LIC,
  DRUG_ENF_ADMIN_REG,
  EDUCATION,
  TRAINING,
  FELLOWSHIP,
  SECURITY,
  MED_MALPRACTICE_INS,
  UPLOAD_FILE,
];
export const EDIT_TABS = [
  VISITS_RECORDS,
  GENERAL_INFO,
  STATE_MED_LIC,
  DRUG_ENF_ADMIN_REG,
  EDUCATION,
  TRAINING,
  FELLOWSHIP,
  SECURITY,
  MED_MALPRACTICE_INS,
  UPLOAD_FILE,
];
export const tabIndexes = {
  GENERAL_INFO: 0,
  STATE_MED_LIC: 1,
  DRUG_ENF_ADMIN_REG: 2,
  EDUCATION: 3,
  TRAINING: 4,
  FELLOWSHIP: 5,
  SECURITY: 6,
  MED_MALPRACTICE_INS: 7,
  UPLOAD_FILE: 8,
  VISITS_RECORDS:9
};
export const editTabIndexes = {
  VISITS_RECORDS:0,
  GENERAL_INFO: 1,
  STATE_MED_LIC: 2,
  DRUG_ENF_ADMIN_REG: 3,
  EDUCATION: 4,
  TRAINING: 5,
  FELLOWSHIP:6,
  SECURITY: 7,
  MED_MALPRACTICE_INS: 8,
  UPLOAD_FILE: 9,
};
export const GENERAL_INFO_INIT_STATE = {
  DOB: "",
  email: "",
  gender: "",
  lastName: "",
  firstName: "",
  uploadedFile: "",
  dateOfBirth: "",
  nationalIdentifier: "",
  providerType: "",
  referenceName1: "",
  referenceNumber1: "",
  referenceName2: "",
  referenceNumber2: "",
  referenceName3: "",
  referenceNumber3: "",
  clinicianID: "",
};

export const initialStates = {
  EDUCATION_FORM: {
    boardCertified: true,
    certificationInstitute: "",
    certificateFile: "",
    medSchool: "",
    graduationDate: "",
    degreeFile: "",
  },
  TRAINING_FORM: {
    programName: "",
    streetAddress: "",
    city: "",
    zipCode: "",
    programDirector: "",
    uploadedFile: "",
    internProgramName: "",
    internStreetAddress: "",
    internCity: "",
    internZipCode: "",
    graduationDate: "",
    internGraduationDate: "",
    internProgramDirector: "",
    internCertificate: "",
  },
  SECURITY_FORM: {
    backgroundCheckStartDate: "",
    backgroundCheckPassed: false,
    backgroundAuthorizationDocument: "",
  },
};

export const viewModes = {
  EDIT_MODE: "editMode",
  ADD_MODE: "addMode",
  VIEW_MODE: "viewMode",
};
