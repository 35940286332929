import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { editTabIndexes, tabIndexes, viewModes } from "Constants/Provider";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  GetFellowshipDetails,
  UpdateFellowshipDetails,
} from "Services/API/ProviderEditView.service";
import { ProviderFellowShip } from "Services/API/CreateProvider.service";
import { AddForm } from "./AddForm";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import "Components/Provider/FormsCommonCss/Form.css";

const formatDetails = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      speciality: list?.speciality || "-",
      programName: list?.fellowshipProgramName || "-",
      streetAddress: list?.fellowshipProgramStreetAddress || "-",
      city: list?.fellowshipProgramCity || "-",
      zipCode: list?.fellowshipProgramZipcode || "-",
      directorName: list?.fellowshipDirectorName || "-",
      certificate: list?.fellowshipCertificateDocument || "-",
    };
  });
  return formattedResult;
};

const ApiReqformatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      speciality: list?.speciality || "-",
      fellowshipDirectorName: list?.directorName || "-",
      fellowshipProgramName: list?.programName || "-",
      fellowshipProgramStreetAddress: list?.streetAddress || "-",
      fellowshipProgramCity: list?.city || "-",
      fellowshipProgramZipcode: list?.zipCode || "-",
      fellowshipCertificateDocument: list?.certificate || "-",
    };
  });
  return formattedResult;
};

export const FellowshipForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState([]);
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetFellowshipDetails(providerId)
        .then((res) => {
          setLoading(false);
          if (res.length > 0) {
            setProviderDetail(formatDetails(res));
            addProvider
              ? setViewMode(viewModes.EDIT_MODE)
              : setViewMode(viewModes.VIEW_MODE);
          } else {
            setViewMode(viewModes.ADD_MODE);
          }
        })
        .catch((err) => {
          console.error("[GetFellowshipDetails][API_ERROR] - ", err);
          setLoading(false);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const addFellowshipDetails = async () => {
    try {
      const res = await ProviderFellowShip(providerId, providerDetail);
      setIsLoading(false);
      setActiveTabIndex(
        !addProviderFlow ? editTabIndexes?.SECURITY : tabIndexes.SECURITY
      );
    } catch (err) {
      console.error(
        "[ProviderFellowShip][API_ERROR] - ",
        err?.response?.data.errors
      );
      setIsLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };
  const UpdateFellowshipDetail = async (isNewFormAdded = false) => {
    try {
      const res = await UpdateFellowshipDetails(
        ApiReqformatter(
          isNewFormAdded
            ? providerDetail.filter(({ id }, index) => isNaN(id, index + 1))
            : providerDetail
        )
      );
      setIsLoading(false);
      setActiveTabIndex(
        !addProviderFlow ? editTabIndexes?.SECURITY : tabIndexes.SECURITY
      );
    } catch (err) {
      console.error("[UpdateTrainingInformation][API_ERROR] - ", err);
      setIsLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };
  const addUpdateFellowshipDetails = async () => {
    let newFellowhipsForm = providerDetail.filter(
      ({ id }, index) => !isNaN(id, index + 1)
    );

    if (newFellowhipsForm.length > 0) {
      try {
        const res = await ProviderFellowShip(providerId, newFellowhipsForm);
        if (res) {
          let isNewFormAdded = true;
          UpdateFellowshipDetail(isNewFormAdded);
        }
      } catch (err) {
        console.error(
          "[ProviderFellowShip][API_ERROR] - ",
          err?.response?.data.errors
        );
        setIsLoading(false);
        setApiError(err?.response?.data?.errors || err?.message);
      }
    } else if (!newFellowhipsForm.length > 0) {
      UpdateFellowshipDetail();
    }
  };
  const updateAddFellowshipDetails = () => {
    if (viewMode !== viewModes.VIEW_MODE) {
      setIsLoading(true);
      if (viewMode === viewModes.EDIT_MODE) {
        addUpdateFellowshipDetails();
      } else if (viewMode === viewModes.ADD_MODE) {
        addFellowshipDetails();
      }
    } else {
      setViewMode(viewModes.EDIT_MODE);
    }
  };

  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider > 0 && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                if (providerDetail?.[0]?.fellowshipProgramCity) {
                  updateAddFellowshipDetails();
                } else {
                  setActiveTabIndex(
                    !addProviderFlow
                      ? editTabIndexes?.SECURITY
                      : tabIndexes.SECURITY
                  );
                }
              }}
            />
          </div>
        )}
        <br />
        {viewMode === viewModes.ADD_MODE ? (
          <AddForm
            fellowshipInfoForm={providerDetail}
            setFellowShipInfoForm={setProviderDetail}
          />
        ) : viewMode === viewModes.EDIT_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow ? editTabIndexes?.TRAINING : tabIndexes.TRAINING
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={false}
            onClick={() => {
              if (providerDetail?.[0]?.fellowshipProgramCity) {
                updateAddFellowshipDetails();
              } else {
                setActiveTabIndex(
                  !addProviderFlow
                    ? editTabIndexes?.SECURITY
                    : tabIndexes.SECURITY
                );
              }
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
