import { BASE_PROVIDER_PATH } from "Constants/Views";
import { useProviderContext } from "Context/Provider";
import { TabsPanel } from "Components/Provider/TabsPanel/TabsPanel";
import DetailsLayout from "Layouts/DetailsLayout/DetailLayout";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import "Pages/Provider/DetailPageCss/DetailPage.css";
import { EditContent } from "Components/Provider/EditViewProvider/Content/EditConten";

export const TheEditProviderPage = () => {
  const { setActiveView, getProviderData, resetProviderData } =
    useProviderContext();
  const [activeTabIndex, setActiveTabIndex] = useLocalStorage(
    "providerEditFormStep",
    0
  );
  const { name } = getProviderData();
  return (
    <DetailsLayout
      leftColumn={
        <TabsPanel
          onBack={() => {
            resetProviderData();
            setActiveTabIndex( "providerEditFormStep", 0)
            setActiveView(BASE_PROVIDER_PATH);
          }}
          name={name}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
      }
    >
      <div className="DetailPage_Wrapper flex flex-col gap-4 overflow-x-hidden">
        <EditContent
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
      </div>
    </DetailsLayout>
  );
};
