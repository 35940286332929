import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { formatDate } from "Helpers/DateFormat";
import { editTabIndexes, tabIndexes, viewModes } from "Constants/Provider";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  GetEducationDetails,
  UpdateEducationDetails,
} from "Services/API/ProviderEditView.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { AddEducationDetail } from "Services/API/CreateProvider.service";
import "Components/Provider/FormsCommonCss/Form.css";

const formatEducationDetails = (data) => {
  return {
    id: data?.id || "-",
    certified: data?.boardCertified || false,
    certificationDocument: data?.boardCertificateDocument || "",
    certificationWith: data?.boardCertifiedWith || "-",
    medSchoolName: data?.medicalSchoolAttended || "-",
    graduationDate:
      formatDate(data?.medicalSchoolGraducationDate, "MM/DD/YYYY") || "",
    degreeDocument: data?.medicalDegreeDocument || "",
  };
};

export const EducationForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState({});
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetEducationDetails(providerId)
        .then((res) => {
          setLoading(false);
          if (Object.keys(res).length !== 0) {
            setProviderDetail(formatEducationDetails(res));
            addProvider
              ? setViewMode(viewModes.EDIT_MODE)
              : setViewMode(viewModes.VIEW_MODE);
          } else {
            setViewMode(viewModes.ADD_MODE);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetEducationDetails][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const addUpdateEducationDetails = async () => {
    if (viewMode !== viewModes.VIEW_MODE) {
      setIsLoading(true);
      if (viewMode === viewModes.ADD_MODE) {
        try {
          const res = await AddEducationDetail(providerId, {
            boardCertified: providerDetail?.certified,
            boardCertifiedWith: providerDetail?.certificationWith,
            boardCertificateDocument: providerDetail?.certificationDocument,
            medicalSchoolAttended: providerDetail?.medSchoolName,
            medicalSchoolGraducationDate: providerDetail?.graduationDate,
            medicalDegreeDocument: providerDetail?.degreeDocument,
          });
          if (res) {
            setIsLoading(false);
            setActiveTabIndex(
              !addProviderFlow ? editTabIndexes?.TRAINING : tabIndexes.TRAINING
            );
          }
        } catch (err) {
          console.error(
            "[AddEducationDetail][API_ERROR] - ",
            err?.response?.data?.errors
          );
          setIsLoading(false);
          setApiError(err?.response?.data?.errors || err?.message);
        }
      } else if (viewMode === viewModes.EDIT_MODE) {
        try {
          const res = await UpdateEducationDetails(providerDetail?.id, {
            boardCertified: providerDetail?.certified,
            boardCertifiedWith: providerDetail?.certificationWith,
            boardCertificateDocument: providerDetail?.certificationDocument,
            medicalSchoolAttended: providerDetail?.medSchoolName,
            medicalSchoolGraducationDate: providerDetail?.graduationDate,
            medicalDegreeDocument: providerDetail?.degreeDocument,
          });
          if (res) {
            setIsLoading(false);
            setActiveTabIndex(
              !addProviderFlow ? editTabIndexes?.TRAINING : tabIndexes.TRAINING
            );
          }
        } catch (err) {
          console.error("[UpdateEducationDetails][API_ERROR] - ", err);
          setIsLoading(false);
          setApiError(err?.response?.data?.errors || err?.message);
        }
      }
    } else {
      setViewMode(viewModes.EDIT_MODE);
    }
  };
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                addUpdateEducationDetails();
              }}
            />
          </div>
        )}
        <br />
        {viewMode !== viewModes.VIEW_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow
                  ? editTabIndexes?.DRUG_ENF_ADMIN_REG
                  : tabIndexes.DRUG_ENF_ADMIN_REG
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={false}
            onClick={() => {
              addUpdateEducationDetails();
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
