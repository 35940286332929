import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
    providerFormDetail.length > 0 &&
    providerFormDetail.map((form, index) => (
      <div key={index} className="grid grid-cols-2 gap-6 pb-4">
        <span className="col-span-2 font-semibold text-base light_grayish_navy">
          License {index + 1}
        </span>
        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>State</span>
          <span className={SUB_HEADING_CSS}>{form?.stateName}</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>License Number</span>
          <span className={SUB_HEADING_CSS}>{form?.licenseNumber}</span>
        </div>

        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>Date First Licensed</span>
          <span className={SUB_HEADING_CSS}>{form?.licensedDate}</span>
        </div>

        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>Date of Expiration</span>
          <span className={SUB_HEADING_CSS}>{form?.licenseExpiration}</span>
        </div>

        <div className="flex flex-col col-span-2 gap-2">
          <UploadFile
            value={form?.licenseDocument}
            label="Medical License"
            mode="View"
          />
        </div>
      </div>
    ))
  );
};
