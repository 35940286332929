import { BarChart, Bar, XAxis } from "recharts";

export const ProviderBarchart = ({ data }) => {
  return (
    // TODO: Check  if we can pass down the width & height in the props
    <BarChart
      width={600}
      height={200}
      data={data}
      margin={{
        top: 5,
        right: 10,
        left: 10,
        bottom: 10,
      }}
      barSize={60}
    >
      <XAxis dataKey="name" scale="point" padding={{ left: 80, right: 80 }} />

      <Bar dataKey="count" fill="#E0EAFD" label={{ position: "middle" }} />
    </BarChart>
  );
};
