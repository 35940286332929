import { useState, useContext, useEffect, createContext } from "react";
import { LOGIN_VIEW } from "Constants/Views";
import { useHistory } from "react-router";

const LoginContext = createContext();
const CREDENTIALS_INITIAL_STATE = {
  email: "",
  password: "",
  verification: "",
};

const LoginProvider = ({ children }) => {
  let history = useHistory();
  const [activeView, setView] = useState(LOGIN_VIEW);
  const [userCredential, setUserCredential] = useState(
    CREDENTIALS_INITIAL_STATE
  );
  const setActiveView = (view, path) => {
    localStorage.setItem("ACTIVE_VIEW", JSON.stringify(view));
    setView(view);
    history.push(path);
  };
  useEffect(() => {
    let currentActiveContainer = JSON.parse(
      localStorage.getItem("ACTIVE_VIEW")
    );
    if (currentActiveContainer && currentActiveContainer !== activeView) {
      setView(currentActiveContainer);
    }
  }, [activeView]);

  const setCredentials = (credentials) => {
    localStorage.setItem("USER_CREDENTIALS", JSON.stringify(credentials));
    localStorage.setItem("USER_EMAIL", JSON.stringify(credentials.email));
    setUserCredential(credentials);
  };
  const getCredentials = () => {
    const credentials = JSON.parse(localStorage.getItem("USER_CREDENTIALS"));
    if (userCredential) {
      return userCredential;
    }
    if (credentials) {
      return credentials;
    }
    return;
  };

  const resetCredentials = () => {
    setUserCredential(CREDENTIALS_INITIAL_STATE);

    localStorage.setItem(
      "USER_CREDENTIALS",
      JSON.stringify(CREDENTIALS_INITIAL_STATE)
    );
  };
  return (
    <LoginContext.Provider
      value={{
        setCredentials,
        getCredentials,
        resetCredentials,
        activeView,
        setActiveView,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};

export { LoginContext, LoginProvider };
