import {
  BASE_PROVIDER_PATH,
  CREATE_PROVIDER_PATH,
  EDIT_PROVIDER_PATH,
} from "Constants/Views";
import { TheAddProviderPage } from "Pages/Provider/AddProvider/AddProvider";
import { TheEditProviderPage } from "Pages/Provider/EditViewProvider/EditViewProvider";
import { ProvidersMainPage } from "Pages/Provider/MainPage/MainPage";
import { Route, Switch } from "react-router";
import { useGlobalContext } from "Context/Global";

export default function Providers() {
  const { getAdminRights } = useGlobalContext();
  const { providerRights } = getAdminRights();
  return (
    <Switch>
      <Route
        exact
        path={CREATE_PROVIDER_PATH}
        render={() => <TheAddProviderPage />}
      />
      <Route
        exact
        path={BASE_PROVIDER_PATH}
        render={() => <ProvidersMainPage />}
      />
      <Route
        exact
        path={EDIT_PROVIDER_PATH}
        render={() =>
          providerRights === "Edit" ? (
            <TheEditProviderPage />
          ) : (
            <ProvidersMainPage />
          )
        }
      />
    </Switch>
  );
}
