import { Button } from "Components/Common/Button/Button";
import { InputField } from "Components/Common/InputField/InputField";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TableHeading } from "Components/Provider/ProviderMainPage/TableHeading/TableHeading";
import Table from "Components/Common/Table/Table";
import { AddROS, ListChildROS } from "Services/API/IntakeForms.service";
import { RosTableBody } from "Pages/IntakeForms/IntakeForms";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

const TABLE_HEADINGS = [
  "Symptoms",
  "Sub-Symptom",
  "Status",
  "Actions",
  " ",
  " ",
];
export const IntakeForm2ndLevel = () => {
  const { id, limit } = useParams();
  const [rosList, setRosList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const RosChildsListing = () => {
    setIsLoading(true);
    ListChildROS(id)
      .then((res) => {
        console.log(res);
        setRosList([...res?.data]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[ListChildROS][API_ERROR] ===>", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      RosChildsListing();
    }
  }, [id]);
  return (
    <div className="flex flex-col gap-4 p-4">
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
      <Header
        parentId={id}
        limit={limit}
        onSuccess={() => RosChildsListing?.()}
        setApiError={setApiError}
      />
      <Table
        heading={
          <TableHeading
            TableHeader={
              limit == 2
                ? TABLE_HEADINGS.filter((item) => item !== "Sub-Symptom")
                : TABLE_HEADINGS
            }
          />
        }
        body={
          <RosTableBody
            data={rosList}
            onSuccess={() => RosChildsListing?.()}
            loading={isLoading}
          />
        }
      />
      {isLoading && <Spinner />}
    </div>
  );
};

const Header = ({ parentId, onSuccess, setApiError,limit }) => {
  const [symptomName, setSymtomName] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  return (
    <div className="flex justify-end gap-3 py-3 pr-4">
      <InputField
        id={parentId}
        placeholder={limit==2?"Sub-Symptom Name":"Symptom Name"}
        value={symptomName}
        onChange={(value) => {
          setSymtomName(value);
        }}
      />
      <Button
        className="w-28 bg_royal_blue hover:bg-blue-light text-white font-normal text-sm py-1 px-6 inline-flex rounded-md"
        btnText="Add"
        isDisabled={false}
        isLoading={isBtnLoading}
        onClick={async () => {
          if (parentId && symptomName) {
            setIsBtnLoading(true);
            try {
              const res = await AddROS({
                name: symptomName.trim(),
                parentId,
              });
              setIsBtnLoading(false);
              if (res) {
                onSuccess?.();
                setSymtomName(" ");
              }
            } catch (err) {
              console.error("[AddROS][API_ERROR] ===>", err);
              setApiError?.(
                errorFormatter(err?.response?.data?.errors || err?.message)
              );
              setIsBtnLoading(false);
            }
          }
        }}
      />
    </div>
  );
};
