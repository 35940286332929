import { useState } from "react";
import { tabIndexes } from "Constants/Patient";
import { usePatientContext } from "Context/Patient";
import { Button } from "Components/Common/Button/Button";
import GeneralInfoEdit from "./GeneralInfoEdit/GeneralInfoEdit";
import { GeneralInfoView } from "./GeneralInfoView/GeneralInfoView";
import { PatientUpdate } from "Services/API/Patients.service";
import { ApiError } from "Components/Common/ApiError";
import "Components/Patients/EditViewPatient/Forms/FormCommonCss/Form.css";
import "Colors.css";

export function GeneralInfoForm({ setActiveTabIndex }) {
  const { getGeneralInfo, getPatientData } = usePatientContext();
  const [patientDetails, setPatientDetails] = useState(getGeneralInfo());
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = getPatientData();
  return (
    <>
      <div className="FormWrapper">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
        <div className="flex justify-end">
          <Button
            className="h-8 w-24 white"
            btnText={isEditMode ? "Save" : "Edit Profile"}
            isLoading={isLoading}
            isDisabled={false}
            onClick={() => {
              if (isEditMode) {
                setIsLoading(true);

                PatientUpdate(id, {
                  prefix: patientDetails.prefix,
                  firstName: patientDetails.firstName,
                  middleName: patientDetails.middleName,
                  lastName: patientDetails.lastName,
                  suffix: patientDetails.suffix,
                  dateOfBirth: patientDetails.DOB,
                  gender: patientDetails.gender,
                  address1: patientDetails.address1,
                  address2: patientDetails.address2,
                  state: patientDetails.stateId,
                  country: patientDetails.country,
                  city: patientDetails.city,
                  zipcode: patientDetails.zipCode,
                  primaryPhone: patientDetails.phoneNumber,
                  primaryPhoneType: patientDetails.phoneType,
                })
                  .then((res) => {
                    if (res) {
                      setActiveTabIndex(tabIndexes.MEDICATIONS);
                      setIsLoading(false);
                    }
                  })
                  .catch((err) => {
                    console.error(
                      "[UpdateGeneralInformation][API_ERROR] - ",
                      err
                    );
                    setIsLoading(false);
                    setApiError(err?.response?.data?.errors || err?.message);
                  });
              } else {
                setIsEditMode(true);
              }
            }}
          />
        </div>
        <br />
        {isEditMode ? (
          <GeneralInfoEdit
            patientDetails={patientDetails}
            onSave={setPatientDetails}
          />
        ) : (
          <GeneralInfoView patientDetails={patientDetails} />
        )}
      </div>
      <div className="flex justify-end">
        <Button
          className="h-8 w-16 text-white"
          btnText="Next"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.MEDICATIONS)}
        />
      </div>
    </>
  );
}
