import { useState, useEffect } from "react";
import { tabIndexes } from "Constants/Patient";
import { usePatientContext } from "Context/Patient";
import { Button } from "Components/Common/Button/Button";
import {
  GetPatientDocuments,
  PostPatientFiles,
} from "Services/API/Patients.service";
import UploadOtherFiles from "Components/Common/UploadOtherFiles/UploadOtherFiles";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Components/Patients/EditViewPatient/Forms/FormCommonCss/Form.css";
import { Divider } from "Components/Common/Divider/Divider";

export const UploadFiles = ({ setActiveTabIndex }) => {
  const { getPatientData } = usePatientContext();
  const { id } = getPatientData();
  const [files, setFiles] = useState([
    { key: 1, id: "", url: "", fileName: "" },
  ]);
  const [previousDocs, setPreviousDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);

  const GetUserDocs = () => {
    GetPatientDocuments(id)
      .then((res) => {
        setPreviousDocs(res);
      })
      .catch((err) => {
        console.log("[GetPatientDocuments][API_ERROR] - ", err);
      });
  };
  useEffect(() => {
    if (id) {
      GetUserDocs();
    }
  }, []);
  return (
    <>
      <div className="flex flex-col gap-3 FormWrapper">
        {previousDocs?.map((doc) => (
          <div className="flex flex-col col-span-2 gap-2">
            <UploadFile mode="View" value={doc?.url} label={doc.name} />
          </div>
        ))}

        {files.map((file, index) => {
          return (
            <div className="col-span-2 pb-4" key={count + index}>
              <UploadOtherFiles
                key={index}
                id={file.key}
                files={files}
                fileUrl={file.url}
                setFiles={setFiles}
                isLoading={isLoading}
                fileName={file.fileName}
                setIsLoading={setIsLoading}
                title="File upload"
                placeHolder="Title"
                onResponseFileAdd={({ data }, value) => {
                  if (data) {
                    PostPatientFiles(id, {
                      otherDocuments: [
                        ...previousDocs.map((doc) => doc.id),
                        data,
                      ],
                    })
                      .then((res) => {
                        if (res) {
                          let submittedFile = files.filter(
                            (file) => file.key !== value
                          );
                          setFiles([...submittedFile]);
                          setIsLoading(false);
                          GetUserDocs();
                        }
                      })
                      .catch((err) => {
                        console.log("[PostPatientFiles][API_ERROR] -  ", err);
                        setIsLoading(false);
                      });
                  }
                }}
                handleCancelFile={(value) => {
                  let cancelFile = files.filter((file) => file.key !== value);
                  setFiles([...cancelFile]);
                }}
              />
            </div>
          );
        })}
        <Divider
          onClick={() => {
            setCount(count + 1);
            setFiles([
              ...files,
              { key: count + 1, id: "", url: "", fileName: "" },
            ]);
            // setIsDisabled(true);
          }}
          isDisabled={false}
        />
      </div>
      <div className="flex justify-between">
        <Button
          btnText="Back"
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.VISITS)}
        />
        <Button
          btnText="Save"
          className="h-8 w-16 text-white"
          onClick={() => {}}
          isDisabled={false}
        />
      </div>
    </>
  );
};
