import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { BASE_PROVIDER_PATH } from "Constants/Views";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import {
  GetUserFiles,
  UpdateUserFiles,
} from "Services/API/ProviderEditView.service";
import { viewModes } from "Constants/Provider";
import { AddUserFiles } from "Services/API/CreateProvider.service";
import "Components/Provider/FormsCommonCss/Form.css";

const formatDetails = (data) => {
  return [
    { label: "Provider Agreement", value: data?.providerAgreement || null },
    {
      label:
        "Educational Commission for Foreign Medical Graduates (ECFMG) Certificate",
      value: data?.ECFMGcertificate || null,
    },
    {
      label: "State Issued Driver License or State Issued Identification Card",
      value: data?.idCard || null,
    },
    { label: "Passport", value: data?.passport || null },
    { label: "Social Security Card", value: data?.socialSecurityCard || null },
    { label: "IRS W9 Form", value: data?.IRS_W9form || null },
    {
      label: "Provider Completed Application",
      value: data?.providerCompletedApplication || null,
    },
    {
      label: "HIPAA Confidentiality Agreement",
      value: data?.HIPAAconfidentialityAgreement || null,
    },
    {
      label: "InstaCured Technology Security and Privacy Agreement",
      value: data?.instacuredTechnologySecurityAndPrivacyAgreement || null,
    },
    {
      label: "InstaCured Provider Application",
      value: data?.instacuredProviderApplication || null,
    },
    {
      label: "InstaCured Confidentiality Agreement",
      value: data?.instacuredConfidentialityAgreement || null,
    },
    {
      label: "InstaCured ADA Policy",
      value: data?.instacuredADApolicy || null,
    },
    {
      label: "InstaCured Sexual Harassment Policy",
      value: data?.instacuredSexualHarassmentPolicy || null,
    },
    {
      label: "InstaCured Prescribing Policy",
      value: data?.instacuredPrescribingPolicy || null,
    },
    {
      label: "InstaCured Antibiotic Stewardship Policy",
      value: data?.instacuredAntibioticStewardshipPolicy || null,
    },
    {
      label: "InstaCured Non-disclosure Agreement",
      value: data?.instacuredNonDisclosureAgreement || null,
    },
  ];
};

export const UploadFilesForm = () => {
  const { setActiveView, getProviderData, getProviderId } =
    useProviderContext();
  const [filesId, setFilesId] = useState("");
  const [providerDetail, setProviderDetail] = useState([]);
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = getProviderData();

  useEffect(() => {
    if (id) {
      setLoading(true);
      GetUserFiles(id)
        .then((res) => {
          setLoading(false);
          if (Object.keys(res).length !== 0) {
            setProviderDetail(formatDetails(res));
            setFilesId(res?.id);
          } else {
            setProviderDetail(formatDetails(res));
            setViewMode(viewModes.ADD_Mode);
          }
        })
        .catch((err) => {
          console.error("[GetUserFiles][API_ERROR] - ", err);
          setLoading(false);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [id]);

  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                if (viewMode !== viewModes.VIEW_MODE) {
                  setIsLoading(true);
                  if (viewMode === viewModes.ADD_Mode) {
                    AddUserFiles(id || getProviderId(), {
                      providerAgreement: providerDetail?.[0]?.value,
                      ECFMGcertificate: providerDetail?.[1]?.value,
                      idCard: providerDetail?.[2]?.value,
                      passport: providerDetail?.[3]?.value,
                      socialSecurityCard: providerDetail?.[4]?.value,
                      IRS_W9form: providerDetail?.[5]?.value,
                      providerCompletedApplication: providerDetail?.[6]?.value,
                      HIPAAconfidentialityAgreement: providerDetail?.[7]?.value,
                      instacuredTechnologySecurityAndPrivacyAgreement:
                        providerDetail?.[8]?.value,
                      instacuredProviderApplication: providerDetail?.[9]?.value,
                      instacuredConfidentialityAgreement:
                        providerDetail?.[10]?.value,
                      instacuredADApolicy: providerDetail?.[11]?.value,
                      instacuredSexualHarassmentPolicy:
                        providerDetail?.[12]?.value,
                      instacuredPrescribingPolicy: providerDetail?.[13]?.value,
                      instacuredAntibioticStewardshipPolicy:
                        providerDetail?.[14]?.value,
                      instacuredNonDisclosureAgreement:
                        providerDetail?.[15]?.value,
                    })
                      .then((res) => {
                        setIsLoading(false);
                        setActiveView(BASE_PROVIDER_PATH);
                      })
                      .catch((err) => {
                        console.error("[AddUserFiles][API_ERROR] -", err);
                        setIsLoading(false);
                        setApiError(
                          err?.response?.data?.errors || err?.message
                        );
                      });
                  } else if (viewMode === viewModes.EDIT_MODE) {
                    UpdateUserFiles(filesId, {
                      providerAgreement: providerDetail?.[0]?.value,
                      ECFMGcertificate: providerDetail?.[1]?.value,
                      idCard: providerDetail?.[2]?.value,
                      passport: providerDetail?.[3]?.value,
                      socialSecurityCard: providerDetail?.[4]?.value,
                      IRS_W9form: providerDetail?.[5]?.value,
                      providerCompletedApplication: providerDetail?.[6]?.value,
                      HIPAAconfidentialityAgreement: providerDetail?.[7]?.value,
                      instacuredTechnologySecurityAndPrivacyAgreement:
                        providerDetail?.[8]?.value,
                      instacuredProviderApplication: providerDetail?.[9]?.value,
                      instacuredConfidentialityAgreement:
                        providerDetail?.[10]?.value,
                      instacuredADApolicy: providerDetail?.[11]?.value,
                      instacuredSexualHarassmentPolicy:
                        providerDetail?.[12]?.value,
                      instacuredPrescribingPolicy: providerDetail?.[13]?.value,
                      instacuredAntibioticStewardshipPolicy:
                        providerDetail?.[14]?.value,
                      instacuredNonDisclosureAgreement:
                        providerDetail?.[15]?.value,
                    })
                      .then((res) => {
                        if (res?.id) {
                          setIsLoading(false);
                          setActiveView(BASE_PROVIDER_PATH);
                        }
                      })
                      .catch((err) => {
                        console.error("[UpdateUserFiles][API_ERROR] - ", err);
                        setIsLoading(false);
                        setApiError(
                          err?.response?.data?.errors || err?.message
                        );
                      });
                  }
                } else {
                  setViewMode(viewModes.EDIT_MODE);
                }
              }}
            />
          </div>
        )}
        <br />
        {viewMode !== viewModes.VIEW_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
    </>
  );
};
