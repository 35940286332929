import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { editTabIndexes, tabIndexes, viewModes } from "Constants/Provider";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { AddTrainingDetail } from "Services/API/CreateProvider.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import {
  GetTrainingInformation,
  UpdateTrainingInformation,
} from "Services/API/ProviderEditView.service";
import "Components/Provider/FormsCommonCss/Form.css";

const formatDetails = (data) => {
  return {
    id: data.id,
    programName: data.residencyProgramName || "-",
    streetAddress: data.residencyStreetAddress || "-",
    city: data.residencyCity || "-",
    zipCode: data.residencyZipcode || "-",
    graduationDate: data.residencyGraduationDate || "",
    directorName: data.residencyProgramDirector || "-",
    file: data.residencyGraduationCertificateDocument || "",
    internProgramName: data.internshipProgramName || "-",
    internStreetAddress: data.internshipStreetAddress || "-",
    internCity: data.internshipCity || "-",
    internZipCode: data.internshipZipcode || "-",
    internCompletionDate: data.internshipCompletionDate || "",
    internProgramDirector: data.internshipProgramDirector || "-",
    internDocument: data.internshipCertificateDocument || "",
  };
};

export const TrainingForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState({});
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetTrainingInformation(providerId)
        .then((res) => {
          setLoading(false);

          if (Object.keys(res).length !== 0) {
            setProviderDetail(formatDetails(res));
            addProvider
              ? setViewMode(viewModes.EDIT_MODE)
              : setViewMode(viewModes.VIEW_MODE);
          } else {
            setViewMode(viewModes.ADD_MODE);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetTrainingInformation][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const addUpdateTraningDetails = async () => {
    if (viewMode !== viewModes.VIEW_MODE) {
      setIsLoading(true);
      if (viewMode === viewModes.ADD_MODE) {
        try {
          const res = await AddTrainingDetail(providerId, {
            residencyProgramName: providerDetail?.programName,
            residencyStreetAddress: providerDetail?.streetAddress,
            residencyCity: providerDetail?.city,
            residencyZipcode: providerDetail?.zipCode,
            residencyGraduationDate: providerDetail?.graduationDate,
            residencyProgramDirector: providerDetail?.directorName,
            residencyGraduationCertificateDocument: providerDetail?.file,
            internshipProgramName: providerDetail?.internProgramName,
            internshipStreetAddress: providerDetail?.internStreetAddress,
            internshipCity: providerDetail?.internCity,
            internshipZipcode: providerDetail?.internZipCode,
            internshipCompletionDate: providerDetail?.internCompletionDate,
            internshipProgramDirector: providerDetail?.internProgramDirector,
            internshipCertificateDocument: providerDetail?.internDocument,
          });
          if (res) {
            setIsLoading(false);
            setActiveTabIndex(
              !addProviderFlow
                ? editTabIndexes?.FELLOWSHIP
                : tabIndexes.FELLOWSHIP
            );
          }
        } catch (err) {
          console.error(
            "[AddTrainingDetail][API_ERROR] - ",
            err?.response?.data?.errors
          );
          setIsLoading(false);
          setApiError(err?.response?.data?.errors || err?.message);
        }
      } else if (viewMode === viewModes.EDIT_MODE) {
        try {
          const res = await UpdateTrainingInformation(providerDetail?.id, {
            residencyProgramName: providerDetail?.programName,
            residencyStreetAddress: providerDetail?.streetAddress,
            residencyCity: providerDetail?.city,
            residencyZipcode: providerDetail?.zipCode,
            residencyGraduationDate: providerDetail?.graduationDate,
            residencyProgramDirector: providerDetail?.directorName,
            residencyGraduationCertificateDocument: providerDetail?.file,
            internshipProgramName: providerDetail?.internProgramName,
            internshipStreetAddress: providerDetail?.internStreetAddress,
            internshipCity: providerDetail?.internCity,
            internshipZipcode: providerDetail?.internZipCode,
            internshipCompletionDate: providerDetail?.internCompletionDate,
            internshipProgramDirector: providerDetail?.internProgramDirector,
            internshipCertificateDocument: providerDetail?.internDocument,
          });
          setIsLoading(false);
          setActiveTabIndex(
            !addProviderFlow ? editTabIndexes?.FELLOWSHIP : tabIndexes.FELLOWSHIP
          );
        } catch (err) {
          console.error("[UpdateTrainingInformation][API_ERROR] - ", err);
          setIsLoading(false);
          setApiError(err?.response?.data?.errors || err?.message);
        }
      }
    } else {
      setViewMode(viewModes.EDIT_MODE);
    }
  };
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                addUpdateTraningDetails();
              }}
            />
          </div>
        )}
        <br />
        {viewMode !== viewModes.VIEW_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow
                  ? editTabIndexes?.EDUCATION
                  : tabIndexes.EDUCATION
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={false}
            onClick={() => {
              addUpdateTraningDetails();
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
