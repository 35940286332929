import ClassNames from "Helpers/Common";
import { TABS } from "Constants/Patient";
import { BASE_PATIENT_PATH } from "Constants/Views";
import { usePatientContext } from "Context/Patient";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import "Colors.css";

export const TabsPanel = ({ activeTabIndex, setActiveTabIndex }) => {
  const { getPatientData, setActiveView } = usePatientContext();
  const { name } = getPatientData();
  let patient = "PatientDetail";

  return (
    <div className="flex flex-col gap-4 p-4">
      <div
        onClick={() =>
          setActiveView(BASE_PATIENT_PATH.replace(":patient", patient))
        }
        className="flex items-center"
      >
        <BackIcon className="h-4 w-4 sapphire cursor-pointer" />
        <span className="ml-2 sapphire text-base font-semibold cursor-pointer">
          {name}
        </span>
      </div>
      <>
        {TABS.map((tab, index) => (
          <div
            onClick={() => setActiveTabIndex(index)}
            key={index}
            className={ClassNames(
              "flex items-center p-4 gap-4 cursor-pointer",
              tab === TABS[activeTabIndex] && "border-blue-chill border-l-2"
            )}
          >
            <span
              className={ClassNames(
                "rounded-full h-10 w-10 flex items-center justify-center border-2",
                tab === TABS[activeTabIndex]
                  ? "border-blue-chill"
                  : "border-gray-200"
              )}
            >
              <span
                className={ClassNames(
                  tab === TABS[activeTabIndex]
                    ? "text-blue-chill"
                    : "light_grayish_navy"
                )}
              >
                0{index + 1}
              </span>
            </span>
            <span
              className={ClassNames(
                "font-normal text-xs",
                tab === TABS[activeTabIndex]
                  ? "text-blue-chill"
                  : "light_grayish_navy"
              )}
            >
              {tab}
            </span>
          </div>
        ))}
      </>
    </div>
  );
};
