import Axios from "Services/Auth/AxiosInterceptors";

/**
 * @description  An API for getting all of the memberships plans
 * @returns the memberships plans
 */
export const GetMembershipPlans = async () => {
  const {
    data: { result },
  } = await Axios.get(`/membership/membership/getactive`);
  console.log("[GetMembershipPlans][API_RESPONSE] - ", result);

  return result;
};

/**
 * @description  An API for updating the membership plan price
 * @param {String} id of the membership plan
 * @param {Object} payload for the price updation
 * Payload_Example :{
 * "price":21
 * }
 * @returns
 */
export const MembershipPriceUpdate = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.post(`/membership/membership/update/price/${id}`, payload);
  console.log("[MembershipPriceUpdate][API_RESPONSE] - ", result);

  return result;
};

export const SetFreeVisit = async (payload) => {
  const {
    data: { result },
  } = await Axios.post('/membership/membership/enable-free-visit', payload);
  console.log("[MembershipSetFreeVisit][API_RESPONSE] - ", result);

  return result;
};
