import { InputField } from "Components/Common/InputField/InputField";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { formatDate } from "Helpers/DateFormat";

export const EditForm = ({ providerFormDetail, setProviderFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <fieldset className="flex flex-col gap-3 col-span-2">
        <label className="font-semibold sapphire">Board Certified</label>
        <RadioButton
          id="1"
          label="Yes"
          value={true}
          isSelected={providerFormDetail?.certified === true}
          onChange={() => {
            setProviderFormDetail({
              ...providerFormDetail,
              certified: true,
            });
          }}
        />
        <RadioButton
          id="2"
          label="No"
          value={false}
          isSelected={providerFormDetail?.certified === false}
          onChange={() => {
            setProviderFormDetail({
              ...providerFormDetail,
              certified: false,
            });
          }}
        />
      </fieldset>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="certified-with"
          label="Board Certfied In"
          value={providerFormDetail?.certificationWith}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              certificationWith: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <br />
      <div className="col-span-2">
        <UploadFile
          mode="Edit"
          id="board-certificate"
          value={providerFormDetail?.certificationDocument}
          label="Board Certificate"
          onResponse={({ data }) => {
            setProviderFormDetail({
              ...providerFormDetail,
              certificationDocument: data,
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="med-school"
          label="Medical School Attended"
          value={providerFormDetail?.medSchoolName}
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              medSchoolName: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>

      <br />
      <div className="col-span-2 lg:col-span-1">
        <DatePicker
          label="Medical School Graduation Date"
          value={
            providerFormDetail?.graduationDate
              ? new Date(providerFormDetail.graduationDate)
              : ""
          }
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              graduationDate:
                fieldValue ? formatDate(fieldValue, "MM/DD/YYYY"):"",
            });
          }}
        />
      </div>
      <br />
      <div className="col-span-2">
        <UploadFile
          mode="Edit"
          id="med-diploma"
          value={providerFormDetail?.degreeDocument}
          label="Medical School Diploma"
          onResponse={({ data }) => {
            setProviderFormDetail({
              ...providerFormDetail,
              degreeDocument: data,
            });
          }}
        />
      </div>
    </div>
  );
};
