import { useState, useEffect } from "react";
import { InputField } from "Components/Common/InputField/InputField";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { GetStates } from "Services/API/GetStates.service";
import { formattedStates } from "Helpers/Providers";
import { formatDate } from "Helpers/DateFormat";
import { Divider } from "Components/Common/Divider/Divider";
import { StateMedLicForm } from "Components/Provider/AddProvider/Forms/StateMedLic/Form";

export const EditForm = ({
  providerFormDetail,
  setProviderFormDetail,
  isDisabled,
  setIsDisabled,
}) => {
  const [states, setStates] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    GetStates()
      .then((res) => {
        setStates(formattedStates(res?.data));
      })
      .catch((err) => {
        console.error("[GetStates][API_ERROR] - ", err);
      });
    setIsDisabled(!(providerFormDetail?.length > 0));
  }, []);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-6">
        {providerFormDetail.length > 0 &&
          providerFormDetail.map((form, index) => (
            <div className="grid grid-cols-2 gap-6" key={index}>
              <span className="col-span-2 font-semibold text-base sapphire">
                License {index + 1} 
              </span>
              
              <div className="col-span-2 lg:col-span-1">
                <Dropdown
                  id="select-state"
                  label="State"
                  onBlur={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              licenseState: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                >
                  <Dropdown.Option
                    id={form?.licenseState}
                    value={form?.licenseState}
                    label={form?.stateName}
                    isDefaultOption={false}
                  />

                  {states.length > 0 &&
                    states.map((state, index) => (
                      <Dropdown.Option
                        key={index}
                        id={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))}
                </Dropdown>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`license-number-${index}`}
                  label="License Number"
                  value={form?.licenseNumber}
                  onBlur={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              licenseNumber: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>

              <div className="col-span-2 lg:col-span-1">
                <DatePicker
                  label="Date First Licensed"
                  value={form?.licensedDate ? new Date(form?.licensedDate) : ""}
                  onBlur={(licenseIssueDate) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              licensedDate:
                                licenseIssueDate &&
                                formatDate(licenseIssueDate, "MM/DD/YYYY"),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <DatePicker
                  label="Date of Expiration"
                  value={
                    form?.licenseExpiration && new Date(form?.licenseExpiration)
                  }
                  onBlur={(expDate) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              licenseExpiration:
                                expDate && formatDate(expDate, "MM/DD/YYYY"),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>

              <div className="col-span-2">
                <UploadFile
                  mode="Edit"
                  id={`certificate-${index}`}
                  value={form?.licenseDocument}
                  label="Medical License"
                  onResponse={({ data }) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              licenseDocument: data,
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
            </div>
          ))}
      </div>
      {Array(count)
        .fill("")
        .map((_, index) => (
          <StateMedLicForm
            key={providerFormDetail.length + 1}
            id={providerFormDetail.length + 1}
            LicensePostfix={providerFormDetail.length + 1}
            states={states}
            formDetail={providerFormDetail}
            setFormDetail={(forms) => {
              setProviderFormDetail(forms);
              setCount(0);
            }}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setProviderFormDetail(
                providerFormDetail.filter(
                  (formInstance) => formInstance.id !== id
                )
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}
      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
