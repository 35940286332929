import { useState } from "react";
import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as RefundIcon } from "Assets/Refund.svg";
import { InputField } from "Components/Common/InputField/InputField";
import { ReactComponent as DollarIcon } from "Assets/Dollar.svg";

export const RefundModal = ({
  isModalOpen,
  isLoading,
  onRefund,
  onCancel,
  setRefundAmount,
  amount,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <ModalBasic
      icon={<RefundIcon />}
      btnText="Confirm Refund"
      heading="Refund Payment"
      content="A refund will be issued to the patient within a few days"
      open={isModalOpen}
      isDisabled={isDisabled}
      loading={isLoading}
      setOpen={(value) => {
        if (value === "Confirm Refund") {
          onRefund();
        } else {
          onCancel();
        }
      }}
    >
      <InputField
        id="payment"
        type="number"
        placeholder="amount"
        isRequired={true}
        onChange={(refundAmount, setInputFieldError) => {
          if (refundAmount > amount) {
            setInputFieldError(
              `Please enter the correct refund amount ${amount}`
            );
            setIsDisabled(true);
          } else if (refundAmount) {
            setRefundAmount(refundAmount);
            setIsDisabled(false);
          } else {
            setIsDisabled(true);
          }
        }}
      >
        <DollarIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />
      </InputField>
    </ModalBasic>
  );
};
