import Axios from "Services/Auth/AxiosInterceptors";

/**
 * @description  An API for searching the provider
 * @param {Object} payload user Email 
 * Payload_Example :{
 *  "username": "aishaburki@yopmail.com"
 * } 
 * @returns the user id and userName 
 * Result_Example :{
 * "id": "629a004bbc7387001f525580",
            "username": "aishaburki@yopmail.com"
 * }
 */
export const SearchProvider = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`/admin/doctor/search`, payload);
  return result;
};
/**
 * @description  An API for searching the admin
 * @param {Object} payload user Email 
 * Payload_Example :{
 *  "username": "aishaburki@yopmail.com"
 * } 
 * @returns the user id and userName 
 * Result_Example :{
 * "id": "629a004bbc7387001f525580",
            "username": "aishaburki@yopmail.com"
 * }
 */
export const SearchAdmin = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`/admin/admin/search`, payload);
  return result;
};
/**
 * @description  An API for searching the patient
 * @param {Object} payload user Email 
 * Payload_Example :{
 *  "username": "aishaburki@yopmail.com"
 * } 
 * @returns the user id and userName 
 * Result_Example :{
 * "id": "629a004bbc7387001f525580",
            "username": "aishaburki@yopmail.com"
 * }
 */
export const SearchPatient = async (searchQuery = "") => {
  const {
    data: { result },
  } = await Axios.post(`/admin/search/patients?partialText=${searchQuery}`);
  console.log("[SEARCH_PATIENTS][API_RESPONSE] - ", result);
  return result;
};
/**
 * 
 * @param {Object} payload containing the filters for the patient search
 * Payload_Example :{
	"searchCriteria": "name",
	"query": "kha",
	"dob_to": "2022-08-20"
}
* @param {Number} pageNumber current  pageNumber
 * @param {Number} perPage no of records to retrieve
 * @returns 
 */
export const SearchPatientByFilters = async (
  payload,
  pageNumber = 1,
  perPage = 20
) => {
  const {
    data: { result },
  } = await Axios.post(
    `/admin/patient/advance-search?page=${pageNumber}&perPage=${perPage}`,
    payload
  );
  console.log("[SearchPatientByFilters][API_RESPONSE] - ", result);
  return result;
};
