import { Route, Switch } from "react-router";
import { PatientsMainPage } from "Pages/Patients/MainPage/MainPage";
import {
  BASE_PATIENT_PATH,
  EDIT_PATIENT_PATH,
  PATIENT_ACCESS_LOGS,
} from "Constants/Views";
import { TheEditPatientPage } from "Pages/Patients/EditViewPatient/EditViewPatient";
import { AccessLogs } from "Pages/Patients/AccessLogs/AccessLogs";
import { useGlobalContext } from "Context/Global";

export default function Patients() {
  const { getAdminRights } = useGlobalContext();
  const { patientRights } = getAdminRights();
  return (
    <Switch>
      <Route exact path={PATIENT_ACCESS_LOGS} render={() => <AccessLogs />} />
      <Route
        exact
        path={EDIT_PATIENT_PATH}
        render={() => <TheEditPatientPage />}
        // render={() =>
        //   patientRights === "Edit" ? (
        //     <TheEditPatientPage />
        //   ) : (
        //     <PatientsMainPage />
        //   )
        // }
      />
      <Route
        exact
        path={BASE_PATIENT_PATH}
        render={() => <PatientsMainPage />}
      />
    </Switch>
  );
}
