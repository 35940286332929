import { InputField } from "Components/Common/InputField/InputField";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { formatDate } from "Helpers/DateFormat";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";

export const EditForm = ({ providerFormDetail, setProviderFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <span className="col-span-2 font-semibold text-base sapphire">
        Residency
      </span>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="program-name"
          label="Program Name"
          value={providerFormDetail?.programName}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              programName: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="street-address"
          label="Street Address"
          value={providerFormDetail?.streetAddress}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              streetAddress: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="city"
          label="City"
          value={providerFormDetail?.city}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              city: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="zip-code"
          label="Zip Code"
          value={providerFormDetail?.zipCode}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              zipCode: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <DatePicker
          label="Graduation Date"
          value={
            providerFormDetail?.graduationDate
              ? new Date(providerFormDetail.graduationDate)
              : ""
          }
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              graduationDate:
                fieldValue && formatDate(fieldValue, "MM/DD/YYYY"),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="program-director"
          label="Program Director"
          value={providerFormDetail?.directorName}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              directorName: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2">
        <UploadFile
          mode="Edit"
          value={providerFormDetail?.file}
          label="Residency Graduation Certificate"
          onResponse={({ data }) => {
            setProviderFormDetail({
              ...providerFormDetail,
              file: data && data,
            });
          }}
        />
      </div>

      <span className="col-span-2 font-semibold text-base sapphire">
        Internship
      </span>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="intern-program-name"
          label="Program Name"
          value={providerFormDetail?.internProgramName}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internProgramName: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="intern-street-address"
          label="Street Address"
          value={providerFormDetail?.internStreetAddress}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internStreetAddress: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="intern-city"
          label="City"
          value={providerFormDetail?.internCity}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internCity: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="intern-zip-code"
          label="Zip Code"
          value={providerFormDetail?.internZipCode}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internZipCode: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <DatePicker
          label="Completion Date"
          value={
            providerFormDetail?.internCompletionDate
              ? new Date(providerFormDetail.internCompletionDate)
              : ""
          }
          onBlur={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internCompletionDate:
                fieldValue && formatDate(fieldValue, "MM/DD/YYYY"),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="intern-program-director"
          label="Program Director"
          value={providerFormDetail?.internProgramDirector}
          onChange={(fieldValue) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internProgramDirector: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2">
        <UploadFile
          mode="Edit"
          id="intern-document"
          value={providerFormDetail?.internDocument}
          label="Residency Graduation Certificate"
          onResponse={({ data }) => {
            setProviderFormDetail({
              ...providerFormDetail,
              internDocument: data && data,
            });
          }}
        />
      </div>
    </div>
  );
};
