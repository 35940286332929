import { useState, useEffect } from "react";
import { Header } from "./Header";
import { formatDate } from "Helpers/DateFormat";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { GetVisitFeedback } from "Services/API/Patients.service";
import { ReactComponent as StarIcon } from "Assets/Star.svg";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

const visitFormatter = (visit) => {
  return {
    id: visit.id || "-",
    rating: visit.rating || "",
    review: visit.text || "",
    recommendation: visit.recommend || "",
    visitQuality: visit.quality || "",
    visitWaitingTime: visit.waiting || "",
    date: formatDate(visit.createdAt, "MM-DD-YYYY") || "",
    time: formatDate(visit.createdAt, "HH:mm A") || "",
  };
};

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const VisitFeedback = ({
  setActiveView,
  patientId,
  visitId,
  docName,
  patientFeedback,
}) => {
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [visit, setVisit] = useState({});
  const [count] = useState(5);

  useEffect(() => {
    if (patientId && visitId) {
      setLoading(true);
      GetVisitFeedback(patientId, { visitId })
        .then((res) => {
          setVisit(visitFormatter(res));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetVisitFeedback][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [patientId, visitId]);

  useEffect(() => {
    if (patientFeedback?.length > 0) {
      setVisit(visitFormatter(patientFeedback?.[0]));
      setLoading(false);
    }
  }, [JSON.stringify(patientFeedback)]);

  return (
    <>
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
      {loading && <Spinner />}
      {!loading && (
        <Header onBack={setActiveView} title={docName}>
          <span className="light_grayish_navy text-sm">{visit.date}</span>
        </Header>
      )}
      {!apiError && !loading && visit ? (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Overall Visit Experience</span>
            <span className="flex">
              {Array(count)
                .fill("")
                .map((_, index) => (
                  <>
                    {visit?.rating < index + 1 ? (
                      <StarIcon className="h-5 w-5 text-gray-200" />
                    ) : (
                      <StarIcon className="h-5 w-5 text-blue-chill" />
                    )}
                  </>
                ))}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Rating</span>
            <span className="flex gap-3 SUB_HEADING_CSS">
              <span className="light_grayish_navy">Quality of visit?</span>
              {visit?.visitQuality}
            </span>
            <span className="flex gap-3 SUB_HEADING_CSS">
              <span className="light_grayish_navy">
                Wait time for the visit?{" "}
              </span>
              {visit?.visitWaitingTime}
            </span>
            <span className="flex gap-3 SUB_HEADING_CSS">
              <span className="light_grayish_navy">
                Would you recommend InstaCured?
              </span>
              {visit?.recommendation ? "Yes" : visit?.visitQuality && "No"}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Note</span>
            <span className={SUB_HEADING_CSS}>{visit?.review}</span>
          </div>
          <div className="flex flex-col gap-2 text-sm light_grayish_navy">
            <span>Date: {visit?.date}</span>
            <span>Time: {visit?.time}</span>
            <span>Provider: {docName}</span>
          </div>
        </div>
      ) : (
        !loading && "No Feedback Found"
      )}
    </>
  );
};
