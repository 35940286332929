import { useState, useEffect } from "react";
import { formattedStates } from "Helpers/Providers";
import { Divider } from "Components/Common/Divider/Divider";
import { GetStates } from "Services/API/GetStates.service";
import { DEAForm } from "Components/Provider/AddProvider/Forms/DrugEnfAdminReg/DEAForm";

export const AddForm = ({ formDetail, setFormDetail }) => {
  const [states, setStates] = useState([]);
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    GetStates()
      .then((res) => {
        setStates(formattedStates(res?.data));
      })
      .catch((err) => {
        console.error("[DrugEnfAdminRegForm][GetStates][API_ERROR] - ", err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-8">
      <DEAForm
        id={1}
        postfix={1}
        states={states}
        formDetails={formDetail}
        setFormDetail={setFormDetail}
        setIsDisabled={setIsDisabled}
      />
      {Array(count)
        .fill("")
        .map((_, index) => (
          <DEAForm
            key={index + 1}
            id={count + 1}
            postfix={count + 1}
            states={states}
            formDetails={formDetail}
            setFormDetail={setFormDetail}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setFormDetail(
                formDetail.filter((formInstance) => formInstance.id !== id)
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}
      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
