import { Route, Switch } from "react-router";
import { TheEditAdminPage } from "Pages/Admin/EditViewAdmin/EditViewAdmin";
import {
  BASE_MEDICAL_CONDITONS_NAMES,
  ADD_MEDICAL_CONDITONS,
} from "Constants/Views";
import { MedConditionsMainPg } from "Pages/MedicalConditions/MainPage/MedConditionsMainPg";
export const MedConditionsNames = () => {
  return (
    <Switch>
      <Route
        exact
        path={BASE_MEDICAL_CONDITONS_NAMES}
        render={() => <MedConditionsMainPg />}
      />
      <Route
        exact
        path={ADD_MEDICAL_CONDITONS}
        render={() => <TheEditAdminPage />}
      />
    </Switch>
  );
};
