import { INTAKE_FORMS, SECOND_LEVEL_ROS } from "Constants/Views";
import { Intake2ndLevel } from "Pages/IntakeForms/Intake2ndLevel";
import { IntakeForms } from "Pages/IntakeForms/IntakeForms";
import { Route, Switch } from "react-router-dom";

export const IntakeFormContainer = () => {
  return (
    <Switch>
      <Route exact path={INTAKE_FORMS} render={() => <IntakeForms />} />
      <Route
        exact
        path={SECOND_LEVEL_ROS}
        render={() => <Intake2ndLevel />}
      />
    </Switch>
  );
};
