import { Divider } from "Components/Common/Divider/Divider";
import { InputField } from "Components/Common/InputField/InputField";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { FellowshipForm } from "Components/Provider/AddProvider/Forms/FellowShip/Form";
import { useState } from "react";

export const EditForm = ({ providerFormDetail, setProviderFormDetail }) => {
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-2 gap-6">
        {providerFormDetail.length > 0 &&
          providerFormDetail.map((form, index) => (
            <>
              <span className="col-span-2 font-semibold text-base sapphire">
                Fellowship {index + 1}
              </span>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`speciality-${index}`}
                  label="Specialty"
                  value={form?.speciality}
                  onChange={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              speciality: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`program-name-${index}`}
                  label="Program Name"
                  value={form?.programName || form?.fellowshipProgramName}
                  onChange={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              programName: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`street-address-${index}`}
                  label="Program Street Address"
                  value={
                    form?.streetAddress || form?.fellowshipProgramStreetAddress
                  }
                  onChange={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              streetAddress: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`city-${index}`}
                  label="City"
                  value={form?.city || form?.fellowshipProgramCity}
                  onChange={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              city: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`zip-code-${index}`}
                  label="Zip Code"
                  value={form?.zipCode || form?.fellowshipProgramZipcode}
                  onChange={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              zipCode: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  id={`director-name-${index}`}
                  label="Fellowship Program Director"
                  value={form?.directorName || form?.fellowshipDirectorName}
                  onChange={(fieldValue) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              directorName: fieldValue && fieldValue.trim(),
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>

              <div className="col-span-2">
                <UploadFile
                  mode="Edit"
                  id={`certificate-${index}`}
                  value={
                    form?.certificate || form?.fellowshipCertificateDocument
                  }
                  label="Fellowship Certificate"
                  onResponse={({ data }) => {
                    setProviderFormDetail(
                      providerFormDetail.map((userDetail) =>
                        userDetail.id === form.id
                          ? {
                              ...userDetail,
                              certificate: data,
                            }
                          : userDetail
                      )
                    );
                  }}
                />
              </div>
            </>
          ))}
      </div>
      {Array(count)
        .fill("")
        .map((_, index) => (
          <FellowshipForm
            key={providerFormDetail.length + 1}
            id={providerFormDetail.length + 1}
            postfix={providerFormDetail.length + 1}
            formDetail={providerFormDetail}
            setFormDetail={(forms) => {
              setProviderFormDetail(forms);
              setCount(0);
            }}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setProviderFormDetail(
                providerFormDetail.filter(
                  (formInstance) => formInstance.id !== id
                )
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}
      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
