import DetailLayout from "Layouts/DetailsLayout/DetailLayout";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import CreateAdmin from "Components/Admins/CreateAdmin/CreateAdmin";
import { BASE_ADMIN_PATH } from "Constants/Views";
import { useAdminContext } from "Context/Admin";

export const CreateAdmins = () => {
  const { setActiveAdminView } = useAdminContext();

  return (
    <DetailLayout
      leftColumn={<LeftSection setActiveAdminView={setActiveAdminView} />}
    >
      <div className="AddProvider_Wrapper flex flex-col gap-4">
        <CreateAdmin />
      </div>
    </DetailLayout>
  );
};
const LeftSection = ({ setActiveAdminView }) => {
  return (
    <div className="pt-8 pl-6 pr-6">
      <div
        onClick={() => setActiveAdminView(BASE_ADMIN_PATH)}
        className="flex pb-2"
      >
        <BackIcon className="h-6 w-6 sapphire cursor-pointer" />
        <span className="ml-2 sapphire text-base font-semibold cursor-pointer">
          {" "}
          Create Admin
        </span>
      </div>
      <span className="light_grayish_navy">
        Create a profile to let others view or edit information as admins
      </span>
    </div>
  );
};
