import useWindowDimensions from "CustomHooks/useWindowDimensions";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";

const MEDIUM_RESOLUTION = 1366;
const HD_RESOLUTION = 1200;
const SMALL_RESOLUTION = 900;

export function AreaCharts({ data }) {
  const { width } = useWindowDimensions();
  return (
    <AreaChart
      //TODO: make this width resposive a little
      width={width <= MEDIUM_RESOLUTION ? HD_RESOLUTION : SMALL_RESOLUTION}
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      {/* <CartesianGrid /> */}
      <XAxis dataKey="date" padding={{ left: 20, right: 30 }} />
      <YAxis dataKey="count" />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="dailyVisits"
        stroke="#45969f"
        fill="#37828B"
      />
    </AreaChart>
  );
}
