import {
  GENERAL_INFO,
  TRAINING,
  EDUCATION,
  SECURITY,
  FELLOWSHIP,
  UPLOAD_FILE,
  DRUG_ENF_ADMIN_REG,
  MED_MALPRACTICE_INS,
  STATE_MED_LIC,
  VISITS_RECORDS,
  EDIT_TABS,
} from "Constants/Provider";
import { DrugEnfAdminRegForm } from "Components/Provider/EditViewProvider/Forms/DrugEnfAdminReg/DrugEnfAdminRegForm";
import { MedMalPracticeForm } from "Components/Provider/EditViewProvider/Forms/MedMalpractice/MedMalPracticeForm";
import { FellowshipForm } from "Components/Provider/EditViewProvider/Forms/Fellowship/FellowshipForm";
import { UploadFilesForm } from "Components/Provider/EditViewProvider/Forms/UploadFiles/UploadFilesForm";
import { TrainingForm } from "Components/Provider/EditViewProvider/Forms/Training/TrainingForm";
import { EducationForm } from "Components/Provider/EditViewProvider/Forms/Education/EducationForm";
import { GeneralInfoForm } from "Components/Provider/EditViewProvider/Forms/GeneralInfo/GeneralInfoForm";
import { SecurityForm } from "Components/Provider/EditViewProvider/Forms/Security/SecurityForm";
import { StateMEdLicenseForm } from "Components/Provider/EditViewProvider/Forms/StateMedLicense/StateMEdLicenseForm";
import { PatientRecordTable } from "../Forms/VisitsRecord/PatientTable";

export const EditContent = ({ activeTabIndex, setActiveTabIndex }) => {
  switch (EDIT_TABS[activeTabIndex]) {
    case VISITS_RECORDS:
      return <PatientRecordTable setActiveTabIndex={setActiveTabIndex} />;
    case GENERAL_INFO:
      return <GeneralInfoForm setActiveTabIndex={setActiveTabIndex} />;
    case STATE_MED_LIC:
      return <StateMEdLicenseForm setActiveTabIndex={setActiveTabIndex} />;
    case DRUG_ENF_ADMIN_REG:
      return <DrugEnfAdminRegForm setActiveTabIndex={setActiveTabIndex} />;
    case EDUCATION:
      return <EducationForm setActiveTabIndex={setActiveTabIndex} />;
    case TRAINING:
      return <TrainingForm setActiveTabIndex={setActiveTabIndex} />;
    case FELLOWSHIP:
      return <FellowshipForm setActiveTabIndex={setActiveTabIndex} />;
    case MED_MALPRACTICE_INS:
      return <MedMalPracticeForm setActiveTabIndex={setActiveTabIndex} />;
    case UPLOAD_FILE:
      return <UploadFilesForm />;
    case SECURITY:
      return <SecurityForm setActiveTabIndex={setActiveTabIndex} />;

    default:
      return "Rest of the forms";
  }
};
