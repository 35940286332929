import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as DeactivateIcon } from "Assets/Deactivate.svg";

export const DeactivateModal = ({
  isModalOpen,
  isLoading,
  onDeactivate,
  onCancel,
}) => {
  return (
    <ModalBasic
      icon={<DeactivateIcon />}
      btnText="Deactivate"
      heading="Deactivate Profile"
      content="Are you sure you want to deactivate this profile? Deactivating a
        provider means they won’t be able to accept patient visits."
      open={isModalOpen}
      loading={isLoading}
      setOpen={(value) => {
        if (value === "Deactivate") {
          onDeactivate();
        } else {
          onCancel();
        }
      }}
    />
  );
};
