import { useState } from "react";
import ClassNames from "Helpers/Common";
import { DownloadFile, UploadFiles } from "Services/API/UploadFile.service";
import { ReactComponent as UploadIcon } from "Assets/FileUpload.svg";
import fileDownload from "js-file-download";
import { Spinner } from "../LoadingSpinner/Spinner";
import "Colors.css";

const UPLOAD_FILE_ERROR = "Sorry! File can not be uploaded. Please try again";
const formatUploadFile = (file) => {
  return file.replace(/.*[\/\\]/, "");
};

export const UploadFile = ({
  id = "upload-file",
  label,
  value = "",
  onResponse,
  mode = "View",
}) => {
  const [fieldError, setFieldError] = useState("");
  const [upLoading, setUploading] = useState(false);
  const [fileName, setFileName] = useState("");

  const FileUploadToServer = async (target) => {
    setUploading(true);
    let fileData = new FormData();
    fileData.append("file", target.files[0]);
    try {
      const response = await UploadFiles(fileData);
      setFileName(formatUploadFile(target.value));
      onResponse?.({
        data: response?.data?.result,
        setFieldError,
        setUploading,
      });
      setUploading(false);
      setFieldError("");
    } catch (error) {
      setFileName("");
      setFieldError(UPLOAD_FILE_ERROR);
      onResponse?.({ error: error });
      setUploading(false);
      console.error("[FileUpload][APIError] is", error);
    }
  };
  return (
    <>
      <label className="block text-sm font-semibold sapphire">{label}</label>
      <div
        className={ClassNames(
          "mt-1 border-2 border-gray-300 rounded-md py-2 px-4 flex justify-between items-center",
          fieldError
            ? "border-red-500 focus:ring-red-500 focus:border-red-500"
            : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
        )}
      >
        <div
          className={ClassNames(
            "relative",
            fieldError ? "coral_red" : "light_grayish_navy"
          )}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
            <UploadIcon className="h-4 w-4" aria-hidden="true" />
          </div>
          {fileName ? (
            <span className="text-sm pl-6 sapphire">{fileName}</span>
          ) : (
            <span className="text-sm pl-6">
              {value ? (
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-light underline"
                >
                  Click here to view the file
                </a>
              ) : (
                "PNG, JPG up to 25MB"
              )}
            </span>
          )}
        </div>

        <div
          className={ClassNames(
            "flex text-sm",
            fieldError ? "coral_red" : "royal_blue"
          )}
        >
          <label
            htmlFor={id}
            className="text-blue-light relative cursor-pointer bg-white rounded-md focus:outline-none"
          >
            <span>
              {(!upLoading && value && mode) === "View" ? (
                <span
                  onClick={() => {
                    setUploading(true);
                    DownloadFile(value)
                      .then((res) => {
                        fileDownload(res.data, label);
                        setUploading(false);
                      })
                      .catch((err) => {
                        console.error("[DownloadFile][API_ERROR] - ", err);
                        setUploading(false);
                      });
                  }}
                >
                  Download
                </span>
              ) : upLoading ? (
                <Spinner width="4" height="4" />
              ) : (
                "Upload"
              )}
            </span>
            {!upLoading ? (
              <input
                id={id}
                name="file-upload"
                type={mode !== "View" ? "file" : "text"}
                onChange={({ target }) => {
                  FileUploadToServer(target);
                }}
                className="sr-only"
              />
            ) : (
              ""
            )}
          </label>
        </div>
      </div>
      {fieldError && (
        <div
          className="pt-2 text-sm text-red-600 font-poppins"
          id={`${label}-error`}
        >
          {fieldError}
        </div>
      )}
    </>
  );
};
