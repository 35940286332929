export const GENERAL_INFO = "General Info";
export const MEDICATIONS = "Medications";
export const MEDICAL_HISTORY = "Medical History";
export const VISITS = "Visits History";
export const UPLOAD_FILE = "Upload of Files";
export const PAYMENTS = "Payments";
export const TABS = [
  GENERAL_INFO,
  MEDICATIONS,
  MEDICAL_HISTORY,
  VISITS,
  UPLOAD_FILE,
  PAYMENTS
];

export const tabIndexes = {
  GENERAL_INFO: 0,
  MEDICATIONS: 1,
  MEDICAL_HISTORY: 2,
  VISITS: 3,
  UPLOAD_FILE: 4,
  PAYMENTS:5
};
