import {
  VISIT_FEEDBACK_VIEW,
  VISIT_NOTES_VIEW,
  VISIT_ORDER_VIEW,
} from "./ConstantsViews";

const TABLE_HEADINGS = [
  "Provider",
  "Created",
  "Status",
  "Details",
  "Actions",
];

export const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
      {['Created', 'Details'].includes(head)
        ? <small className="ml-1">(PST)</small>
        : ''
      }
    </th>
  ))
}

export const TableBody = ({ data, onClick }) => {
  return data.map((patient, index) => (
    <tr key={index}>
      <td className="px-6 py-4 whitespace-nowrap sapphire">
        <div>
          <div>{patient.docName}</div>
          <div>{patient.doctorUsername}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
        {patient.createdAt}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
        {patient.status}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
        <table className="table-auto">
          <tbody>
            <tr>
              <td className="text-sm leading-5 font-regular light_grayish_navy">State</td>
              <td><div className="ml-4">{patient.states}</div></td>
            </tr>
            <tr>
              <td className="text-sm leading-5 font-regular light_grayish_navy">Offer</td>
              <td><div className="ml-4">{patient.offerActiveMinutes}</div></td>
            </tr>
            <tr>
              <td className="text-sm leading-5 font-regular light_grayish_navy">Accepted</td>
              <td><div className="ml-4">{patient.startedAt || 'N/A'}</div></td>
            </tr>
            <tr>
              <td className="text-sm leading-5 font-regular light_grayish_navy">Wait Started</td>
              <td><div className="ml-4">{patient.waitStartedAt || 'N/A'}</div></td>
            </tr>
            <tr>
              <td className="text-sm leading-5 font-regular light_grayish_navy">Wait Ended</td>
              <td><div className="ml-4">{patient.waitEndedAt || 'N/A'}</div></td>
            </tr>
            <tr>
              <td className="text-sm leading-5 font-regular light_grayish_navy">Closed</td>
              <td><div className="ml-4">{patient.endedAt || 'N/A'}</div></td>
            </tr>
          </tbody>
        </table>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-chill cursor-pointer">
        <div className="mt-2 mb-2" onClick={() => onClick(patient, VISIT_ORDER_VIEW)}>
          Order
        </div>
        <div className="mt-2 mb-2" onClick={() => onClick(patient, VISIT_NOTES_VIEW)}>
          Notes
        </div>
        <div className="mt-2 mb-2" onClick={() => onClick(patient, VISIT_FEEDBACK_VIEW)}>
          Feedback
        </div>
      </td>
    </tr>
  ));
};
