import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as HealthIcon } from "Assets/HealthCondition.svg";
import { InputField } from "Components/Common/InputField/InputField";
import { useState } from "react";
import { useEffect } from "react";

export const AddMedConditionModal = ({
  isModalOpen = false,
  isLoading = false,
  medConditionValue = "",
  onConfirm,
  onCancel,
  ICDCode,
}) => {
  const [medConditionName, setMedConditionName] = useState("");
  const [ICDcode, setICDcode] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (medConditionValue) {
      setMedConditionName(medConditionValue);
      setIsEdit(true);
    }
    if (ICDCode) {
      setICDcode(ICDCode);
    }
  }, [medConditionValue, ICDCode]);
  return (
    <ModalBasic
      icon={<HealthIcon className="text-blue-chill bg-white" />}
      btnText="Confirm"
      heading="Medical Health Condition"
      content="Add a medical health condition for patients to select"
      open={isModalOpen}
      loading={isLoading}
      setOpen={(value) => {
        if (value === "Confirm") {
          onConfirm?.(medConditionName, ICDcode);
        } else {
          onCancel?.();
        }
      }}
      isDisabled={!(medConditionName.length > 0)}
    >
      <div className="flex flex-col gap-3">
        <InputField
          id="Medical Condition Name"
          placeholder="Name"
          isRequired={true}
          value={medConditionName}
          onChange={(value) => {
            setMedConditionName(value);
          }}
        />

        <InputField
          id="Medical Condition Code"
          placeholder="ICD-10 Code"
          isRequired={true}
          isDisabled={isEdit}
          value={ICDcode}
          onChange={(value) => {
            setICDcode(value);
            setIsEdit(false);
          }}
        />
      </div>
    </ModalBasic>
  );
};
