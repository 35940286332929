import { useState, useContext, createContext } from "react";
import { useHistory } from "react-router";

const PatientContext = createContext();
const PATIENT_INIT_STATE = {
  name: "",
  id: "",
};
const GENERAL_INFO_INIT_STATE = {
  firstName: "",
  lastName: "",
  userName: "",
  DOB: "",
  gender: "",
  phoneNumber: "",
  streetAddress: "",
  zipCode: "",
  state: "",
  country: "",
  memberShipPlan: "",
  address1:"",
  address2:"",
  middleName:"",
  city:"",
  phoneType:"",
  prefix:"",
  suffix:"",
  stateId:"",
  dosespotId:"",
  cognitoId:""
};
const PatientProvider = ({ children }) => {
  let history = useHistory();
  const [patient, setPatient] = useState(PATIENT_INIT_STATE);
  const [patientDetail, setPatientDetail] = useState(GENERAL_INFO_INIT_STATE);

  const setActiveView = (view) => {
    history.push(view);
  };
  const setActivePatient = (patientData) => {
    localStorage.setItem("PATIENT_DATA", JSON.stringify(patientData));
    setPatient(patientData);
  };
  const getPatientData = () => {
    const PatientData = JSON.parse(localStorage.getItem("PATIENT_DATA"));
    if (patient.name) {
      return patient;
    }
    if (PatientData) {
      return PatientData;
    }
    return;
  };
  const resetPatientData = () => {
    setPatient({ PATIENT_INIT_STATE });
    localStorage.setItem(
      "PATIENT_DATA",
      JSON.stringify({ PATIENT_INIT_STATE })
    );
  };

  const setGeneralInfo = (genralInfo) => {
    localStorage.setItem("PATIENT_DETAIL", JSON.stringify(genralInfo));
    setPatientDetail(genralInfo);
  };
  const getGeneralInfo = () => {
    const PatientDetail = JSON.parse(localStorage.getItem("PATIENT_DETAIL"));
    if (patientDetail.firstName) {
      return patientDetail;
    }
    if (PatientDetail) {
      return PatientDetail;
    }
    return;
  };
  return (
    <PatientContext.Provider
      value={{
        setActiveView,
        setActivePatient,
        setGeneralInfo,
        getPatientData,
        getGeneralInfo,
        resetPatientData,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const usePatientContext = () => {
  return useContext(PatientContext);
};

export { PatientContext, PatientProvider };
