import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { VISIT_TABLE_VIEW } from "./ConstantsViews";

export const Header = ({ onBack, title, children }) => {
  return (
    <div className="flex gap-2 p-2">
      <BackIcon
        onClick={() => {
          onBack(VISIT_TABLE_VIEW);
        }}
        className="h-5 w-5 mt-0.5 sapphire cursor-pointer"
      />
      <div className="flex flex-col ml-2">
        <span className="sapphire text-base font-semibold cursor-pointer">
          {title}
        </span>
        {children}
      </div>
    </div>
  );
};
