import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
    providerFormDetail.length > 0 &&
    providerFormDetail.map((form, index) => (
      <div className="grid grid-cols-2 gap-6" key={index}>
        <span
          className="col-span-2 font-semibold text-base light_grayish_navy"
          key={index}
        >
          DEA {index + 1}
        </span>
        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>DEA State</span>
          <span className={SUB_HEADING_CSS}>{form?.deaState}</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>DEA Registeration Number</span>
          <span className={SUB_HEADING_CSS}>{form?.deaNumber}</span>
        </div>

        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>DEA Issue Dated</span>
          <span className={SUB_HEADING_CSS}>{form?.deaDate}</span>
        </div>

        <div className="flex flex-col gap-2">
          <span className={HEADING_CSS}>DEA Expiration</span>
          <span className={SUB_HEADING_CSS}>{form?.deaExpiration}</span>
        </div>

        <div className="flex flex-col col-span-2 gap-2">
          <UploadFile value={form?.deaDocument} label="DEA Card" mode="View" />
        </div>
      </div>
    ))
  );
};
