import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Navbar from "../../Components/Navbar";
import {
  UsersIcon,
  FireIcon,
  HomeIcon,
  MenuIcon,
  UserIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  BASE_DASHBOARD_PATH,
  BASE_ADMIN_PATH,
  BASE_PATIENT_PATH,
  BASE_PROVIDER_PATH,
  BASE_MEDICAL_CONDITONS_NAMES,
  MEMBERSHIP_PLANS,
  INTAKE_FORMS,
} from "Constants/Views";
import { ReactComponent as CreditCard } from "Assets/CreditCardLayout.svg";
import { ReactComponent as HealthConditionIcon } from "Assets/HealthCondition.svg";
import { ReactComponent as IntakeForms } from "Assets/Form.svg";
import { Link } from "react-router-dom";
import { ReactComponent as InstaCureLogo } from "Assets/InstacureLightLogo.svg";
import "./MainLayout.css";
import ClassNames from "Helpers/Common";

const navigation = [
  { name: "Dashboard", path: BASE_DASHBOARD_PATH, icon: HomeIcon },
  { name: "Providers", path: BASE_PROVIDER_PATH, icon: FireIcon },
  { name: "Patients", path: BASE_PATIENT_PATH, icon: UsersIcon },
  { name: "Admins", path: BASE_ADMIN_PATH, icon: UserIcon },
  {
    name: "Health Conditions",
    path: BASE_MEDICAL_CONDITONS_NAMES,
    icon: HealthConditionIcon,
  },
  {
    name: "Membership",
    path: MEMBERSHIP_PLANS,
    icon: CreditCard,
  },
  {
    name: "Intake Form",
    path: INTAKE_FORMS,
    icon: IntakeForms,
  },
];
export default function MainLayout({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="h-1/2 flex">
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-4">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="pt-5 pb-4">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                      alt="Workflow"
                    />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="flex flex-col gap-2 pl-2">
                      {navigation.map((item) => (
                        <Link to={item.path}>
                          {" "}
                          <div
                            key={item.name}
                            className="group p-2 rounded-md flex items-center gap-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                          >
                            <item.icon
                              className="h-6 w-6 text-white group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>{" "}
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-28">
            <div className="flex-1 flex flex-col fixed h-full bg-blue-chill w-28 overflow-y-auto">
              <div className="flex-1">
                <div className="bg-blue-chill py-4 flex items-center justify-center">
                  <InstaCureLogo className="h-14 w-auto" />
                </div>
                <nav
                  aria-label="Sidebar"
                  className="flex flex-col items-center py-3"
                >
                  {navigation.map((item) => (
                    <Link to={item.path}>
                      <div
                        key={item.name}
                        className="flex flex-col py-4 items-center gap-2 text-indigo-200 hover:bg-blue-light w-28 rounded-none"
                      >
                        {" "}
                        <item.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                        <span
                          className={ClassNames(
                            "flex text-white w-full items-center",
                            item?.name === "Health Conditions"
                              ? "px-4 text-center"
                              : "justify-center"
                          )}
                        >
                          {item.name}
                        </span>
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          {/* Mobile top navigation */}
          <div className="lg:hidden">
            <div className="bg-indigo-600 py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8">
              <div>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                  alt="Workflow"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-indigo-600 rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <Navbar />
          <main className="flex-1 flex overflow-hidden h-1/2">
            <section
              aria-labelledby="primary-heading"
              className="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last"
            >
              {children}
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
