export const LOGIN_VIEW = "login";
export const VERIFICATION_VIEW = "verification";
export const FORGOT_PASSWORD_VIEW = "forgotPassword";
export const RESET_PASSWORD_VIEW = "resetPassword";
export const PROVIDER_VIEW = "providerView";
export const ADMIN_VIEW = "adminView";
export const CREATE_ADMIN_VIEW = "createAdmin";
export const DASHBOARD_VIEW = "dashboard";
export const MEMBERSHIP_PLANS = "/membershipPlan";
export const INTAKE_FORMS = "/intakeForms";

export const CREATE_PROVIDER_PATH = "/provider/create";
export const BASE_PROVIDER_PATH = "/provider";
export const EDIT_PROVIDER_PATH = "/provider/edit&view";
export const PATIENT_ACCESS_LOGS = "/patient/accesslogs";
export const EDIT_PATIENT_PATH = "/patient/edit";
export const BASE_PATIENT_PATH = "/patient/:patient";
export const PATIENT_REFUNDS_PATH = "/patient/refunds";
export const BASE_ADMIN_PATH = "/admin";
export const BASE_DASHBOARD_PATH = "/dashboard";
export const BASE_LOGIN_PATH = "/login";
export const CREATE_ADMIN_PATH = "/admin/create";
export const EDIT_ADMIN_PATH = "/admin/edit&view";
export const ADMIN_ACCESS_LOGS = "/admin/accesslogs";
export const BASE_MEDICAL_CONDITONS_NAMES = "/meds/medicalConditions/names";
export const ADD_MEDICAL_CONDITONS = "/meds/add/medConditions";
export const SECOND_LEVEL_ROS = "/intakeForms/ros2/:id/:name/:limit";
