import { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { ReactComponent as DollarIcon } from "Assets/Dollar.svg";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { SetFreeVisit } from "Services/API/Membership.Service";
import { TableHeading } from "Components/Provider/ProviderMainPage/TableHeading/TableHeading";
import { InputField } from "Components/Common/InputField/InputField";
import { Button } from "Components/Common/Button/Button";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { errorFormatter } from "Helpers/ErrorMsgHandler";

export const FreeVisits = () => {
  const [email, setEmail] = useState('');
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [apiError, setApiError] = useState('');
  
  const isDisabled = email?.length === 0;

  const setFreeVisitForPatient = async () => {
    setIsBtnLoading(true);
    setSuccess(false);
    
    try {
      const response = await SetFreeVisit({ email })
      console.log("SetFreeVisit - response", response)
      if (response?.success) {
        setSuccess(true)
      }
    } catch(err) {
      console.log("SetFreeVisit - error", err)
      if (err?.response?.data?.errors?.length) {
        setApiError(
          errorFormatter(err?.response?.data?.errors)
        )
      }
    }
    setIsBtnLoading(false);
  }
    
  return (
    <div className="p-4 flex flex-wrap gap-4 justify-between">
      <div>
        {success ? (
          <div className="green text-left font-medium">
            Success! {email} now has a free visit enabled.
          </div>
        ) : (
          <span className="light_grayish_navy text-left font-medium">
            Promotional free visits eligible for brand new patients.
          </span>
        )}
        
        {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
        
        <div className="lg:space-y-2 2xl:space-y-4 mt-8">
          <InputField
            placeholder="Patient Email"
            isRequired={true}
            value=""
            onChange={(value) => {
              setEmail(value)
              setSuccess(false)
            }}
          />
          <Button
            className="w-full py-2"
            btnText="Continue"
            isDisabled={isDisabled}
            isLoading={isBtnLoading}
            onClick={setFreeVisitForPatient}
          />
        </div>
      </div>
    </div>
  );
};
