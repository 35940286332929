import Axios from "Services/Auth/AxiosInterceptors";

/**
 * @description  An API for list the ros at 1st level
 * @param {Number} page current page number
 * @param {Number} recordsPerPage no of records to retrieve
 * @returns the ros
 */
export const ListROS = async (page = 1, recordsPerPage = 50) => {
  const {
    data: { result },
  } = await Axios.get(
    `/rosNew/admin/get/roots/all?page=${page}&perPage=${recordsPerPage}`
  );
  return result;
};
/**
 * @description  An API for adding the new ros at any given level
 * @param {Object} payload to add the ros
 * Payload_Example:{
 * 	"name":"maleria",
	"parentId": null
 * }
 * @returns 
 */
export const AddROS = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`/rosNew/admin`, payload);
  return result;
};
export const UpdateROS = async (ROS_ID, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/rosNew/admin/${ROS_ID}`, payload);
  return result;
};
export const DeleteROS = async (ROS_ID) => {
  const {
    data: { result },
  } = await Axios.delete(`/rosNew/admin/${ROS_ID}`);
  return result;
};
export const EnableDisableROS = async (ROS_ID) => {
  const {
    data: { result },
  } = await Axios.patch(`/rosNew/admin/enable/${ROS_ID}`);
  return result;
};

export const ListChildROS = async (
  ROS_ID = "",
  page = 1,
  recordsPerPage = 10
) => {
  const {
    data: { result },
  } = await Axios.get(
    `/rosNew/admin/get/child/all/${ROS_ID}?page=${page}&perPage=${recordsPerPage}`
  );
  return result;
};
