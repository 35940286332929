/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import Table from "Components/Common/Table/Table";
import { useAdminContext } from "Context/Admin";
import { adminsFormatter, formatAdminStatus } from "Helpers/Admins";
import ModalBasic from "Components/Modal/Modal";
import { Button } from "Components/Common/Button/Button";
import { DROPDOWN_OPTIONS } from "Constants/DropDown";
import {
  AdminActivate,
  AdminDeactivate,
  GetAdminDetail,
  GetAdmins,
  MoveToArchive,
} from "Services/API/Admin.service";
import { ApiError } from "Components/Common/ApiError";
import MainLayout from "Layouts/MainLayout/MainLayout";
import SearchBar from "Components/Common/SearchBar/SearchBar";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  ADMIN_ACCESS_LOGS,
  CREATE_ADMIN_PATH,
  EDIT_ADMIN_PATH,
} from "Constants/Views";
import { ReactComponent as DeactivateIcon } from "Assets/Deactivate.svg";
import { ReactComponent as ActivateIcon } from "Assets/Activate.svg";
import { FormattedDropdown } from "Components/Common/FormattedDropdown/FormattedDropDown";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import "Colors.css";
import "./MainPage.css";
import { DeleteModal } from "Components/Provider/ProviderMainPage/Tables/DeleteModal";
import { SearchAdmin } from "Services/API/Search.service";

const TABLE_HEADINGS = ["Name", "Actions", " ", " ", " "];

export function AdminMainPage() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchResults, setSearchResults] = useState({});
  const fetchAdmins = () => {
    setLoading(true);
    GetAdmins(pageNumber)
      .then((res) => {
        setLoading(false);
        setAdmins(adminsFormatter(res?.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
      })
      .catch((err) => {
        setLoading(false);
        console.error(`[AdminsPage][GetAdminsList][Error]`, err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message)
        );
      });

    return () => {
      setAdmins([]);
    };
  };
  useEffect(() => {
    fetchAdmins();
  }, [pageNumber]);

  return (
    <MainLayout>
      <div className="p-8 flex flex-col gap-8">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}

        <Header setSearchResult={setSearchResults} />

        <Table
          heading={<TableHeading />}
          body={
            !loading && admins.length > 0 ? (
              <TableBody
                data={admins}
                setApiError={setApiError}
                fetchAdmins={fetchAdmins}
                searchResults={searchResults}
              />
            ) : (
              !loading && "No Data Found"
            )
          }
        />
        {loading && <Spinner />}

        {!loading && admins.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
    </MainLayout>
  );
}
const Header = ({ setSearchResult }) => {
  const { setActiveAdminView } = useAdminContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    if (searchQuery.length >= 2) {
      SearchAdmin({username:searchQuery})
        .then((res) => {
          setSearchResults([...res]);
        })
        .catch((err) => {
          console.error("[SearchProvider][API_ERROR] - ", err);
        });
    }
  }, [searchQuery]);
  return (
    <div className="flex justify-between items-center ">
      <div className="flex items-center ">
        {" "}
        <span className="font-semibold text-2xl sapphire">Admins</span>
      </div>
      <div className="flex items-center gap-4">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          onSearch={(searchedAdminid) => {
            if (searchedAdminid) {
              GetAdminDetail(searchedAdminid)
                .then(({ result }) => {
                  setSearchResult({
                    id: result?.id,
                    name: `${result?.firstName} ${result?.lastName}` || "",
                    username: result?.username || "",
                    actionStatus: formatAdminStatus(result?.status) || "",
                  });
                })
                .catch((err) => {
                  console.error("[GetAdminDetail][API_ERROR][SEARCH] - ", err);
                });
            }
          }}
        />
        <Button
          className="w-full bg_royal_blue hover:bg-blue-light text-white font-normal text-sm py-1 px-6 inline-flex rounded-md"
          btnText="Add an Admin"
          isDisabled={false}
          onClick={() => setActiveAdminView(CREATE_ADMIN_PATH)}
        />
      </div>
    </div>
  );
};
const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};

const TableBody = ({ data, setApiError, fetchAdmins, searchResults }) => {
  const { setActiveAdminView, setAdminDetails } = useAdminContext();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveAdminId, setArchiveAminId] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  return (
    <>
      {!searchResults?.id
        ? data?.map((admin, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="ml-4">
                  <span
                    onClick={() => {
                      setAdminDetails({ id: admin.id, name: admin.name });
                      setActiveAdminView(EDIT_ADMIN_PATH);
                    }}
                    className="text-sm font-medium eclipse cursor-pointer"
                  >
                    {admin.name}
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      setAdminDetails({ id: admin.id, name: admin.name });
                      setActiveAdminView(EDIT_ADMIN_PATH);
                    }}
                    className="bali_hai text-sm cursor-pointer"
                  >
                    {admin.username}
                  </span>
                </div>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm w-64 text-gray-500">
                <FormattedDropdown
                  status={admin.actionStatus}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={(selectedOption) => {
                    setModal(true);
                    setSelectedUserEmail(admin.username);
                    setSelectedOption(selectedOption);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setAdminDetails({ id: admin.id, name: admin.name });
                    setActiveAdminView(EDIT_ADMIN_PATH);
                  }}
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                >
                  Edit
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setAdminDetails({ id: admin.id, name: admin.name });
                    setActiveAdminView(ADMIN_ACCESS_LOGS);
                  }}
                  className="text-blue-chill hover:text-blue-light cursor-pointer"
                >
                  View Access Log
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  className="coral_red hover:text-red-800 cursor-pointer"
                  // onClick={() => {
                  //   MoveToArchive(admin.id)
                  //     .then((res) => {
                  //       fetchAdmins();
                  //     })
                  //     .catch((err) => {
                  //       console.error(
                  //         "[ADMINS_TABLE][MoveToArchive][API_ERROR - ",
                  //         err
                  //       );
                  //       setApiError(err?.response?.data?.errors || err?.message);
                  //     });
                  // }}
                  onClick={() => {
                    setArchiveAminId(admin.id);
                    setArchiveModalOpen(true);
                  }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ))
        : searchResults?.id && (
            <tr key={searchResults?.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="ml-4">
                  <span
                    onClick={() => {
                      setAdminDetails({
                        id: searchResults?.id,
                        name: searchResults?.name,
                      });
                      setActiveAdminView(EDIT_ADMIN_PATH);
                    }}
                    className="text-sm font-medium eclipse cursor-pointer"
                  >
                    {searchResults?.name}
                  </span>
                  <br />
                  <span
                    onClick={() => {
                      setAdminDetails({
                        id: searchResults?.id,
                        name: searchResults?.name,
                      });
                      setActiveAdminView(EDIT_ADMIN_PATH);
                    }}
                    className="bali_hai text-sm cursor-pointer"
                  >
                    {searchResults?.username}
                  </span>
                </div>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <FormattedDropdown
                  status={searchResults?.actionStatus}
                  dropDownOptions={DROPDOWN_OPTIONS}
                  onClick={(selectedOption) => {
                    setModal(true);
                    setSelectedUserEmail(searchResults?.username);
                    setSelectedOption(selectedOption);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setAdminDetails({
                      id: searchResults?.id,
                      name: searchResults?.name,
                    });
                    setActiveAdminView(EDIT_ADMIN_PATH);
                  }}
                  className="royal_blue hover:text-indigo-900 cursor-pointer"
                >
                  Edit
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  onClick={() => {
                    setAdminDetails({
                      id: searchResults?.id,
                      name: searchResults?.name,
                    });
                    setActiveAdminView(ADMIN_ACCESS_LOGS);
                  }}
                  className="royal_blue hover:text-indigo-900 cursor-pointer"
                >
                  View Access Log
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <span
                  className="coral_red hover:text-red-800 cursor-pointer"
                  // onClick={() => {
                  //   MoveToArchive(admin.id)
                  //     .then((res) => {
                  //       fetchAdmins();
                  //     })
                  //     .catch((err) => {
                  //       console.error(
                  //         "[ADMINS_TABLE][MoveToArchive][API_ERROR - ",
                  //         err
                  //       );
                  //       setApiError(err?.response?.data?.errors || err?.message);
                  //     });
                  // }}
                  onClick={() => {
                    setArchiveAminId(searchResults?.id);
                    setArchiveModalOpen(true);
                  }}
                >
                  Delete
                </span>
              </td>
            </tr>
          )}
      {modal && selectedOption === "Deactivate" ? (
        <ModalBasic
          open={modal}
          icon={<DeactivateIcon />}
          btnText="Deactivate"
          loading={isLoading}
          heading="Deactivate Profile"
          content="Are you sure you want to deactivate this profile?"
          //TODO Content confirmation with UX
          setOpen={(value) => {
            if (value === "Deactivate") {
              setIsLoading(true);
              AdminDeactivate({ email: selectedUserEmail })
                .then((res) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  fetchAdmins();
                })
                .catch((err) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  setApiError(err?.response?.data?.errors);
                  console.error("[AdminDeactivate][API_ERROR] - ", err);
                });
            } else {
              setModal(false);
            }
          }}
        />
      ) : selectedOption === "Activate" ? (
        <ModalBasic
          open={modal}
          icon={<ActivateIcon />}
          btnText="Activate"
          loading={isLoading}
          heading="Activate Profile"
          content="Are you sure you want to Activate this profile?"
          //TODO Content confirmation with UX
          setOpen={(value) => {
            if (value === "Activate") {
              setIsLoading(true);
              AdminActivate({ email: selectedUserEmail })
                .then((res) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  fetchAdmins();
                })
                .catch((err) => {
                  setSelectedOption("");
                  setIsLoading(false);
                  setModal(false);
                  setApiError(err?.response?.data?.errors);
                  console.error("[AdminActivate][API_ERROR] - ", err);
                });
            } else {
              setModal(false);
            }
          }}
        />
      ) : (
        ""
      )}
      {archiveModalOpen && (
        <DeleteModal
          isModalOpen={archiveModalOpen}
          isLoading={isBtnLoading}
          onDelete={() => {
            if (archiveAdminId) {
              setIsBtnLoading(true);
              MoveToArchive(archiveAdminId)
                .then((res) => {
                  fetchAdmins();
                  setIsBtnLoading(false);
                  setArchiveModalOpen(false);
                })
                .catch((err) => {
                  console.error(
                    "[ADMINS_TABLE][MoveToArchive][API_ERROR - ",
                    err
                  );
                  setIsBtnLoading(false);
                  setArchiveModalOpen(false);
                  setApiError(err?.response?.data?.errors || err?.message);
                });
            }
          }}
          onCancel={() => {
            setIsBtnLoading(false);
            setArchiveModalOpen(false);
          }}
        />
      )}
    </>
  );
};
