import Axios from "axios";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for sending a login request
 * @param {Object} payload User Credentails
 * Payload Example {
 *  "email": "xyz@gmail.com",
    "password": "sd5545sd4f5",
 * }
 * @returns an authcode for verification
 */
export const SendLogInReq = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}/admin/admin/login`, payload);
  return result;
};
/**
 * @description  An API for requesting verification
 * @param {Object} payload User Credentails with auth-code
 * Payload Example {
 *  "email": "xyz@gmail.com",
    "password": "sd5545sd4f5",
    "authCode": "XXXX"
 * }
 * @returns token
 */
export const SendVerificationReq = async (payload) => {
  return await Axios.post(
    `${API_BASE}/admin/admin/login-verification`,
    payload
  );
};
/**
 * TODO Confirm the use of this api and add a one liner description of what this api does here
 */
export const ForgotPasswordReq = async (payload) => {
  return await Axios.post(`${API_BASE}/admin/admin/forgot-password`, payload);
};
/**
 * 
 * @param {Object} payload admin credentials 
 * Payload_Example : {
    "email": "newAdmin06@gmail.com",
    "password": "",
    "authCode": ""
}
 * @returns 
 */
export const ChangePassword = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}/admin/admin/confirm-forgot-password`, payload);
  console.log("[ChangePassword][API_RESPONSE] =>", result);
  return result;
};


/**
 * @description  An API for getting the new id token
 * @param {Object} payload user email and the refresh token
 * Payload Example {
 *  "email": "freshuser01@hotmail.com",
    "refreshToken": "fdlskfklamfkmdakmngfjakngjafknvkmacjcanfjdnakodfmajnsfkmasgjnuinarjkfnkdnfjgnako"
 * }
 * @returns
 */
export const RefreshTokenReq = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}/user/user/refresh-access-token`, payload);
  return result;
};

export const PatientRouteLogin = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}/admin/admin/login/direct`, payload);
  return result;
};
