import { useState, useEffect, useReducer } from "react";
import { GENERAL_INFO_INIT_STATE, tabIndexes } from "Constants/Provider";
import { EMAIL_INVALID } from "Constants/ErrorMsgs";
import { useProviderContext } from "Context/Provider";
import { useProviderFormContext } from "Context/ProviderForm";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as EmailIcon } from "Assets/Mail.svg";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { InputField } from "Components/Common/InputField/InputField";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { SaveGeneralInfo } from "Services/API/CreateProvider.service";
import { InputFieldWithDropdown } from "Components/Common/InputFieldWithDropdown/InputFieldWithDropdown";
import { UpdateGeneralInformation } from "Services/API/ProviderEditView.service";
import ClassNames from "Helpers/Common";
import { formatGeneralInfoPayload } from "Helpers/Providers";
import "../FormsCommonCss/Form.css";
import "Colors.css";

const PHONE_OPTIONS = ["US"];
const MED_DOC = "Medical Doctor";
const PHYSICIAN_ASSTT = "Physician Assistant";
const NURSE_PRACTITIONER = "Nurse Practitioner";
const GENDER_OPTIONS = ["Male", "Female", "Unknown"];
const OSTEOPATHIC_DOC = "Doctor of Osteopathic Medicine";
const MAX_PHONE_NUMBER_LENGTH = "10";
const TYPE = "update";
const validateForm = (formFields) => {
  const { apiError, formState } = formFields;
  if (apiError) {
    return false;
  }
  return !(
    formState.DOB !== "" &&
    formState.email !== "" &&
    formState.gender !== "" &&
    formState.lastName !== "" &&
    formState.firstName !== "" &&
    formState.providerType !== "" &&
    formState.nationalProviderIdentifier !== "" &&
    formState.uploadedFile !== "" &&
    formState.referenceName1 !== "" &&
    formState.referenceNumber1 !== "" &&
    formState.referenceName2 !== "" &&
    formState.referenceNumber2 !== "" &&
    formState.referenceName3 !== "" &&
    formState.referenceNumber3 !== "" &&
    formState.clinicianID !== ""
  );
};

const SET_DOB = "SET_DOB";
const SET_FIRST_NAME = "SET_FIRST_NAME";
const SET_LAST_NAME = "SET_LAST_NAME";
const SET_EMAIL = "SET_EMAIL";
const SET_GENDER = "SET_GENDER";
const SET_PROVIDER_TYPE = "SET_PROVIDER_TYPE";
const SET_NATIONAL_IDENTIFIER = "SET_NIP";
const SET_RESUME = "SET_RESUME";
const SET_CLINICIAN_ID = "SET_CLINIC_ID";
const SET_REF_NAME_1 = "SET_REF_NAME_1";
const SET_REF_NAME_2 = "SET_REF_NAME_2";
const SET_REF_NAME_3 = "SET_REF_NAME_3";
const SET_REF_NUMBER_1 = "SET_REF_NUMBER_1";
const SET_REF_NUMBER_2 = "SET_REF_NUMBER_2";
const SET_REF_NUMBER_3 = "SET_REF_NUMBER_3";
const SET_FROM_CONTEXT = "SET_FROM_CONTEXT";
const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DOB:
      return { ...state, DOB: payload };
    case SET_FIRST_NAME:
      return { ...state, firstName: payload.trim() };
    case SET_LAST_NAME:
      return { ...state, lastName: payload.trim() };
    case SET_EMAIL:
      return { ...state, email: payload.trim() };
    case SET_GENDER:
      return { ...state, gender: payload };
    case SET_PROVIDER_TYPE:
      return { ...state, providerType: payload };
    case SET_NATIONAL_IDENTIFIER:
      return { ...state, nationalIdentifier: payload.trim() };
    case SET_CLINICIAN_ID:
      return { ...state, clinicianID: payload };
    case SET_RESUME:
      return { ...state, uploadedFile: payload || "" };
    case SET_REF_NAME_1:
      return { ...state, referenceName1: payload.trim() };
    case SET_REF_NAME_2:
      return { ...state, referenceName2: payload.trim() };
    case SET_REF_NAME_3:
      return { ...state, referenceName3: payload.trim() };
    case SET_REF_NUMBER_1:
      return { ...state, referenceNumber1: payload };
    case SET_REF_NUMBER_2:
      return { ...state, referenceNumber2: payload };
    case SET_REF_NUMBER_3:
      return { ...state, referenceNumber3: payload };
    case SET_FROM_CONTEXT:
      return payload;
    default:
      return state;
  }
};

export function GeneralInfoForm({ setActiveTabIndex }) {
  const { setProviderID, getProviderId } = useProviderContext();
  const { generalInfo: generalInfoCxt, setGeneralInfo } =
    useProviderFormContext();
  const [formState, setFormState] = useReducer(
    reducer,
    GENERAL_INFO_INIT_STATE
  );
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const providerId = getProviderId();
  useEffect(() => {
    if (generalInfoCxt.email) {
      setFormState({ type: SET_FROM_CONTEXT, payload: generalInfoCxt });
    }
  }, [generalInfoCxt]);

  const handleSubmit = () => {
    if (!providerId) {
      setIsLoading(true);
      SaveGeneralInfo(formatGeneralInfoPayload(formState))
        .then(({ data: { result } }) => {
          setGeneralInfo(formState);
          setProviderID(result?.id);
          setIsLoading(false);
          setActiveTabIndex(tabIndexes.STATE_MED_LIC);
        })
        .catch((err) => {
          console.error("[ProviderGeneralInfo][API_ERROR] - ", err);
          setApiError(err?.response?.data?.errors || err?.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      UpdateGeneralInformation(
        getProviderId(),
        formatGeneralInfoPayload(formState, TYPE)
      )
        .then((res) => {
          setIsLoading(false);
          setGeneralInfo(formState);
          setActiveTabIndex(tabIndexes.STATE_MED_LIC);
        })
        .catch((err) => {
          console.error("[UpdateGeneralInformation][API_ERROR] - ", err);
          setApiError(err?.response?.data?.errors || err?.message);
          setIsLoading(false);
        });
    }
  };
  console.log("FOrm state is", formState);
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="firstName"
              label="First Name"
              isRequired={true}
              value={formState.firstName}
              onChange={(value) =>
                setFormState({ type: SET_FIRST_NAME, payload: value })
              }
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="lastName"
              label="Last Name"
              value={formState.lastName}
              isRequired={true}
              onChange={(value) =>
                setFormState({ type: SET_LAST_NAME, payload: value })
              }
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Dropdown
              id="selectGender"
              label="Gender"
              isRequired={true}
              onBlur={(value) => {
                setFormState({ type: SET_GENDER, payload: value });
              }}
            >
              <Dropdown.Option
                id={-1}
                // TODO:Can't pass the default gender value from the CTX
                value={formState.gender || "-1"}
                label=""
                isDefaultOption={true}
              />
              {GENDER_OPTIONS.map((gender, index) => (
                <Dropdown.Option
                  key={index}
                  id={index}
                  value={gender}
                  label={gender}
                />
              ))}
            </Dropdown>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="email"
              type="email"
              value={formState.email}
              label="Email"
              isRequired={true}
              placeholder="name@example.com"
              onChange={(fieldValue) => {
                setFormState({ type: SET_EMAIL, payload: fieldValue });
              }}
              onBlur={(fieldValue, setInputFieldError) => {
                if (
                  !(fieldValue.includes("@") && fieldValue.includes(".com"))
                ) {
                  setInputFieldError(EMAIL_INVALID);
                  setFormState({ type: SET_EMAIL, payload: " " });
                } else {
                  setFormState({ type: SET_EMAIL, payload: fieldValue });
                }
              }}
            >
              <EmailIcon />
            </InputField>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="clinicianID"
              label="Clinician ID"
              value={formState.clinicianID}
              isRequired={true}
              onChange={(value) => {
                setFormState({ type: SET_CLINICIAN_ID, payload: value });
              }}
            />
          </div>
          <div className="col-span-1">
            <DatePicker
              label="Date of Birth"
              isRequired={true}
              value={formState.DOB}
              onBlur={(birthDate) => {
                setFormState({ type: SET_DOB, payload: birthDate });
              }}
            />
          </div>
          <fieldset className="mt-2 space-y-6 col-span-2">
            <div className="space-y-2">
              <label className="font-semibold sapphire">Type of Provider</label>
              <RadioButton
                id="1"
                label="Medical Doctor"
                value={MED_DOC}
                isSelected={formState.providerType === MED_DOC}
                onChange={() => {
                  setFormState({ type: SET_PROVIDER_TYPE, payload: MED_DOC });
                }}
              />
              <RadioButton
                id="2"
                label="Doctor of Osteopathic Medicine"
                value={OSTEOPATHIC_DOC}
                isSelected={formState.providerType === OSTEOPATHIC_DOC}
                onChange={() => {
                  setFormState({
                    type: SET_PROVIDER_TYPE,
                    payload: OSTEOPATHIC_DOC,
                  });
                }}
              />
              <RadioButton
                id="3"
                label="Nurse Practitioner"
                value={NURSE_PRACTITIONER}
                isSelected={formState.providerType === NURSE_PRACTITIONER}
                onChange={() => {
                  setFormState({
                    type: SET_PROVIDER_TYPE,
                    payload: NURSE_PRACTITIONER,
                  });
                }}
              />
              <RadioButton
                id="3"
                label="Physician Assistant"
                value={PHYSICIAN_ASSTT}
                isSelected={formState.providerType === PHYSICIAN_ASSTT}
                onChange={() => {
                  setFormState({
                    type: SET_PROVIDER_TYPE,
                    payload: PHYSICIAN_ASSTT,
                  });
                }}
              />
            </div>
          </fieldset>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="NPI"
              label="National Provider Identifier"
              value={formState.nationalIdentifier}
              type="number"
              isRequired={true}
              onChange={(fieldValue) =>
                setFormState({
                  type: SET_NATIONAL_IDENTIFIER,
                  payload: fieldValue,
                })
              }
            />
          </div>
          <div className="col-span-2">
            <UploadFile
              mode={formState?.uploadedFile ? "View" : "Edit"}
              label="CV/Resume"
              onBlur={() => {}}
              value={formState?.uploadedFile}
              onResponse={({ data }) => {
                setFormState({ type: SET_RESUME, payload: data });
              }}
            />
          </div>
          <span className="col-span-2 font-semibold text-base sapphire">
            References
          </span>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="name1"
              label="Name 1"
              value={formState.referenceName1}
              isRequired={true}
              onChange={(fieldValue) => {
                setFormState({ type: SET_REF_NAME_1, payload: fieldValue });
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputFieldWithDropdown
              id="phoneNumber1"
              label="Phone Number1"
              value={formState.referenceNumber1}
              maxLength={MAX_PHONE_NUMBER_LENGTH}
              isRequired={true}
              options={PHONE_OPTIONS}
              onChange={(fieldValue) => {
                setFormState({ type: SET_REF_NUMBER_1, payload: fieldValue });
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="name2"
              label="Name 2"
              value={formState.referenceName2}
              isRequired={true}
              onChange={(fieldValue) => {
                setFormState({ type: SET_REF_NAME_2, payload: fieldValue });
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputFieldWithDropdown
              id="phoneNumber2"
              label="Phone Number2"
              value={formState.referenceNumber2}
              maxLength={MAX_PHONE_NUMBER_LENGTH}
              isRequired={true}
              options={PHONE_OPTIONS}
              onChange={(fieldValue) => {
                setFormState({ type: SET_REF_NUMBER_2, payload: fieldValue });
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="name3"
              label="Name 3"
              value={formState.referenceName3}
              isRequired={true}
              onChange={(fieldValue) => {
                setFormState({ type: SET_REF_NAME_3, payload: fieldValue });
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputFieldWithDropdown
              id="phoneNumber3"
              label="Phone Number3"
              value={formState.referenceNumber3}
              maxLength={MAX_PHONE_NUMBER_LENGTH}
              isRequired={true}
              options={PHONE_OPTIONS}
              onChange={(fieldValue) => {
                setFormState({ type: SET_REF_NUMBER_3, payload: fieldValue });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className={ClassNames(
            "h-8 w-32",
            !validateForm({
              formState,
              apiError,
            }) && "text-white"
          )}
          btnText={providerId ? "Next" : "Create Profile"}
          isDisabled={validateForm({
            formState,
            apiError,
          })}
          isLoading={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
