import { formatDate } from "Helpers/DateFormat";

export const formatStates = (states) => {
  return states
    ?.map((state) => {
      return state?.name;
    })
    .join(",");
};
export const formattedProviders = (data) => {
  const formattedResult = data?.map((list) => {
    return {
      id: list?.id,
      name: list?.name || "-",
      username: list?.username || "-",
      profileStatus:
        `${list?.status === "Disabled" ? "Inactive" : "Active"}` || "",
      providerType: list?.typeOfProvider,
      actionStatus:
        `${list?.status === "Disabled" ? "Deactivated" : "Activated"}` || "",
      states: formatStates(list?.states) || [],
      image: list?.image || "",
    };
  });
  return formattedResult;
};

export const formattedStates = (states) => {
  return states.map((list) => {
    return {
      id: list?.id,
      name: list?.name || "",
      label: list?.name || "",
      value: list?.id,
    };
  });
};
export const formattedNewProviders = (data) => {
  const formattedResult = data?.map((list) => {
    return {
      id: list?.id,
      name: list?.name || "-",
      username: list?.username || "-",
      profileStatus: list?.profileStatus || "-",
      requestedBy: list?.requestedBy || "-",
      actionStatus:
        `${list?.status === "Disabled" ? "Deactivated" : "Activated"}` || "",
      states: formatStates(list?.states) || [],
    };
  });
  return formattedResult;
};

export const formatGeneralInfoPayload = (formData, type = "") => {
  console.log("Formatting the general info dta is", formData, type);
  switch (type) {
    case "update":
      return {
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthdate: formatDate(formData.DOB, "MM/DD/YYYY"),
        NPI: formData.nationalIdentifier,
        typeOfProvider: formData.providerType,
        referenceName1: formData.referenceName1,
        referenceNumber1: formData.referenceNumber1,
        referenceName2: formData.referenceName2,
        referenceNumber2: formData.referenceNumber2,
        referenceName3: formData.referenceName3,
        referenceNumber3: formData.referenceNumber3,
        resume: formData.uploadedFile,
        dosespotId: formData.clinicianID,
        //TODO:Enable it once the backend resolve this
        // gender: formData.gender,
        // email: formData.email,
      };
    default:
      return {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        email: formData.email,
        birthdate: formatDate(formData.DOB, "MM/DD/YYYY"),
        NPI: formData.nationalIdentifier,
        typeOfProvider: formData.providerType,
        referenceName1: formData.referenceName1,
        referenceNumber1: formData.referenceNumber1,
        referenceName2: formData.referenceName2,
        referenceNumber2: formData.referenceNumber2,
        referenceName3: formData.referenceName3,
        referenceNumber3: formData.referenceNumber3,
        resume: formData.uploadedFile,
        dosespotId: formData.clinicianID,
      };
  }
};
