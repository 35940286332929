export const errorFormatter = (error) => {
  console.error("Current Error is", error);
  switch (error) {
    case "Network Error":
      return "Please check your internet connection";
    case "Token has expired":
      return "We are sorry for the trouble bear with us";
    case error[0].includes("record", "not", "found"):
      return "Can not retrieve the record at the moment";
    case error[0].includes("Feedback", "not", "found"):
      return "No Feedback available at the moment";
    case "timeout of 10000ms exceeded":
      return "We can not retrieve the record at the moment.Please refresh the page";
    case "Cannot read properties of undefined (reading 'protocol')":
      return "Please refresh the page";
    default:
      return error;
  }
};
