import { useState } from "react";
import { Divider } from "Components/Common/Divider/Divider";
import { FellowshipForm } from "Components/Provider/AddProvider/Forms/FellowShip/Form";

export const AddForm = ({fellowshipInfoForm,setFellowShipInfoForm}) => {
    const [count, setCount] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <div className="flex flex-col gap-8">
        <FellowshipForm
          id={1}
          postfix={1}
          formDetail={fellowshipInfoForm}
          setFormDetail={setFellowShipInfoForm}
          setIsDisabled={setIsDisabled}
        />
        {Array(count)
          .fill("")
          .map((_,index) => (
            <FellowshipForm
              key={index + 1}
              id={count + 1}
              postfix={count + 1}
              formDetail={fellowshipInfoForm}
              setFormDetail={setFellowShipInfoForm}
              setIsDisabled={setIsDisabled}
              onRemove={(id) => {
                setFellowShipInfoForm(
                  fellowshipInfoForm.filter(
                    (formInstance) => formInstance.id !== id
                  )
                );
                setCount(count - 1);
                setIsDisabled(false);
              }}
            />
          ))}

        <Divider
          onClick={() => {
            setCount(count + 1);
            setIsDisabled(true);
          }}
          isDisabled={isDisabled}
        />
      </div>
    )
}
