import Axios from "Services/Auth/AxiosInterceptors";
/**
 * @description  An API for getting the medicalcondition names
 * @param {Number} page current  page number
 * @param {Number} recordsPerPage no of records to retrieve
 * @returns the medicalcondition names
 */
export const ListMedConditions = async (page = 1, recordsPerPage = 50) => {
  const {
    data: { result },
  } = await Axios.get(
    `/medicalcondition-new/admin?page=${page}&perPage=${recordsPerPage}`,
  );

  console.table("[ListMedConditions][API_RESPONSE]===> ", result);
  return result;
};
/**
 * @description  An API for adding the med condition name
 * @param {Object} payload Example :{
    "name": "Some Name of a Medicalcondition. e.g. Diabetes",
    "form": true,
    "active": true
} 
 * @returns 
 */
export const AddMedCondition = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`/medicalcondition-new/admin`, payload);
  console.log("[AddMedCondition][API_RESPONSE] - ", result);

  return result;
};
/**
 * @description  An API for updating the med condition name
 * @param {String} medCondition_Id of the med condition
 ** @param {Object} payload Example :{
    "name": "Some Name of a Medicalcondition. e.g. Diabetes",
    "form": true,
    "active": true
} 
 * @returns
 */
export const UpdateMedCondition = async (medCondition_Id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/medicalcondition-new/admin/${medCondition_Id}`,
    payload,
  );
  console.log("[UpdateMedCondition][API_RESPONSE] - ", result);

  return result;
};
export const DeleteMedCondition = async (medCondition_Id) => {
  const {
    data: { result },
  }= await Axios.delete(`/medicalcondition-new/admin/${medCondition_Id}`);
  console.log("[DeleteMedCondition][API_RESPONSE] - ", result);

  return result;
};
/**
 * @description  An API for activating the med condition
 * @param {String} medCondition_Id of the med condition
 * @returns
 */
export const ActivateMedCondition = async (medCondition_Id) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/medicalcondition-new/admin/enable/${medCondition_Id}`,
  );
  console.log("[ActivateMedCondition][API_RESPONSE] - ", result);

  return result;
};
/**
 * @description  An API for deactivating the med condition
 * @param {String} medCondition_Id of the med condition
 * @returns
 */
export const DeactiveMedCondition = async (medCondition_Id) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/medicalcondition-new/admin/disable/${medCondition_Id}`,
  );
  console.log("[DeactiveMedCondition][API_RESPONSE] - ", result);

  return result;
};
/**
 * @description  An API for getting the family condition names
 * @param {Number} page current  page number
 * @param {Number} recordsPerPage no of records to retrieve
 * @returns the familycondition names
 */
export const ListFamilyConditions = async (page = 1, recordsPerPage = 50) => {
  const {
    data: { result },
  } = await Axios.get(
    `/familycondition-new/admin?page=${page}&perPage=${recordsPerPage}`,
  );
  console.log("[ListFamilyConditions][API_RESPONSE] - ", result);

  return result;
};
export const AddFamilyConditions = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`/familycondition-new/admin`, payload);
  console.log("[AddFamilyConditions][API_RESPONSE] - ", result);

  return result;
};
export const UpdateFamilyCondition = async (familyCondition_Id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/familycondition-new/admin/${familyCondition_Id}`,
    payload,
  );
  console.log("[UpdateFamilyCondition][API_RESPONSE] - ", result);

  return result;
};
export const DeleteFamilyCondition = async (familyCondition_Id) => {
  const res = await Axios.delete(
    `/familycondition-new/admin/${familyCondition_Id}`,
  );
  console.log("[DeleteFamilyCondition][API_RESPONSE] - ", res);

  return res?.data;
};
export const ActivateFamilyCondition = async (medCondition_Id) => {
  const {
    data: { result },
  } = await Axios.patch(`/familycondition-new/admin/enable/${medCondition_Id}`);
  console.log("[ActivateFamilyCondition][API_RESPONSE] - ", result);

  return result;
};

export const DeactivateFamilyCondition = async (medCondition_Id) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/familycondition-new/admin/disable/${medCondition_Id}`,
  );
  console.log("[DeactivateFamilyCondition][API_RESPONSE] - ", result);

  return result;
};

/**
 * @description  An API for swapping the med conditions
 * @param {String} medCondition_Id of the med-condition
 * @param {*} payload med-condition order number to be swapped with
 * @returns
 */
export const MedConditionSwap = async (medCondition_Id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/medicalcondition-new/admin/reorder/single/${medCondition_Id}`,
    payload,
  );
  console.log("[MedConditionSwap][API_RESPONSE] - ", result);

  return result;
};

/**
 * @description  An API for reordering the med conditions
 * @param {Object} payload med-conditions array of objects with order number and id
 * Payload_Example :[
 * 	{
		"id": "6335879a1543ea5f1da6b503",
		"order": 2
	},
	{
		"id": "6335874f1543ea5f1da6b4fa",
		"order": 1
	},
	{
		"id": "63352ec0c85caa7ea55960ec",
		"order": 3
	}
 * ]
 * @returns 
 */

export const MedConditionReorder = async (payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/medicalcondition-new/admin/reorder/batch`, payload);
  console.log("[MedConditionReorder][API_RESPONSE] - ", result);

  return result;
};

/**
 * @description  An API for reordering the family med condition list
 * @param {String} medCondition_Id id of the family condition
 * @param {Object} payload order number of the family condition
 * Payload_Example :{
    "order": 1
 * 
 * }
 * @returns 
 */
export const FamilyConditionSwap = async (medCondition_Id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(
    `/familycondition-new/admin/reorder/single/${medCondition_Id}`,
    payload,
  );
  console.log("[FamilyConditionSwap][API_RESPONSE] - ", result);

  return result;
};
export const FamilyConditionReorder = async (payload) => {
  const {
    data: { result },
  } = await Axios.patch(`/familycondition-new/admin/reorder/batch`, payload);
  console.log("[FamilyConditionReorder][API_RESPONSE] - ", result);

  return result;
};

/**
 *
 * @param {String} searchQuery to search for the med condition
 * @param {Number} page Current  page number
 * @param {Number} perPage no of records to return
 * @returns
 */
export const SearchMedConditions = async (
  searchQuery = "",
  page = 1,
  perPage = 10,
) => {
  const {
    data: { result },
  } = await Axios.get(
    `/medicalcondition-new/admin/search/all?queryString=${searchQuery}&page=${page}&perPage=${perPage}`,
  );
  console.log("[FamilyConditionReorder][API_RESPONSE] - ", result);

  return result;
};
/**
 *
 * @param {String} searchQuery to search for the family-conditions
 * @param {Number} page current page number
 * @param {Number} perPage no of records to return
 * @returns
 */
export const SearchFamConditions = async (
  searchQuery = "",
  page = 1,
  perPage = 10,
) => {
  const {
    data: { result },
  } = await Axios.get(
    `/familycondition-new/admin/search/all?queryString=${searchQuery}&page=${page}&perPage=${perPage}`,
  );
  console.log("[FamilyConditionReorder][API_RESPONSE] - ", result);

  return result;
};
