import { IntakeForm2ndLevel } from "Components/IntakeForm/IntakeForm2ndLevel";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import DetailLayout from "Layouts/DetailsLayout/DetailLayout";
import { useHistory, useParams } from "react-router-dom";
import { useState,useEffect } from "react";

export const Intake2ndLevel = () => {
  return (
    <DetailLayout leftColumn={<LeftSection />}>
      <div className="flex flex-col gap-4 h-full overflow-y-auto">
        <IntakeForm2ndLevel />
      </div>
    </DetailLayout>
  );
};
const LeftSection = () => {
  const [formName,setFormName] = useState("")
  const { name } = useParams();
  const history = useHistory()

  useEffect(() => {
    if(name&&!formName){
      setFormName(name)
    }
  },[name])
  return (
    <div className="pt-8 pl-6 pr-6">
      <div onClick={() => history.goBack()} className="flex pb-2">
        <BackIcon className="h-6 w-6 sapphire cursor-pointer" />
        <span className="ml-2 sapphire text-base font-semibold cursor-pointer">
          {" "}
          {formName===name?formName:formName+">"+name}
        </span>
      </div>
      <span className="light_grayish_navy">Add symptoms to this form</span>
    </div>
  );
};
