import "Colors.css";
import ClassNames from "Helpers/Common";

export const RadioButton = ({
  label,
  id,
  value,
  onChange,
  isSelected = false,
  className,
  isDisabled = false,
}) => {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          key={id}
          id={id}
          value={value}
          type="radio"
          checked={isSelected}
          disabled={isDisabled}
          className="h-4 w-4 text-blue-chill"
          onChange={onChange}
        />
      </div>
      <div className="ml-2 text-sm">
        <label
          htmlFor={id}
          className={ClassNames(
            !className ? "font-normal light_grayish_navy" : className,
            isDisabled&& "text-gray-500"
          )}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
