import { useState, useEffect } from "react";
import { tabIndexes } from "Constants/Patient";
import { usePatientContext } from "Context/Patient";
import { Button } from "Components/Common/Button/Button";
import { GetMedicalHistory } from "Services/API/Patients.service";
import { getMedicalHistoryFormatter } from "Helpers/Patients";
import "Components/Patients/EditViewPatient/Forms/FormCommonCss/Form.css";
import "Colors.css";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS =
  "text-sm leading-4 font-normal sapphire flex flex-col gap-1";

export function MedicalHistoryFormView({ setActiveTabIndex }) {
  const { getPatientData } = usePatientContext();
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = getPatientData();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      GetMedicalHistory(id)
        .then((res) => {
          setMedicalHistory(getMedicalHistoryFormatter(res));
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("[GetMedicalHistory ][API_ERROR} -", err);
          setIsLoading(false);
        });
    }
  }, [id]);

  return !isLoading ? (
    <>
      <div className="FormWrapper flex flex-col gap-14">
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Height and Weight</span>
          <span className={SUB_HEADING_CSS}>
            <span>
              Height:{" "}
              {medicalHistory?.heightFeet + "'" + medicalHistory?.heightInches}
            </span>
            <span>Weight: {medicalHistory?.weight}</span>
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Allergies</span>
          <span className={SUB_HEADING_CSS}>
            {medicalHistory?.allergies?.map((allergy, index) => (
              <span className="flex flex-col gap-1.5" key={index}>
                <span>Name: {allergy.name}</span>
                <span>Type: {allergy.type}</span>
                <span>Reaction: {allergy.reaction}</span>
              </span>
            ))}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Alcohol Consumption</span>
          <span className={SUB_HEADING_CSS}>
            <span>{medicalHistory?.alcoholConsumption}</span>
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Tobacco Consumption</span>
          <span className={SUB_HEADING_CSS}>
            <span>Packs a week: {medicalHistory?.tobacoPerWeek}</span>
            <span>Number of years: {medicalHistory?.tobacoAnnual}</span>
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Surgical History</span>
          <span className={SUB_HEADING_CSS}>
            {medicalHistory?.userSurgical?.map((surgery, index) => (
              <span key={index}>{surgery?.name}</span>
            ))}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Family History</span>
          <span className={SUB_HEADING_CSS}>
            {medicalHistory?.familyHistory?.map((disease, index) => (
              <span key={index}>{disease}</span>
            ))}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Immunization History</span>
          <span className={SUB_HEADING_CSS}>
            {medicalHistory?.userImmunization?.immuniztionName?.map(
              (immunization, index) => (
                <span key={index}>
                  {index + 1}:{immunization?.name}{" "}
                  {immunization?.subName?.map(
                    (subName, index) => subName?.name,
                  )}
                </span>
              ),
            )}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className={HEADING_CSS}>Sexual History</span>
          <span className={SUB_HEADING_CSS}>
            <span>{medicalHistory?.sexualHistory}</span>
          </span>
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          btnText="Back"
          className="h-8 w-16 bg-white border-gray-300 sapphire"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.MEDICATIONS)}
        />
        <Button
          btnText="Next"
          className="h-8 w-16 text-white royal_blue"
          isDisabled={false}
          onClick={() => setActiveTabIndex(tabIndexes.VISITS)}
        />
      </div>
    </>
  ) : (
    isLoading && <Spinner />
  );
}
