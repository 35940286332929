import {
  GENERAL_INFO,
  MEDICAL_HISTORY,
  MEDICATIONS,
  UPLOAD_FILE,
  VISITS,
  PAYMENTS,
  TABS,
} from "Constants/Patient";
import { GeneralInfoForm } from "Components/Patients/EditViewPatient/Forms/GeneralInfo";
import { VisitTable } from "Components/Patients/EditViewPatient/Forms/VisitTable/VisitTable";
import { MedicationsFormView } from "Components/Patients/EditViewPatient/Forms/Medications/Medications";
import { MedicalHistoryFormView } from "Components/Patients/EditViewPatient/Forms/MedicalHistory/MedicalHistory";
import { UploadFiles } from "Components/Patients/EditViewPatient/Forms/UploadFiles/UploadFiles";
import { PaymentsTable } from "Components/Patients/EditViewPatient/Forms/PaymentsTable/PaymentsTable";
export const Content = ({ activeTabIndex, setActiveTabIndex }) => {
  switch (TABS[activeTabIndex]) {
    case GENERAL_INFO:
      return <GeneralInfoForm setActiveTabIndex={setActiveTabIndex} />;

    case MEDICATIONS:
      return <MedicationsFormView setActiveTabIndex={setActiveTabIndex} />;

    case VISITS:
      return <VisitTable setActiveTabIndex={setActiveTabIndex} />;

    case MEDICAL_HISTORY:
      return <MedicalHistoryFormView setActiveTabIndex={setActiveTabIndex} />;
    case UPLOAD_FILE:
      return <UploadFiles setActiveTabIndex={setActiveTabIndex} />;
    case PAYMENTS:
      return <PaymentsTable setActiveTabIndex={setActiveTabIndex} />;

    default:
      throw new Error("Tab Index not registered");
  }
};
