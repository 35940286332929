import { formatDate } from "./DateFormat";

export const formattedPatientMemberships = (data) => {
  function formatName(name) {
    if (name === "Yearly Unlimited") return "Yearly Plan";
    if (name === "Monthly Unlimited") return "Monthly Plan";
    return name;
  }
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "",
      name: formatName(list?.name) || "",
      value: list?.userSubscriptionCount || 0,
    };
  });
  return formattedResult;
};
export const formattedDailyVisits = (data) => {
  const formattedResult = data.map((list) => {
    return {
      dailyVisits: list?.count || "-",
      pv: 120,
      date: formatDate(list?.date, "MMM-DD-YY") || "",
      count: list?.count || 0,
    };
  });
  return formattedResult;
};
export const formattedPatientByState = (data) => {
  const formattedResult = data.map((list) => {
    return {
      name: list?.name || "",
      count: list?.stateUserCount || 0,
    };
  });
  return formattedResult;
};
export const formattedProviderByState = (data) => {
  const formattedResult = data.map((list) => {
    return {
      name: list?.name || "",
      count: list?.stateUserCount || 0,
    };
  });
  return formattedResult;
};

export const DUMMY_STATES_METRICS = [
  { name: "Michigan", count: 120 },
  { name: "Masenchusets", count: 220 },
  { name: "Florida", count: 20 },
  { name: "Texas", count: 520 },
  { name: "Hawai", count: 120 },
  { name: "Georgia", count: 220 },
  { name: "Washington", count: 20 },
  { name: "Ohio", count: 520 },
  { name: "New Jersey", count: 120 },
  { name: "Colorad0", count: 220 },
  { name: "Utah", count: 20 },
  { name: "Indiana", count: 520 },
  { name: "Oregon", count: 220 },
  { name: "Montanan", count: 20 },
  { name: "Pensylvania", count: 520 },
  { name: "Tennesse", count: 120 },
  { name: "Maryland", count: 220 },
  { name: "New York", count: 20 },
  { name: "Albabma", count: 520 },
];
export const DUMMY_VISIT_STATS = [
  { dailyVisits: 13, pv: 120, date: "Sep-27-22", count: 13 },
  { dailyVisits: 333, pv: 120, date: "Sep-20-22", count: 333 },
  { dailyVisits: 13, pv: 120, date: "Sep-27-22", count: 13 },
  { dailyVisits: 500, pv: 120, date: "Oct-27-22", count: 500 },
  { dailyVisits: 144, pv: 120, date: "Nov-15-22", count: 144 },
  { dailyVisits: 34, pv: 120, date: "Dec-27-22", count: 34 },
  { dailyVisits: 454, pv: 120, date: "Jan-27-23", count: 454 },
  { dailyVisits: 43, pv: 120, date: "Feb-27-23", count: 43 },
  { dailyVisits: 54, pv: 120, date: "Mar-7-23", count: 54 },
  { dailyVisits: 643, pv: 120, date: "Mar-17-23", count: 643 },
  { dailyVisits: 865, pv: 120, date: "Mar-27-23", count: 865 },
  { dailyVisits: 900, pv: 120, date: "Apr-27-23", count: 900 },
];
