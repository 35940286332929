import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { editTabIndexes, tabIndexes, viewModes } from "Constants/Provider";
import { formatDate } from "Helpers/DateFormat";
import { AddForm } from "./AddForm";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  GetStateMedLicense,
  UpdateStateMedLicense,
} from "Services/API/ProviderEditView.service";
import { ProviderMedLic } from "Services/API/CreateProvider.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import "Components/Provider/FormsCommonCss/Form.css";

const formatStateMedLicApiResDetails = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      stateName: list?.licenseState?.name || "-",
      licenseState: list?.licenseState?.id || "-",
      licenseNumber: list?.licenseNumber || "-",
      licensedDate: formatDate(list?.licensedDate, "MM/DD/YYYY") || "-",
      licenseExpiration:
        formatDate(list?.licenseExpiration, "MM/DD/YYYY") || "-",
      licenseDocument: list?.licenseDocument || "-",
    };
  });
  return formattedResult;
};

export const StateMEdLicenseForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow = false,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState([]);
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [providerId, setProviderId] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetStateMedLicense(providerId)
        .then((res) => {
          setLoading(false);
          if (res?.length > 0) {
            setProviderDetail(formatStateMedLicApiResDetails(res));
            addProvider
              ? setViewMode(viewModes.EDIT_MODE)
              : setViewMode(viewModes.VIEW_MODE);
          } else {
            setViewMode(viewModes.ADD_MODE);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("[GetStateMedLicense][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const AddStateMedLic = async () => {
    try {
      const res = await ProviderMedLic(providerId, providerDetail);
      if (res.data) {
        setIsBtnLoading(false);
        setActiveTabIndex(
          !addProviderFlow
            ? editTabIndexes.DRUG_ENF_ADMIN_REG
            : tabIndexes.DRUG_ENF_ADMIN_REG
        );
        
      }
    } catch (err) {
      console.error("[ProviderMedLic][API_ERROR]", err.response.data.errors);
      setIsBtnLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };

  const UpdateLicense = async (isNewFormAdded = false) => {
    try {
      const res = UpdateStateMedLicense(
        isNewFormAdded
          ? providerDetail
          : providerDetail.filter(({ id }, index) => isNaN(id, index + 1))
      );
      if (res) {
        setIsBtnLoading(false);

        setActiveTabIndex(
          !addProviderFlow
            ? editTabIndexes.DRUG_ENF_ADMIN_REG
            : tabIndexes.DRUG_ENF_ADMIN_REG
        );
      }
    } catch (err) {
      console.error("[UpdateStateMedLicense][API_ERROR] - ", err);
      setIsBtnLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };

  const UpdateAndAddStateMedLic = async () => {
    let newStateMedLicenses = providerDetail.filter(
      ({ id }, index) => !isNaN(id, index + 1)
    );
    if (newStateMedLicenses.length > 0) {
      try {
        const res = await ProviderMedLic(providerId, newStateMedLicenses);
        if (res?.data) {
          let isNewFormAdded = true;
          UpdateLicense(isNewFormAdded);
        }
      } catch (err) {
        console.error("[ProviderMedLic][API_ERROR]", err.response.data.errors);
        setIsBtnLoading(false);
        setApiError(err?.response?.data?.errors || err?.message);
      }
    } else if (!newStateMedLicenses.length > 0) {
      UpdateLicense();
    }
  };

  const AddUpdateStateMedLic = () => {
    if (viewMode !== viewModes.VIEW_MODE) {
      setIsBtnLoading(true);
      if (viewMode === viewModes.EDIT_MODE) {
        UpdateAndAddStateMedLic();
      } else if (viewMode === viewModes.ADD_MODE) {
        AddStateMedLic();
      }
    } else {
      setViewMode(viewModes.EDIT_MODE);
    }
  };

  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isBtnLoading}
              isDisabled={false}
              onClick={() => {
                AddUpdateStateMedLic();
              }}
            />
          </div>
        )}

        <br />
        {viewMode === viewModes.ADD_MODE ? (
          <AddForm
            medLicense={providerDetail}
            setMedLic={setProviderDetail}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        ) : viewMode === viewModes.EDIT_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow
                  ? editTabIndexes.GENERAL_INFO
                  : tabIndexes.GENERAL_INFO
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={isDisabled}
            onClick={() => {
              AddUpdateStateMedLic();
            }}
            isLoading={isBtnLoading}
          />
        </div>
      )}
    </>
  );
};
