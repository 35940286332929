import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { editTabIndexes, tabIndexes, viewModes } from "Constants/Provider";
import { formatDate } from "Helpers/DateFormat";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import {
  GetDEAInfo,
  UpdateDEAInfo,
} from "Services/API/ProviderEditView.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { ProviderDEAInfo } from "Services/API/CreateProvider.service";
import { AddForm } from "./AddForm";
import "Components/Provider/FormsCommonCss/Form.css";

const formatApiRes = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      deaState: list?.deaState?.name || "-",
      deaStateID: list?.deaState?.id || "-",
      deaNumber: list?.deaNumber || "-",
      deaDate: formatDate(list?.deaDate, "MM/DD/YYYY") || "-",
      deaExpiration: formatDate(list?.deaExpiration, "MM/DD/YYYY") || "-",
      deaDocument: list?.deaDocument || "-",
    };
  });
  return formattedResult;
};
const ApiReqformatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      deaState: list?.deaStateID,
      deaNumber: list?.deaNumber,
      deaExpiration: list?.deaExpiration,
      deaDocument: list?.deaDocument,
      deaDate: list?.deaDate,
    };
  });
  return formattedResult;
};
export const DrugEnfAdminRegForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState([]);
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetDEAInfo(providerId)
        .then((res) => {
          setLoading(false);
          if (res.length > 0) {
            setProviderDetail(formatApiRes(res));
            addProvider
              ? setViewMode(viewModes.EDIT_MODE)
              : setViewMode(viewModes.VIEW_MODE);
          } else {
            setViewMode(viewModes.ADD_MODE);
          }
        })
        .catch((err) => {
          console.error("[GetDEAInfo][API_ERROR] - ", err);
          setLoading(false);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const addDeaDetails = async () => {
    try {
      const res = await ProviderDEAInfo(providerId, providerDetail);
      if (res) {
        setIsBtnLoading(false);
        setActiveTabIndex(
          !addProviderFlow ? editTabIndexes?.EDUCATION : tabIndexes.EDUCATION
        );
      }
    } catch (err) {
      console.error(
        "[ProviderDEAInfo][API_ERROR] - ",
        err?.response?.data?.errors
      );
      setIsBtnLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };

  const UpdateDeaDetails = async (isNewFormAdded = false) => {
    try {
      const res = await UpdateDEAInfo(
        ApiReqformatter(
          isNewFormAdded
            ? providerDetail.filter(({ id }, index) => isNaN(id, index + 1))
            : providerDetail
        )
      );
      if (res) {
        setIsBtnLoading(false);
        setActiveTabIndex(
          !addProviderFlow ? editTabIndexes?.EDUCATION : tabIndexes.EDUCATION
        );
      }
    } catch (err) {
      console.error("[UpdateDEAInfo][API_ERROR] - ", err);
      setIsBtnLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };

  const addUpdateDeaDetails = async () => {
    let newDEALicenses = providerDetail.filter(
      ({ id }, index) => !isNaN(id, index + 1)
    );

    if (newDEALicenses.length > 0) {
      try {
        const res = await ProviderDEAInfo(providerId, newDEALicenses);
        if (res?.data) {
          let isNewFormAdded = true;
          UpdateDeaDetails(isNewFormAdded);
        }
      } catch (err) {
        console.error(
          "[ProviderDEAInfo][API_ERROR] - ",
          err?.response?.data?.errors
        );
        setIsBtnLoading(false);
        setApiError(err?.response?.data?.errors || err?.message);
      }
    } else if (!newDEALicenses.length > 0) {
      UpdateDeaDetails();
    }
  };

  const AddUpdateDEALic = () => {
    if (viewMode !== viewModes.VIEW_MODE) {
      setIsBtnLoading(true);
      if (viewMode === viewModes.EDIT_MODE) {
        addUpdateDeaDetails();
      } else if (viewMode === viewModes.ADD_MODE) {
        addDeaDetails();
      }
    } else {
      setViewMode(viewModes.EDIT_MODE);
    }
  };
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isBtnLoading}
              isDisabled={false}
              onClick={() => {
                if (providerDetail?.[0]?.deaNumber) {
                  AddUpdateDEALic();
                } else {
                  setActiveTabIndex(
                    !addProviderFlow
                      ? editTabIndexes?.EDUCATION
                      : tabIndexes.EDUCATION
                  );
                }
              }}
            />
          </div>
        )}
        <br />

        {viewMode === viewModes.ADD_MODE ? (
          <AddForm
            formDetail={providerDetail}
            setFormDetail={setProviderDetail}
          />
        ) : viewMode === viewModes.EDIT_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow
                  ? editTabIndexes?.STATE_MED_LIC
                  : tabIndexes.STATE_MED_LIC
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={false}
            onClick={() => {
              if (providerDetail?.[0]?.deaNumber) {
                AddUpdateDEALic();
              } else {
                setActiveTabIndex(
                  !addProviderFlow
                    ? editTabIndexes?.EDUCATION
                    : tabIndexes.EDUCATION
                );
              }
            }}
            isLoading={isBtnLoading}
          />
        </div>
      )}
    </>
  );
};
