import { useState, useEffect } from "react";
import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { editTabIndexes, tabIndexes, viewModes } from "Constants/Provider";
import { formatDate } from "Helpers/DateFormat";
import { useProviderContext } from "Context/Provider";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { AddMalpracticeDetail } from "Services/API/CreateProvider.service";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import {
  GetMedMalpracticeDetails,
  UpdateMedMalpracticeDetails,
} from "Services/API/ProviderEditView.service";
import { AddForm } from "./AddForm";
import "Components/Provider/FormsCommonCss/Form.css";

const formatDetails = (data) => {
  const formatStates = (states) => {
    return states
      ?.map((state) => {
        return state?.name;
      })
      .join(",");
  };
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      companyName: list?.nameOfCompany || "-",
      policyName: list?.agentIssuingPolicyName || "-",
      policyNumber: list?.agentIssuingPolicyNumber || "-",
      expiryDate:
        formatDate(list?.currentExpirationDate, "MM/DD/YYYY") || new Date(),
      states: formatStates(list?.statesCovered) || [],
      certificate: list?.malpracticeCertificateDocument || "-",
    };
  });
  return formattedResult;
};

const ApiReqformatter = (data) => {
  const formattedResult = data.map((list) => {
    return {
      id: list?.id || "-",
      nameOfCompany: list?.companyName || "-",
      agentIssuingPolicyName: list?.policyName || "-",
      agentIssuingPolicyNumber: list?.policyNumber || "-",
      currentExpirationDate: list?.expiryDate || "-",
      malpracticeCertificateDocument: list?.certificate || "-",
    };
  });
  return formattedResult;
};

export const MedMalPracticeForm = ({
  setActiveTabIndex,
  addProvider = false,
  addProviderFlow = false,
}) => {
  const { getProviderData, getProviderId } = useProviderContext();
  const [providerDetail, setProviderDetail] = useState([]);
  const [viewMode, setViewMode] = useState(viewModes.VIEW_MODE);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (addProvider) {
      setProviderId(getProviderId());
    } else if (!addProvider) {
      const { id } = getProviderData();
      setProviderId(id);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      setLoading(true);
      GetMedMalpracticeDetails(providerId)
        .then((res) => {
          setLoading(false);
          if (Object.keys(res).length === 0) {
            setViewMode(viewModes.ADD_MODE);
          } else {
            setProviderDetail(formatDetails(res));
            addProvider
              ? setViewMode(viewModes.EDIT_MODE)
              : setViewMode(viewModes.VIEW_MODE);
          }
        })
        .catch((err) => {
          console.error("[GetMedMalpracticeDetails][API_ERROR] - ", err);
          setLoading(false);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message)
          );
        });
    }
  }, [providerId]);

  const addMedMalPracticeDetails = async () => {
    try {
      const res = await AddMalpracticeDetail(providerId, providerDetail);
      setIsLoading(false);
      setActiveTabIndex(
        !addProviderFlow ? editTabIndexes?.UPLOAD_FILE : tabIndexes.UPLOAD_FILE
      );
    } catch (err) {
      console.error(
        "[AddMalpracticeDetail][API_ERROR] - ",
        err?.response?.data.errors
      );
      setIsLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };

  const updateMedMalDetails = async (isNewFormAdded = false) => {
    try {
      const res = await UpdateMedMalpracticeDetails(
        ApiReqformatter(
          isNewFormAdded
            ? providerDetail.filter(({ id }, index) => isNaN(id, index + 1))
            : providerDetail
        )
      );
      if (res) {
        setIsLoading(false);
        setActiveTabIndex(
          !addProviderFlow ? editTabIndexes?.UPLOAD_FILE : tabIndexes.UPLOAD_FILE
        );
      }
    } catch (err) {
      console.error("[UpdateTrainingInformation][API_ERROR] - ", err);
      setIsLoading(false);
      setApiError(err?.response?.data?.errors || err?.message);
    }
  };

  const updateAddMedMalPracticeDetails = async () => {
    let newMedMalpracticeLicenses = providerDetail.filter(
      ({ id }, index) => !isNaN(id, index + 1)
    );
    if (newMedMalpracticeLicenses.length > 0) {
      try {
        const res = await AddMalpracticeDetail(
          providerId,
          newMedMalpracticeLicenses
        );
        if (res) {
          let isNewFormAdded = true;
          updateMedMalDetails(isNewFormAdded);
        }
      } catch (err) {
        console.error(
          "[AddMalpracticeDetail][API_ERROR] - ",
          err?.response?.data.errors
        );
        setIsLoading(false);
        setApiError(err?.response?.data?.errors || err?.message);
      }
    } else if (!newMedMalpracticeLicenses.length > 0) {
      updateMedMalDetails();
    }
  };

  const addUpdateMedMalPracticeDetails = () => {
    if (viewMode !== viewModes.VIEW_MODE) {
      setIsLoading(true);
      if (viewMode === viewModes.EDIT_MODE) {
        updateAddMedMalPracticeDetails();
      } else if (viewMode === viewModes.ADD_MODE) {
        addMedMalPracticeDetails();
      }
    } else {
      setViewMode(viewModes.EDIT_MODE);
    }
  };
  return (
    <>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="FormWrapper">
        {loading && <Spinner />}
        {!loading && !addProvider && (
          <div className="flex justify-end">
            <Button
              className="h-8 w-24 text-white"
              btnText={
                viewMode !== viewModes.VIEW_MODE ? "Save" : "Edit Profile"
              }
              isLoading={isLoading}
              isDisabled={false}
              onClick={() => {
                addUpdateMedMalPracticeDetails();
              }}
            />
          </div>
        )}
        <br />
        {viewMode === viewModes.ADD_MODE ? (
          <AddForm
            formState={providerDetail}
            setFormState={setProviderDetail}
          />
        ) : viewMode === viewModes.EDIT_MODE ? (
          <EditForm
            providerFormDetail={providerDetail}
            setProviderFormDetail={setProviderDetail}
          />
        ) : (
          !loading && <ViewForm providerFormDetail={providerDetail} />
        )}
      </div>
      {!loading && addProvider && (
        <div className="flex justify-between">
          <Button
            btnText="Back"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            onClick={() => {
              setActiveTabIndex(
                !addProviderFlow ? editTabIndexes?.SECURITY : tabIndexes.SECURITY
              );
            }}
            isDisabled={false}
          />
          <Button
            btnText="Next"
            className="h-8 w-16 bg-white border-gray-300 saphire"
            isDisabled={providerDetail?.length > 0 ? false : true}
            onClick={() => {
              addUpdateMedMalPracticeDetails();
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
