import { EditForm } from "./EditForm";
import { ViewForm } from "./ViewForm";
import { useState, useEffect } from "react";
import { useAdminContext } from "Context/Admin";
import { BASE_ADMIN_PATH } from "Constants/Views";
import { Button } from "Components/Common/Button/Button";
import { GetAdminDetail, UpdateAdminDetail } from "Services/API/Admin.service";
import "Components/Admins/FormsCommonCss/Form.css";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { ApiError } from "Components/Common/ApiError";

const adminDetailFormatter = (data) => {
  return {
    id: data?.id,
    firstName: data?.firstName || "-",
    lastName: data?.lastName || "-",
    email: data?.email || "-",
    phoneNumber: data?.phoneNumber || "-",
    providerAccess: data?.providerProfileAccessType || "-",
    patientAccess: data?.adminProfileAccessType || "-",
  };
};

export const EditViewAdmin = () => {
  const { setActiveAdminView, getAdminDetails } = useAdminContext();
  const [adminDetail, setadminDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiError, setApiError] = useState("");
  const { id } = getAdminDetails();

  useEffect(() => {
    if (id) {
      GetAdminDetail(id)
        .then((res) => {
          setadminDetail(adminDetailFormatter(res?.result));
        })
        .catch((err) => {
          console.error("[GetAdminDetail][API_ERROR] - ", err);
        });
    }
  }, [id]);
  return (
    <>
      <div className="FormWrapper">
        <div className="flex justify-end">
          <Button
            className="h-8 w-24 text-white"
            btnText={isEditMode ? "Save" : "Edit Profile"}
            isDisabled={false}
            isLoading={isLoading}
            onClick={() => {
              if (isEditMode) {
                setIsLoading(true);
                UpdateAdminDetail(id, {
                  firstName: adminDetail.firstName,
                  lastName: adminDetail.lastName,
                  phoneNumber: adminDetail.phoneNumber.includes("+")
                    ? adminDetail.phoneNumber
                    : "+" + adminDetail.phoneNumber,
                  providerProfileAccessType: adminDetail.providerAccess,
                  adminProfileAccessType: adminDetail.patientAccess,
                })
                  .then((res) => {
                    if (res?.result === "success") {
                      setActiveAdminView(BASE_ADMIN_PATH);
                    }
                    setIsLoading(false);
                  })
                  .catch((err) => {
                    console.error("[UpdateAdminDetail][API_ERROR] - ", err);
                    setIsLoading(false);
                    setApiError(
                      errorFormatter(
                        err?.response?.data?.errors || err?.message
                      )
                    );
                  });
              } else {
                setIsEditMode(true);
              }
            }}
          />
        </div>
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}

        <br />
        {isEditMode ? (
          <EditForm
            adminFormDetail={adminDetail}
            setAdminFormDetail={setadminDetail}
          />
        ) : (
          <ViewForm adminFormDetail={adminDetail} />
        )}
      </div>
    </>
  );
};
