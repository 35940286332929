import { useState, useEffect } from "react";
import { Button } from "Components/Common/Button/Button";
import {
  BASE_DASHBOARD_PATH,
  LOGIN_VIEW,
  DASHBOARD_VIEW,
} from "Constants/Views";
import { tokenFormatter } from "Helpers/Login";
import { useLoginContext } from "Context/Login";
import { useGlobalContext } from "Context/Global";
import { SendVerificationReq, SendLogInReq } from "Services/API/Login.service";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { ApiError } from "Components/Common/ApiError";
import ClassNames from "Helpers/Common";
import { GetAdminPersonalDetails } from "Services/API/Admin.service";
import OtpInput from "react-otp-input";
import "./Verification.css";
import "Colors.css";

const TOTAL_VERIFICATION_CHARS = 4;

export default function Verification() {
  const { setActiveView, getCredentials, resetCredentials, setCredentials } =
    useLoginContext();
  const { verification } = getCredentials();
  const { setTokenDetails, setAdminRights, setAdminDetail } =
    useGlobalContext();
  const [codeVerificationChars, setCodeVerificationChars] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setIsDisabled(!(codeVerificationChars.length === TOTAL_VERIFICATION_CHARS));
  }, [codeVerificationChars]);

  const handleSubmit = () => {
    const { email, password } = getCredentials();
    setIsLoading(true);
    if (
      email &&
      password &&
      codeVerificationChars.length === TOTAL_VERIFICATION_CHARS
    ) {
      SendVerificationReq({
        email,
        password,
        authCode: codeVerificationChars,
      })
        .then(async ({ data: { result } }) => {
          setTokenDetails(tokenFormatter(result.auth));
          setAdminRights({
            providerRights: result?.adminProfile?.providerProfileAccessType,
            patientRights: result?.adminProfile?.adminProfileAccessType,
          });

          try {
            const res = await GetAdminPersonalDetails();
            setAdminDetail({
              name: res?.name,
              id: res?.id,
            });
            setIsLoading(false);
            resetCredentials();
            setActiveView(DASHBOARD_VIEW, BASE_DASHBOARD_PATH);
          } catch (error) {
            console.error("[GetAdminPersonalDetails][API_ERROR] =>", error);
          }
        })
        .catch((err) => {
          console.error(
            "[SendVerificationReq][API_ERROR] -",
            err?.response?.data?.errors || err
          );
          setIsLoading(false);
          setApiError(err?.response?.data?.errors);
          setIsDisabled(true);
        });
    }
  };

  const handleResend = () => {
    const { email, password } = getCredentials();
    if (email && password) {
      SendLogInReq({
        email: email,
        password: password,
      })
        .then(async (res) => {
          const { authCode } = res;
          console.log(`[SendLogInReq][API_RESPONSE]`, authCode);
          setCredentials({ email, password, verification: authCode });
          try {
            const res = await GetAdminPersonalDetails();
            console.log("Res of the admin credentials are", res);
            setAdminDetail({
              name: res?.name,
              id: res?.id,
            });
          } catch (error) {
            console.error("[GetAdminPersonalDetails][API_ERROR] =>", error);
          }
        })
        .catch((err) => {
          setIsDisabled(true);
          setApiError(err?.response?.data?.errors);
          console.error(
            "[SendLogInReq_RESEND_CODE][API_ERROR] - ",
            err?.response?.data?.errors || err
          );
        });
    }
  };

  return (
    <>
      <div
        onClick={() => setActiveView(LOGIN_VIEW)}
        className="flex mb-12 items-center gap-3"
      >
        <BackIcon className="text-blue-chill cursor-pointer" />
        <span className="text-blue-chill text-base cursor-pointer"> Back</span>
      </div>
      <span className="sapphire mt-6 text-3xl font-extrabold font-poppins">
        Verification
      </span>
      <br />
      <div className="text-blue-chill text-left font-medium font-poppins w-72">
        Please enter the code sent to your email
      </div>
      <div className="pt-4">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      </div>
      <div className="space-y-6 mt-8">
        <div
          className="flex flex-row justify-between gap-4 text-center Code_Boxs"
          onKeyPress={(e) => {
            if (e.key === "Enter" && codeVerificationChars?.length === 4) {
              handleSubmit();
            }
          }}
        >
          <OtpInput
            value={codeVerificationChars}
            onChange={(otp) => {
              setCodeVerificationChars(otp);
            }}
            numInputs={4}
            autoFocus={true}
            placeholder="XXXX"
            inputStyle={{
              border: "1px solid #45969f",
              borderRadius: "8px",
              width: "60px",
              height: "54px",
              fontSize: "16px",
              color: "#000",
              fontWeight: "300",
              caretColor: "#9298A9",
              marginLeft: "10px",
            }}
            focusStyle={{
              border: "1px solid #9298A9",
              outline: "none",
            }}
          />
        </div>
        {verification && <div>Your verification code is {verification}</div>}
        <div>
          <Button
            btnText="Verify"
            className={ClassNames("w-full py-2", !isDisabled && "text-white")}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={handleSubmit}
          />
          <div className="flex justify-end mt-3">
            <span
              onClick={() => handleResend()}
              className="text-blue-chill cursor-pointer"
            >
              Resend code
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
