import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
      providerFormDetail.length > 0 &&
        providerFormDetail.map((form, index) => (
            <div  key={index} className="grid grid-cols-2 gap-6">
            <span className="col-span-2 font-semibold text-base sapphire">
              Policy {index + 1}
            </span>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Name of Company</span>
              <span className={SUB_HEADING_CSS}>{form?.companyName}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Agent Issuing Policy Name</span>
              <span className={SUB_HEADING_CSS}>{form?.policyName}</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Policy Number</span>
              <span className={SUB_HEADING_CSS}>{form?.policyNumber}</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>Current Expiration Date</span>
              <span className={SUB_HEADING_CSS}>{form?.expiryDate}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className={HEADING_CSS}>States Covered</span>
              <span className={SUB_HEADING_CSS}>{form?.states}</span>
            </div>

            <div className="flex flex-col col-span-2 gap-2">
              <UploadFile
                value={form?.certificate}
                label="Malpractice Certificate/Verification"
              />
            </div>
            </div>
        ))
  );
};