/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { FormattedDropdown } from "Components/Common/FormattedDropdown/FormattedDropDown";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { PaginationBtns } from "Components/Common/PaginationBtns/PaginationBtns";
import Table from "Components/Common/Table/Table";
import { TableHeading } from "Components/Provider/ProviderMainPage/TableHeading/TableHeading";
import ClassNames from "Helpers/Common";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { AddMedConditionModal } from "Pages/MedicalConditions/MainPage/AddMedConditionModal";
import {
  ActivateMedCondition,
  DeactiveMedCondition,
  DeleteMedCondition,
  ListMedConditions,
  MedConditionSwap,
  UpdateMedCondition,
  MedConditionReorder,
} from "Services/API/MEDConditions.service";
import { MedReorderModal } from "../MedReorderModal";
import {
  MED_CONDITIONS_DROPDOWN_OPTIONS,
  MED_CONDITIONS_HEADINGS,
} from "./CommonHeadings";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as BarsIcon } from "Assets/Bars.svg";
import { DeleteMedConditionsModal } from "./DeleteModal";

export const MedConditionTable = ({
  selectedResult,
  addedNewMedCondition = false,
  setAddedNewMedCondition,
}) => {
  const [medConditions, setMedConditions] = useState([]);
  const [medicalConditions, setMedicalConditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [addMedConditionModal, setAddMedConditionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [medConditionDetails, setMedConditionDetails] = useState({
    id: "",
    name: "",
    ICDCode: "",
  });
  const [isMedReorderModal, setMedReorderModal] = useState(false);
  const [currentMedReorder, setcurrentMedReorder] = useState({
    id: "",
    serialNumber: "",
  });
  const [reOrdered, setReordered] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getMedConditions = () => {
    setLoading(true);
    ListMedConditions(pageNumber)
      .then((res) => {
        setLoading(false);
        setMedConditions(res?.data?.reverse());
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setApiError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error("[ListMedConditions][API_ERROR] - ", err);
        setApiError(
          errorFormatter(err?.response?.data?.errors || err?.message),
        );
      });
    return () => {
      setMedConditions([]);
    };
  };

  const handleDragEnd = (result) => {
    setReordered(true);
    if (!result.destination) return;
    const items = Array.from(medConditions);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);
    setMedConditions(items);
  };

  useEffect(() => {
    getMedConditions();
  }, [pageNumber, addedNewMedCondition]);
  useEffect(() => {
    if (selectedResult?.length > 0) {
      setMedicalConditions([...selectedResult]);
    }
    if (!selectedResult?.length > 0 && medConditions?.length > 0) {
      setMedicalConditions(medConditions);
    }
  }, [JSON.stringify(selectedResult), medConditions]);

  useEffect(() => {
    if (reOrdered) {
      console.log("medConditions ====>", medConditions);
      MedConditionReorder(
        medConditions.map((item, index) => {
          return { id: item?.id, order: index };
        }),
      )
        .then((res) => {})
        .catch((err) => {
          console.error("[MedConditionReorder][API_ERROR] ===>", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message),
          );
        });
    }
  }, [medConditions]);
  return (
    <>
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}

      <Table heading={<TableHeading TableHeader={MED_CONDITIONS_HEADINGS} />} />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="medConditions">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="border -mt-6 px-3"
            >
              {medicalConditions?.map((medCondition, index) => (
                <Draggable
                  key={index + 1}
                  draggableId={`${index + 2}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="flex "
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="flex gap-2 items-center w-24">
                        <BarsIcon className="h-4 w-4 cursor-pointer " />

                        <div
                          className="flex items-center gap-2 text-sm font-medium eclipse cursor-pointer"
                          onClick={() => {
                            setcurrentMedReorder({
                              id: medCondition?.id,
                              serialNumber: index + 1,
                            });
                            setMedReorderModal(true);
                          }}
                        >
                          <div className="flex  items-center justify-center">
                            <div className=" w-full">
                              <div className="mx-auto inline-flex w-full justify-center ">
                                <a
                                  className="group max-w-max relative mx-1 flex flex-col items-center justify-center  "
                                  href="#"
                                >
                                  {index + 1 ?? "*"}
                                  <div className="[transform:perspective(50px)_translateZ(0)_rotateX(10deg)] group-hover:[transform:perspective(0px)_translateZ(0)_rotateX(0deg)] absolute bottom-0 mb-6 origin-bottom transform rounded text-white opacity-0 transition-all duration-300 group-hover:opacity-100">
                                    <div className="flex max-w-xs flex-col items-center ">
                                      <div className="flex items-center justify-center font-normal rounded-lg w-32 h-8 bg-white text-black-eclipse p-1 text-xs text-center shadow-xl">
                                        Click to reorder
                                      </div>
                                      <div className="clip-bottom h-2 w-4 bg-blue-chill"></div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-start px-20 2xl:px-36 w-2/5 text-sm font-medium eclipse cursor-pointer">
                        {medCondition.name}
                      </div>
                      <div className="flex items-center justify-start px-8 2xl:px-24 w-1/6 text-sm font-medium eclipse cursor-pointer">
                        {medCondition?.ICDCode ?? "- - -"}
                      </div>
                      <div className="flex gap-2 items-center justify-end w-24 2xl:w-40">
                        <span
                          className={ClassNames(
                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bali_hai bg-gray-100",
                            !medCondition.active
                              ? "bg-gray-100 "
                              : "bg-green-200",
                          )}
                        >
                          {medCondition.active ? "Active" : "Disabled"}
                        </span>
                      </div>

                      <div className="flex py-2 items-center justify-end 2xl:px-24 w-64 2xl:w-1/4 text-sm">
                        <FormattedDropdown
                          status={
                            !medCondition.active ? "Deactivated" : "Activated"
                          }
                          dropDownOptions={MED_CONDITIONS_DROPDOWN_OPTIONS}
                          onClick={(selectedOption) => {
                            if (selectedOption === "Activate") {
                              ActivateMedCondition(medCondition?.id)
                                .then((res) => {
                                  if (res) {
                                    getMedConditions();
                                  }
                                })
                                .catch((err) => {
                                  console.error(
                                    "[ActivateMedCondition][API_ERROR] - ",
                                    err,
                                  );
                                });
                            } else if (selectedOption === "Deactivate") {
                              DeactiveMedCondition(medCondition?.id)
                                .then((res) => {
                                  if (res) {
                                    getMedConditions();
                                  }
                                })
                                .catch((err) => {
                                  console.error(
                                    "[DeactiveMedCondition][API_ERROR] - ",
                                    err,
                                  );
                                });
                            }
                          }}
                        />
                      </div>

                      <div className="flex justify-center items-center w-48 2xl:w-2/12  whitespace-nowrap text-left text-sm font-medium">
                        <span
                          onClick={() => {
                            setMedConditionDetails({
                              id: medCondition?.id,
                              name: medCondition?.name,
                              ICDCode: medCondition?.ICDCode,
                            });
                            setAddMedConditionModal(true);
                          }}
                          className="text-blue-chill hover:text-indigo-900 cursor-pointer"
                        >
                          Edit
                        </span>
                      </div>
                      <div className="flex justify-center items-center text-left text-sm font-medium ">
                        <span
                          className="coral_red hover:text-red-800 cursor-pointer"
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setMedConditionDetails({
                              id: medCondition?.id,
                              name: medCondition?.name,
                              ICDCode: medCondition?.ICDCode,
                            });
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {loading && <Spinner />}
      {!loading && medConditions?.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
      {addMedConditionModal && (
        <AddMedConditionModal
          isModalOpen={addMedConditionModal}
          onCancel={() => setAddMedConditionModal(false)}
          isLoading={isLoading}
          medConditionValue={medConditionDetails?.name}
          ICDCode={medConditionDetails?.ICDCode}
          onConfirm={(medConditionName) => {
            setIsLoading(true);
            UpdateMedCondition(medConditionDetails?.id, {
              name: medConditionName,
              ICDCode: medConditionDetails?.ICDCode,
            })
              .then((res) => {
                if (res?.id) {
                  setIsLoading(false);
                  setAddMedConditionModal(false);
                  setAddedNewMedCondition(!addedNewMedCondition);
                }
              })
              .catch((err) => {
                setIsLoading(false);
                console.error("[AddMedConditionsNames][API_ERROR] -", err);
              });
          }}
        />
      )}
      {
        <MedReorderModal
          isModalOpen={isMedReorderModal}
          onCancel={() => setMedReorderModal(false)}
          isLoading={isLoading}
          medConditionValue={currentMedReorder?.serialNumber}
          onConfirm={(medSerialNo) => {
            setIsLoading(true);
            MedConditionSwap(currentMedReorder?.id, {
              order: medConditions[medSerialNo - 1]?.order,
            })
              .then((res) => {
                if (res?.id) {
                  setIsLoading(false);
                  setMedReorderModal(false);
                  setAddedNewMedCondition(!addedNewMedCondition);
                }
              })
              .catch((err) => {
                setIsLoading(false);
                console.error("[AddMedConditionsNames][API_ERROR] -", err);
                setApiError(
                  errorFormatter(err?.response?.data?.errors || err?.message),
                );
              });
          }}
        />
      }
      {isDeleteModalOpen && (
        <DeleteMedConditionsModal
          isModalOpen={isDeleteModalOpen}
          isLoading={loading}
          onCancel={() => setIsDeleteModalOpen(false)}
          onDelete={() => {
            if (medConditionDetails?.id) {
              setLoading(true);
              DeleteMedCondition(medConditionDetails?.id)
                .then((res) => {
                  getMedConditions();
                  setMedConditionDetails({
                    id: "",
                    name: "",
                    ICDCode: "",
                  });
                  setLoading(false);
                  setIsDeleteModalOpen(false);
                })
                .catch((err) => {
                  console.error("[DeleteMedCondition][API_ERROR] - ", err);
                  setLoading(false);
                  setIsDeleteModalOpen(false);
                  setApiError(
                    errorFormatter(
                      err?.response?.data?.errors ||
                        err?.message ||
                        "Try again later",
                    ),
                  );
                });
            }
          }}
        />
      )}
    </>
  );
};
