import { initialStates, GENERAL_INFO_INIT_STATE } from "Constants/Provider";
import { createContext, useReducer, useContext } from "react";

const initialProviderState = {
  generalInfo: GENERAL_INFO_INIT_STATE,
  stateMedicalLicenses: [],
  drugEnforcment: [],
  education: initialStates.EDUCATION_FORM,
  training: initialStates.TRAINING_FORM,
  fellowShip: [],
  security: initialStates.SECURITY_FORM,
  malpractice: [],
  uploadFiles: {
    providerAgreement: "",
    ECFMGcertificate: "",
    idCard: "",
    passport: "",
    socialSecurityCard: "",
    IRS_W9form: "",
    providerCompletedApplication: "",
    HIPAAconfidentialityAgreement: "",
    instacuredTechnologySecurityAndPrivacyAgreement: "",
    instacuredProviderApplication: "",
    instacuredConfidentialityAgreement: "",
    instacuredADApolicy: "",
    instacuredSexualHarassmentPolicy: "",
    instacuredPrescribingPolicy: "",
    instacuredAntibioticStewardshipPolicy: "",
    instacuredNonDisclosureAgreement: "",
  },
};

const SET_GENERAL_INFO = "generalInfo";
const SET_STATE_MEDICAL_LICENSES = "stateMedicalLicenses";
const SET_EDUCATION = "education";
const SET_TRAINING = "training";
const SET_SECURITY = "security";
const SET_UPLOAD_FILE = "uploadFiles";
const RESET_PROVIDER = "resetProvider";
const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_GENERAL_INFO:
      return { ...state, generalInfo: payload };
    case SET_STATE_MEDICAL_LICENSES:
      return { ...state, stateMedicalLicenses: payload };
    case SET_EDUCATION:
      return { ...state, education: payload };
    case SET_TRAINING:
      return { ...state, training: payload };
    case SET_SECURITY:
      return { ...state, security: payload };
    case RESET_PROVIDER:
      return {
        ...state,
        generalInfo: GENERAL_INFO_INIT_STATE,
        stateMedicalLicenses: [],
        drugEnforcment: [],
        education: initialStates.EDUCATION_FORM,
        training: initialStates.TRAINING_FORM,
        fellowShip: [],
        security: initialStates.SECURITY_FORM,
        malpractice: [],
        uploadFiles: {
          providerAgreement: "",
          ECFMGcertificate: "",
          idCard: "",
          passport: "",
          socialSecurityCard: "",
          IRS_W9form: "",
          providerCompletedApplication: "",
          HIPAAconfidentialityAgreement: "",
          instacuredTechnologySecurityAndPrivacyAgreement: "",
          instacuredProviderApplication: "",
          instacuredConfidentialityAgreement: "",
          instacuredADApolicy: "",
          instacuredSexualHarassmentPolicy: "",
          instacuredPrescribingPolicy: "",
          instacuredAntibioticStewardshipPolicy: "",
          instacuredNonDisclosureAgreement: "",
        },
      };
    default:
      return state;
  }
};

const AppContext = createContext();
const ProviderForm = ({ children }) => {
  const [providerState, setProviderState] = useReducer(
    reducer,
    initialProviderState
  );

  const setValToProviderCxt = (type, payload) => {
    setProviderState({ type, payload });
  };

  const setGeneralInfo = (generalInfo) =>
    setValToProviderCxt(SET_GENERAL_INFO, generalInfo);
  const setStateMedicalLicenses = (stateMedicalLicenses) =>
    setValToProviderCxt(SET_STATE_MEDICAL_LICENSES, stateMedicalLicenses);
  const setEducation = (education) =>
    setValToProviderCxt(SET_EDUCATION, education);
  const setTraining = (training) => setValToProviderCxt(SET_TRAINING, training);
  const setSecurity = (security) => setValToProviderCxt(SET_SECURITY, security);
  const resetProvderCtx = () => setValToProviderCxt(RESET_PROVIDER);
  return (
    <AppContext.Provider
      value={{
        generalInfo: providerState.generalInfo,
        stateMedicalLicenses: providerState.stateMedicalLicenses,
        setGeneralInfo,
        setStateMedicalLicenses,
        education: providerState.education,
        setEducation,
        training: providerState.training,
        setTraining,
        security: providerState.security,
        setSecurity,
        resetProvderCtx,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useProviderFormContext = () => {
  return useContext(AppContext);
};
export { AppContext, ProviderForm };
