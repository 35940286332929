import { useState, useEffect } from "react";
import { Header } from "./Header";
import { GetVisitNotes } from "Services/API/Patients.service";
import { formatDate } from "Helpers/DateFormat";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { errorFormatter } from "Helpers/ErrorMsgHandler";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

const notesFormatter = (note) => {
  const medicationsFormatter = (medications) => {
    return medications.map((medicine) => {
      return {
        id: medicine.id || "-",
        dosage: medicine.dosage || "-",
        frequency: medicine.frequency || "-",
        name: medicine.medicationId?.name || "-",
      };
    });
  };
  const formatFamilyHistory = (familyHistory) => {
    return familyHistory?.map((history) => {
      return history?.name;
    });
  };
  const formatUserImmunization = (userImmunization) => {
    const formatImmunizationForm = (immunizations) => {
      return immunizations?.map((immunization) => {
        return {
          id: immunization?.id,
          name: immunization?.name,
          subName: immunization?.children?.map((child) => {
            if (child.selected) {
              return { id: child?.id, name: child.name };
            }
          }),
        };
      });
    };
    return {
      immuniztionName: formatImmunizationForm(
        userImmunization?.immunizationForm,
      ),
    };
  };
  const formatSurgicalHistory = (surgicalHistory) => {
    return surgicalHistory?.custom?.map((surgery) => {
      return surgery?.name;
    });
  };
  const formatUserAllergy = (userAllergy) => {
    return userAllergy?.map((allergy) => {
      return {
        name: allergy.allergyId.name || "-",
        type: allergy.allergyId.type || "-",
        reaction: allergy?.reaction || "-",
      };
    });
  };
  return {
    id: note?.id || "-",
    plan: note?.plan || "-",
    date: formatDate(note?.createdAt, "MM-DD-YYYY") || "",
    time: formatDate(note?.createdAt, "HH:mm A") || "",
    physicalExam: note?.physical || "-",
    followUp: note?.followup || "-",
    assessment: note?.assessment || "-",
    subjective: note?.subjective || "-",
    weight: note?.medicalHistory?.weightLbs || "-",
    alcoholConsumption: note?.medicalHistory?.alcohol || "-",
    tobacoPerWeek: note?.medicalHistory?.tobaccoPerWeek || "-",
    tobacoAnnualy: note?.medicalHistory?.tobaccoYears || "-",
    sexualHistory: note?.medicalHistory?.sexualHistory || "-",
    medications: medicationsFormatter(note?.userMedications) || [],
    height:
      `${note?.medicalHistory?.feet ?? ""}'${
        note?.medicalHistory?.inches ?? ""
      }` || "-",
    surgicalHistory: note?.medicalHistory?.userSurgical
      ? formatSurgicalHistory(note?.medicalHistory?.userSurgical) || []
      : [],
    familyHistory:
      formatFamilyHistory(note?.medicalHistory?.familyHistory) || [],
    immunizationHistory: note?.medicalHistory?.userImmunization
      ? formatUserImmunization(note?.medicalHistory?.userImmunization) || []
      : [],
    allergies: formatUserAllergy(note?.medicalHistory?.userAllergies) || [],
  };
};

const addendumsFormatter = (addendums) => {
  return addendums?.map((data) => {
    return {
      description: data?.text || "-",
      date: formatDate(data?.createdAt, "MM-DD-YYYY") || "",
      time: formatDate(data?.createdAt, "HH:mm A") || "",
    };
  });
};

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const VisitNotes = ({
  setActiveView,
  visitId,
  patientId,
  docName,
  patientNotes,
}) => {
  const [notes, setNotes] = useState({});
  const [addendum, setAddendums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    if (patientId && visitId) {
      setLoading(true);
      GetVisitNotes(patientId, { visitId })
        .then((res) => {
          console.log("Get notes api res is", res);
          setNotes(notesFormatter(res?.note));
          setAddendums(addendumsFormatter(res?.addendums));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("[PatientGetNotes][API_ERROR] - ", err);
          setApiError(
            errorFormatter(err?.response?.data?.errors || err?.message),
          );
        });
    }
  }, [patientId, visitId]);

  useEffect(() => {
    if (patientNotes?.notes?.id) {
      setNotes(notesFormatter(patientNotes?.notes));
      setAddendums(addendumsFormatter(patientNotes?.addendums));
      setLoading(false);
    }
  }, [JSON.stringify(patientNotes)]);

  return (
    <>
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
      <Header onBack={setActiveView} title={docName}>
        <span className="light_grayish_navy text-sm">{notes?.date}</span>
      </Header>
      {!loading ? (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Subjective</span>
            <span className={SUB_HEADING_CSS}>{notes?.subjective}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Physical Exam</span>
            <span className={SUB_HEADING_CSS}>{notes?.physicalExam}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Assessment</span>
            <span className={SUB_HEADING_CSS}>{notes?.assessment}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Plan</span>
            <span className={SUB_HEADING_CSS}>{notes?.plan}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>FollowUp</span>
            <span className={SUB_HEADING_CSS}>{notes?.followUp}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className={HEADING_CSS}>Medications</span>
            <span className={SUB_HEADING_CSS}>
              {notes?.medications?.map((medications, index) => (
                <span className="flex flex-col gap-2" key={index}>
                  <span>{medications.name}</span>
                  <span>Dosage: {medications.dosage}</span>
                  <span>Frequency: {medications.frequency}</span>
                </span>
              ))}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Height and Weight</span>
            <span className="flex flex-col SUB_HEADING_CSS">
              <span>Height: {notes?.height}</span>
              <span>Weight: {notes?.weight}</span>
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Allergies</span>
            <span className={SUB_HEADING_CSS}>
              {notes?.allergies?.map((allergy, index) => (
                <span className="flex flex-col gap-1.5" key={index}>
                  <span>Name: {allergy.name}</span>
                  <span>Type: {allergy.type}</span>
                  <span>Reaction: {allergy.reaction}</span>
                </span>
              ))}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Alcohol Consumption</span>
            <span className={SUB_HEADING_CSS}>
              <span>{notes?.alcoholConsumption}</span>
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Tobacco Consumption</span>
            <span className="flex flex-col SUB_HEADING_CSS">
              <span>Packs a week: {notes?.tobacoPerWeek}</span>
              <span>Number of years: {notes?.tobacoAnnualy}</span>
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Surgical History</span>
            <span className="flex flex-col gap-1.5 SUB_HEADING_CSS">
              {notes?.surgicalHistory?.map((surgery, index) => (
                <span key={index}>
                  {index + 1}: {surgery}
                </span>
              ))}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Family History</span>
            <span className="flex flex-col gap-1.5 SUB_HEADING_CSS">
              {notes?.familyHistory?.map((disease, index) => (
                <span key={index}>
                  {" "}
                  {index + 1}: {disease}
                </span>
              ))}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Immunization History</span>
            <span className={SUB_HEADING_CSS}>
              {notes?.immunizationHistory?.immuniztionName?.map(
                (immunization, index) => (
                  <span key={index}>
                    {index + 1}:{immunization?.name}{" "}
                    {immunization?.subName?.map(
                      (subName, index) => subName?.name,
                    )}
                    <br />
                  </span>
                ),
              )}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            <span className={HEADING_CSS}>Sexual History</span>
            <span className={SUB_HEADING_CSS}>
              <span>
                Currently Active: {notes?.sexualHistory ? "Yes" : "No"}
              </span>
            </span>
          </div>
          <div className="flex flex-col gap-1 text-sm light_grayish_navy">
            <span>Date: {notes?.date}</span>
            <span>Time: {notes?.time}</span>
            <span>Provider: {docName}</span>
          </div>
          {addendum?.map((list, index) => (
            <div className="flex flex-col gap-2" key={index}>
              <div className="flex flex-col gap-1">
                <span className={HEADING_CSS}>Addendum</span>
                <span className={SUB_HEADING_CSS}>
                  <span>{list.description}</span>
                </span>
              </div>
              <div className="flex flex-col gap-1 text-sm light_grayish_navy">
                <span>Date: {list.date}</span>
                <span>Time: {list.time}</span>
                <span>Provider: {docName}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
