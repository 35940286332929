import { useState, useEffect } from "react";
import { BASE_ADMIN_PATH } from "Constants/Views";
import { useAdminContext } from "Context/Admin";
import { EMAIL_INVALID } from "Constants/ErrorMsgs";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as EmailIcon } from "Assets/Mail.svg";
import { CreateAdmin } from "Services/API/CreateAdmin.service";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { InputField } from "Components/Common/InputField/InputField";
import "Components/Admins/FormsCommonCss/Form.css";
import "./CreateAdmin.css";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";
import { phoneNumberFormatter } from "Helpers/Common";

const EDIT = "Edit";
const VIEW = "View";

export default function CreateAdminForm() {
  const { setActiveAdminView } = useAdminContext();
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedProviderOption, setSelectedProviderOption] = useState(null);
  const [selectedPatientOption, setSelectedPatientOption] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (!isDisabled) {
      setIsLoading(true);
      CreateAdmin({
        email,
        lastName: lastName,
        firstName: firstName,
        phoneNumber: phoneNumber,
        providerProfileAccessType: selectedProviderOption,
        patientProfileAccessType: selectedPatientOption,
      })
        .then((res) => {
          if (res) {
            setIsLoading(false);
            setActiveAdminView(BASE_ADMIN_PATH);
          }
        })
        .catch((error) => {
          console.error("[CreateAdmin][API_ERROR] - ", error);
          setApiError(error?.response?.data?.errors);
          setIsDisabled(true);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsDisabled(
      !(
        firstName &&
        lastName &&
        phoneNumber &&
        selectedProviderOption &&
        selectedPatientOption
      )
    );
  }, [
    email,
    firstName,
    lastName,
    phoneNumber,
    selectedProviderOption,
    selectedPatientOption,
  ]);

  return (
    <div className="FormWrapper flex flex-col gap-6">
      {apiError && <ErrorPopUp error={apiError} setApiError={setApiError} />}
      <div className="CreateAdmin_FormWrapper">
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="firstName"
              label="First Name"
              isRequired={true}
              onChange={(fieldValue) => {
                setFirstName(fieldValue?.trim());
              }}
              onBlur={(fieldValue) => {
                setFirstName(fieldValue ? fieldValue.trim() : "");
              }}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="lastName"
              label="Last Name"
              isRequired={true}
              onChange={(fieldValue) => {
                setLastName(fieldValue?.trim());
              }}
              onBlur={(fieldValue) => {
                setLastName(fieldValue ? fieldValue.trim() : "");
              }}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="email"
              type="email"
              label="Email"
              isRequired={true}
              icon={<EmailIcon />}
              placeholder="name@example.com"
              onChange={(fieldValue) => {
                setEmail(fieldValue?.trim());
              }}
              onBlur={(fieldValue, setInputFieldError) => {
                if (
                  !(fieldValue.includes("@") && fieldValue.includes(".com"))
                ) {
                  setInputFieldError(EMAIL_INVALID);
                  setIsDisabled(true);
                } else {
                  setEmail(fieldValue.trim());
                }
              }}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              id="phoneNumber"
              type="tel"
              label="Phone Number"
              maxLength="20"
              placeholder="(555) 555-5555"
              isRequired={true}
              onChange={(fieldValue) => {
                setPhoneNumber(phoneNumberFormatter(fieldValue));
              }}
              onBlur={(fieldValue) => {
                setPhoneNumber(
                  fieldValue ? phoneNumberFormatter(fieldValue) : ""
                );
              }}
            />
          </div>

          <fieldset className="mt-4 space-y-6">
            <div className="space-y-2">
              <label className="font-semibold sapphire">
                Provider Profile Access Type
              </label>
              <RadioButton
                id="1"
                label="Can Edit"
                value={EDIT}
                isSelected={selectedProviderOption === EDIT}
                onChange={() => {
                  setSelectedProviderOption(EDIT);
                }}
              />
              <RadioButton
                id="2"
                label="Can View"
                value={VIEW}
                isSelected={selectedProviderOption === VIEW}
                onChange={() => {
                  setSelectedProviderOption(VIEW);
                }}
              />
            </div>
            <div className="space-y-2">
              <label className="font-semibold sapphire">
                Patient Profile Access Type
              </label>
              <RadioButton
                id="3"
                label="Can Edit"
                value={EDIT}
                isSelected={selectedPatientOption === EDIT}
                onChange={() => setSelectedPatientOption(EDIT)}
              />
              <RadioButton
                id="4"
                label="Can View"
                value={VIEW}
                isSelected={selectedPatientOption === VIEW}
                onChange={() => setSelectedPatientOption(VIEW)}
              />
            </div>
          </fieldset>
        </div>
      </div>
      <div className="flex h-8 justify-end text-white">
        <Button
          btnText="Save"
          className="w-24  rounded-md"
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
