import { Divider } from "Components/Common/Divider/Divider";
import { StateMedLicForm } from "Components/Provider/AddProvider/Forms/StateMedLic/Form";
import { formattedStates } from "Helpers/Providers";
import { useState, useEffect } from "react";
import { GetStates } from "Services/API/GetStates.service";

export const AddForm = ({ medLicense, setMedLic,isDisabled, setIsDisabled }) => {
  const [states, setStates] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    GetStates()
      .then((res) => {
        setStates(formattedStates(res?.data));
      })
      .catch((err) => {
        console.error("[GetStates][API_ERROR] - ", err);
      });
  }, []);
  return (
    <div className="flex flex-col gap-8">
      <StateMedLicForm
        id={1}
        LicensePostfix={1}
        states={states}
        formDetail={medLicense}
        setFormDetail={setMedLic}
        setIsDisabled={setIsDisabled}
      />
      {Array(count)
        .fill("")
        .map((_, index) => (
          <StateMedLicForm
            key={index + 1}
            id={count + 1}
            LicensePostfix={count + 1}
            states={states}
            formDetail={medLicense}
            setFormDetail={setMedLic}
            setIsDisabled={setIsDisabled}
            onRemove={(id) => {
              setMedLic(
                medLicense.filter((formInstance) => formInstance.id !== id)
              );
              setCount(count - 1);
              setIsDisabled(false);
            }}
          />
        ))}

      <Divider
        onClick={() => {
          setCount(count + 1);
          setIsDisabled(true);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};
