import {
  EDUCATION,
  DRUG_ENF_ADMIN_REG,
  GENERAL_INFO,
  STATE_MED_LIC,
  TRAINING,
  UPLOAD_FILE,
  FELLOWSHIP,
  MED_MALPRACTICE_INS,
  SECURITY,
  TABS,
} from "Constants/Provider";
import { GeneralInfoForm } from "Components/Provider/AddProvider/Forms/GeneralInfoForm/GeneralInfoForm";
import { UploadFilesForm } from "Components/Provider/AddProvider/Forms/UploadFilesForm/UploadFilesForm";
import { TrainingForm } from "Components/Provider/EditViewProvider/Forms/Training/TrainingForm";
import { DrugEnfAdminRegForm } from "Components/Provider/EditViewProvider/Forms/DrugEnfAdminReg/DrugEnfAdminRegForm";
import { FellowshipForm } from "Components/Provider/EditViewProvider/Forms/Fellowship/FellowshipForm";
import { EducationForm } from "Components/Provider/EditViewProvider/Forms/Education/EducationForm";
import { SecurityForm } from "Components/Provider/EditViewProvider/Forms/Security/SecurityForm";
import { MedMalPracticeForm } from "Components/Provider/EditViewProvider/Forms/MedMalpractice/MedMalPracticeForm";
import { StateMEdLicenseForm } from "Components/Provider/EditViewProvider/Forms/StateMedLicense/StateMEdLicenseForm";

export const Content = ({
  activeTabIndex,
  setActiveTabIndex,
  addProviderFlow,
}) => {
  switch (TABS[activeTabIndex]) {
    case GENERAL_INFO:
      return (
        <GeneralInfoForm
          setActiveTabIndex={setActiveTabIndex}
        />
      );
    case STATE_MED_LIC:
      return (
        <StateMEdLicenseForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
          addProvider={true}
        />
      );
    case DRUG_ENF_ADMIN_REG:
      return (
        <DrugEnfAdminRegForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
          addProvider={true}
        />
      );
    case EDUCATION:
      return (
        <EducationForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
          addProvider={true}
        />
      );
    case TRAINING:
      return (
        <TrainingForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
          addProvider={true}
        />
      );
    case FELLOWSHIP:
      return (
        <FellowshipForm
          setActiveTabIndex={setActiveTabIndex}
          addProvider={true}
          addProviderFlow={addProviderFlow}
        />
      );
    case UPLOAD_FILE:
      return (
        <UploadFilesForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
        />
      );
    case MED_MALPRACTICE_INS:
      return (
        <MedMalPracticeForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
          addProvider={true}
        />
      );
    case SECURITY:
      return (
        <SecurityForm
          setActiveTabIndex={setActiveTabIndex}
          addProviderFlow={addProviderFlow}
          addProvider={true}
        />
      );
    default:
      return "Rest of the forms";
  }
};
