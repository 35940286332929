import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as ArrowDownIcon } from "Assets/ArrowDown.svg";
import ClassNames from "Helpers/Common";
import "Colors.css";

export function FormattedDropdown({ status, dropDownOptions, onClick }) {
  const [selected, setSelected] = useState(dropDownOptions[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <Listbox.Label className="sr-only">
        Change activation status
      </Listbox.Label>
      <div className="relative">
        <div className="inline-flex shadow-sm rounded-md ">
          <div className="relative z-0 inline-flex shadow-sm rounded-md border border-gray-200">
            <Listbox.Button className="relative inline-flex items-center  p-1 rounded-l-none rounded-r-md text-sm font-medium text-black  focus:outline-none ">
              <div className="relative w-24 inline-flex items-center  py-1  border border-transparent rounded-l-md shadow-sm text-black">
                <span className="ml-2.5 text-sm font-normal sapphire">
                  {status}
                </span>
              </div>
              <ArrowDownIcon className="h-5 w-5 sapphire" aria-hidden="true" />
            </Listbox.Button>
          </div>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          // className="z-50 w-44"
        >
          <Listbox.Options className="absolute z-40 right-0 mt-2 w-64 rounded-lg shadow-lg bg-white divide-y  divide-gray-200  focus:outline-none">
            {dropDownOptions.map((option) => (
              <Listbox.Option
                key={option.title}
                className={({ active }) =>
                  ClassNames(
                    active && option?.title + "d" !== status
                      ? "text-white bg-blue-chill cursor-pointer font-semibold"
                      : active &&
                          option?.title + "d" === status &&
                          "bg-gray-400 cursor-not-allowed",
                    "cursor-default select-none relative p-4 text-sm "
                  )
                }
                value={option}
              >
                {({ selected }) => (
                  <div
                    className="flex flex-col"
                    onClick={() => {
                      if (option?.title + "d" === status) {
                        return;
                      } else onClick?.(option.title);
                    }}
                  >
                    <span>{option.title}</span>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
