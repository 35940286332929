import { ReactComponent as PlusSmIcon } from "Assets/Plus.svg";
import ClassNames from "Helpers/Common";
import 'Colors.css'
export const Divider = ({onClick,isDisabled}) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <button
          type="button"
          onClick={onClick}
          disabled={isDisabled}
          className={ClassNames(
            "inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full focus:outline-none sapphire",
            isDisabled ? " bg-gray-300" : "bg-white"
          )}
        >
          <PlusSmIcon
            className="-ml-1.5 mr-1 h-4 w-4 light_grayish_navy"
            aria-hidden="true"
          />
          <span>Add</span>
        </button>
      </div>
    </div>
  );
};
