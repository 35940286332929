import Admin from "Containers/Admins";
import SignIn from "Containers/Login";
import Patients from "Containers/Patients";
import Provider from "Containers/Providers";
import ProtectedRoute from "ProtectedRoutes";
import { AdminProvider } from "Context/Admin";
import { LoginProvider } from "Context/Login";
import { TokenProvider } from "Context/Global";
import { Dashboard } from "Containers/Dashboard";
import { PatientProvider } from "Context/Patient";
import { GetToken } from "Constants/LocalStorage";
import { DoctorsProvider } from "Context/Provider";
import { ProviderForm } from "Context/ProviderForm";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MembershipPricing } from "Pages/MembershipPricing/membershipPricing";
import { MedConditionsNames } from "Containers/MedicalConditionsNames/MedConditionsNames";
import "./App.css";
import { IntakeFormContainer } from "Containers/IntakeForms/IntakeFormContainer";

function App() {
  const isAuthenticated = GetToken();
  return (
    <BrowserRouter>
      <TokenProvider>
        <Switch>
          <ProtectedRoute path="/dashboard" component={() => <Dashboard />} />
          <ProtectedRoute 
            path="/membershipPlan"
            component={() => <MembershipPricing />}
          />
              <ProtectedRoute 
            path="/intakeForms"
            component={() => <IntakeFormContainer />}
          />
          
          <ProtectedRoute
            path="/provider"
            component={() => (
              <DoctorsProvider>
                <ProviderForm>
                  <Provider />
                </ProviderForm>
              </DoctorsProvider>
            )}
          />
          <ProtectedRoute
            path="/patient"
            component={() => (
              <PatientProvider>
                <Patients />
              </PatientProvider>
            )}
          />

          <ProtectedRoute
            path="/meds"
            component={() => <MedConditionsNames />}
          />
          <ProtectedRoute
            path="/admin"
            component={() => (
              <AdminProvider>
                <Admin />
              </AdminProvider>
            )}
          />

          <Route
            exact
            path="/"
            render={() =>
              isAuthenticated ? (
                <Dashboard />
              ) : (
                <LoginProvider>
                  <SignIn />
                </LoginProvider>
              )
            }
          />
          <Redirect from="*" to="/" />
        </Switch>
      </TokenProvider>
    </BrowserRouter>
  );
}

export default App;
