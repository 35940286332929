import ModalBasic from "Components/Modal/Modal";
import { ReactComponent as CreditCardIcon } from "Assets/CreditCard.svg";
import { ReactComponent as CalendarIcon } from "Assets/Calendar.svg";
import { ReactComponent as DollarIcon } from "Assets/Dollar.svg";
import "Colors.css";
import Table from "Components/Common/Table/Table";

const HEADING_CSS =
  "flex flex-col gap-2 text-md font-medium light_grayish_navy";
const SUB_HEADING_CSS = "flex gap-2 items-center text-md font-normal sapphire";
const TABLE_HEADINGS = [
  "Refunded Amount",
  "Remaining Balance",
  "Date and Time",
];

export const PaymentDetailsModal = ({
  isModalOpen,
  onCancel,
  paymentDetails,
}) => {
  return (
    <ModalBasic
      icon={<CreditCardIcon />}
      heading="Payment Details"
      open={isModalOpen}
      setOpen={() => {
        onCancel();
      }}
    >
      <div className="flex flex-col gap-6 ">
        <span className={HEADING_CSS}>
          Memberships
          <span className={SUB_HEADING_CSS}>{paymentDetails.memberShip}</span>
        </span>
        <span className={HEADING_CSS}>
          Amount{" "}
          <span className={SUB_HEADING_CSS}>
            {" "}
            <DollarIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />
            {paymentDetails.amount}
          </span>{" "}
          <span className={SUB_HEADING_CSS}>
            <CalendarIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />{" "}
            {paymentDetails.date}
          </span>{" "}
        </span>
        <div className="overflow-y-auto overflow-x-hidden h-72 2xl:h-96">
          {paymentDetails?.refunds.length > 0 && (
            <Table
              heading={<TableHeading />}
              body={
                paymentDetails?.refunds?.length > 0 ? (
                  <TableBody data={paymentDetails?.refunds} />
                ) : (
                  "No Data Found"
                )
              }
            />
          )}
        </div>
      </div>
    </ModalBasic>
  );
};

const TableHeading = () => {
  return TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
    </th>
  ));
};
const TableBody = ({ data }) => {
  const TABLE_ROW =
    "px-6 py-4 whitespace-nowrap text-sm font-normal text-black";

  return (
    <>
      {data?.map((invoice, index) => (
        <tr key={index}>
          <td className={TABLE_ROW}>
            {invoice.refundAmount ? (
              <span className="flex items-center">
                <DollarIcon className="w-5 h-5 light_grayish_navy pointer-events-none" />{" "}
                {invoice.refundAmount}
              </span>
            ) : (
              "- - -"
            )}
          </td>
          <td className={TABLE_ROW}>
            {invoice.remainingAmount ? (
              <span className="flex items-center">
                <DollarIcon className="w-5 h-5 light_grayish_navy pointer-events-none" />{" "}
                {invoice.remainingAmount}
              </span>
            ) : (
              "- - -"
            )}
          </td>

          <td className="flex flex-col gap-2 px-6 py-4 whitespace-nowrap text-sm font-normal text-black">
            <span>{invoice.date}</span>
            <span>{invoice.time}</span>
          </td>
        </tr>
      ))}
    </>
  );
};
