import Axios from "Services/Auth/AxiosInterceptors";

export const SaveGeneralInfo = async (payload) => {
  return await Axios.post(`/doctor/doctor/admin-signsup-doctor`, payload);
};
export const ProviderMedLic = async (id, payload) => {
  console.log("ProviderMedLic Services", id, payload);
  return await Axios.post(
    `/statemedicallicense/statemedicallicense/add/${id}`,
    payload
  );
};
export const AddEducationDetail = async (id, payload) => {
  return await Axios.post(`/education/education/add/${id}`, payload);
};

export const AddMalpracticeDetail = async (id, payload) => {
  return await Axios.post(
    `/medicalmalpracticeinsurance/medicalmalpracticeinsurance/add/${id}`,
    payload
  );
};
export const ProviderSecurityInfo = async (id, payload) => {
  return await Axios.post(`/security/security/add/${id}`, payload);
};
export const AddTrainingDetail = async (id, payload) => {
  return await Axios.post(`/training/training/add/${id}`, payload);
};

export const ProviderDEAInfo = async (id, payload) => {
  return await Axios.post(`/dea/dea/add/${id}`, payload);
};
export const ProviderFellowShip = async (id, payload) => {
  /**


@param id {String} will be of the adding new provider

@param payload {object} will contain different information of the fellowship of provider



**/
  return await Axios.post(`/fellowship/fellowship/add/${id}`, payload);
};

export const AddUserFiles = async (id, payload) => {
  return await Axios.post(`/files/file/add/${id}`, payload);
};
