/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { InputField } from "Components/Common/InputField/InputField";
import "Colors.css";

export const FellowshipForm = ({
  postfix,
  setIsDisabled,
  setFormDetail,
  formDetail,
  onRemove,
}) => {
  const [speciality, setSpecialty] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [programName, setProgramName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  useEffect(() => {
    const updatedFormDetail = (arr, newformInstance) => [
      ...arr.filter((formInstance) => formInstance.id !== newformInstance.id),
      { ...newformInstance },
    ];
    if (
      speciality &&
      directorName &&
      programName &&
      streetAddress &&
      city &&
      zipCode &&
      uploadedFile
    ) {
      setFormDetail(
        updatedFormDetail(formDetail, {
          id: postfix,
          speciality,
          fellowshipDirectorName: directorName,
          fellowshipProgramName: programName,
          fellowshipProgramStreetAddress: streetAddress,
          fellowshipProgramCity: city,
          fellowshipProgramZipcode: zipCode,
          fellowshipCertificateDocument: uploadedFile,
        })
      );
    }
    setIsDisabled(
      !(
        speciality &&
        directorName &&
        programName &&
        streetAddress &&
        city &&
        zipCode &&
        uploadedFile
      )
    );
  }, [
    speciality,
    directorName,
    streetAddress,
    programName,
    city,
    uploadedFile,
  ]);
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <span className="text-base font-medium sapphire">
          Fellowship {postfix}{" "}
          <span className="light_grayish_navy font-normal text-xs">
            (Optional)
          </span>
        </span>
        {postfix > 1 && (
          <span
            onClick={() => onRemove(postfix)}
            className="text-blue-light text-sm cursor-pointer"
          >
            Remove
          </span>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="specialty"
            label="Speciality"
            onChange={(fieldValue) =>
              fieldValue ? setSpecialty(fieldValue) : ""
            }
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="fellowship-director-name"
            label="Fellowship Director Name"
            onChange={(fieldValue) =>
              fieldValue ? setDirectorName(fieldValue) : ""
            }
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="program-name"
            label="Program Name"
            isRequired={true}
            onChange={(fieldValue) =>
              fieldValue ? setProgramName(fieldValue) : ""
            }
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="street-address"
            label="Program Street Address"
            onChange={(fieldValue) =>
              fieldValue ? setStreetAddress(fieldValue) : ""
            }
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="city"
            label="Program City"
            onChange={(fieldValue) => (fieldValue ? setCity(fieldValue) : "")}
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="zip-code"
            label="Zip Code"
            onChange={(fieldValue) =>
              fieldValue ? setZipCode(fieldValue) : ""
            }
          />
        </div>
        <div className="col-span-2">
          <UploadFile
            id={postfix}
            mode="Edit"
            label="Fellowship Certificate"
            onBlur={() => {}}
            onResponse={(response) => {
              setUploadedFile(response?.data || "");
            }}
          />
        </div>
      </div>
    </div>
  );
};
