/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { formatDate } from "Helpers/DateFormat";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import { InputField } from "Components/Common/InputField/InputField";
import "Colors.css";

export const DEAForm = ({
  postfix,
  states,
  setIsDisabled,
  setFormDetail,
  formDetails,
  onRemove,
}) => {
  const [state, setState] = useState("");
  const [regNum, setRegNum] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");

  useEffect(() => {
    const updatedFormDetail = (arr, newformInstance) => [
      ...arr.filter((formInstance) => formInstance.id !== newformInstance.id),
      { ...newformInstance },
    ];
    if (state && regNum && issueDate && expiryDate && uploadedFile) {
      setFormDetail(
        updatedFormDetail(formDetails, {
          id: postfix,
          deaState: state,
          deaNumber: regNum,
          deaExpiration: formatDate(expiryDate, "MM/DD/YYYY"),
          deaDate: formatDate(issueDate, "MM/DD/YYYY"),
          deaDocument: uploadedFile,
        })
      );
    }
    setIsDisabled(
      !(state && regNum && issueDate && expiryDate && uploadedFile)
    );
  }, [state, regNum, expiryDate, issueDate, uploadedFile]);
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <span className="text-base font-medium sapphire">
          DEA Registeration {postfix}{" "}
          <span className="light_grayish_navy font-normal text-xs">
            (Optional)
          </span>
        </span>
        {postfix > 1 && (
          <span
            onClick={() => onRemove(postfix)}
            className="text-blue-light text-sm cursor-pointer"
          >
            Remove
          </span>
        )}
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <Dropdown
            id="select-state"
            label="DEA State"
            onBlur={(fieldValue) => (fieldValue ? setState(fieldValue) : "")}
          >
            <Dropdown.Option
              id={-1}
              value="-1"
              label=""
              isDefaultOption={true}
            />
            {states.length > 0 &&
              states.map((state) => (
                <Dropdown.Option
                  key={state.id}
                  id={state.id}
                  value={state.id}
                  label={state.name}
                />
              ))}
          </Dropdown>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <InputField
            id="registration-number"
            label="DEA Registration Number"
            onBlur={(fieldValue) => (fieldValue ? setRegNum(fieldValue) : "")}
          />
        </div>
        <div className="col-span-1">
          <DatePicker
            label="DEA Issue Date"
            value={issueDate}
            onBlur={(issueDate) => {
              setIssueDate(issueDate || "");
            }}
          />
        </div>
        <div className="col-span-1">
          <DatePicker
            label="DEA Expiration"
            value={expiryDate}
            onBlur={(expDate) => {
              setExpiryDate(expDate || "");
            }}
          />
        </div>

        <div className="col-span-2">
          <UploadFile
            mode="Edit"
            id={postfix}
            label="DEA Card"
            onBlur={() => {}}
            onResponse={(response) => {
              setUploadedFile(response?.data || "");
            }}
          />
        </div>
      </div>
    </div>
  );
};
