export const formatAdminStatus=(status)=> {
  switch (status) {
    case "Disabled":
      return "Deactivated";
    case "Enabled":
      return "Activated";
    default:
      throw new Error(
        `Unregistered status "${status}" found in admin record`
      );
  }
}
export const adminsFormatter = (data) => {
  
  const formattedResult = data.map((list) => {
    return {
      id: list?.id,
      name: `${list?.firstName} ${list?.lastName}` || "",
      username: list?.username || "",
      actionStatus: formatAdminStatus(list?.status) || "",
    };
  });
  return formattedResult;
};
