import { UploadFile } from "Components/Common/UploadFile/UploadFile";
import "Colors.css";

const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";

export const ViewForm = ({ providerFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Board Certified</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.certified ? "Yes" : "NO"}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Board Certified With</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.certificationWith}
        </span>
      </div>

      <div className="flex flex-col col-span-2 gap-2">
        <UploadFile
          value={providerFormDetail?.certificationDocument}
          label="Board Certificate"
        />
      </div>

      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Medical School Attended</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.medSchoolName}
        </span>
      </div>

      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Medical School Graduation Date</span>
        <span className={SUB_HEADING_CSS}>
          {providerFormDetail?.graduationDate}
        </span>
      </div>

      <div className="flex flex-col col-span-2 gap-2">
        <UploadFile
          mode="View"
          value={providerFormDetail?.degreeDocument}
          label="Medical Degree"
        />
      </div>
    </div>
  );
};
