import Axios from "Services/Auth/AxiosInterceptors";

/**
 * @description  An API for getting Admins
 * @param {Number} page Current page number
 * @returns
 */
export const GetAdmins = async (page, recordsPerPage) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/admin/get?page=${page}&perPage=${recordsPerPage}`
  );
  return result;
};
/**
   @description An API for fetching the Admin Information
   @param id {String} will be of the selected admin
   @param response {Object} will return the details of the admin

**/
export const GetAdminDetail = async (id) => {
  const { data: result } = await Axios.get(`/admin/admin/get/${id}`);
  return result;
};
/**
 @description An API for fetching the Admin Informatio
 @param id {String} will be of the selected admin
 @param payload {Object} updated information of the admin
Payload Example {
    "firstName": "",
    "lastName": "",
    "providerProfileAccessType": "Edit",
    "adminProfileAccessType": "Edit"
}
**/
export const UpdateAdminDetail = async (id, payload) => {
  const { data: result } = await Axios.patch(
    `/admin/admin/profile/update/${id}`,
    payload
  );
  return result;
};
/**
 * @description  An API for getiing access logs
 * @param {String} id of the admin
 * @param {Number} page  Current page number
 * @returns
 */
export const GetAccessLogs = async (id, page, recordsPerPage = 8) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/accesslogs/admin/${id}?page=${page}&perPage${recordsPerPage}`
  );
  console.log("[GetAccessLogs][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description  An API for activating the admin
 * @param {String} email of the admin
 * @returns
 */
export const AdminActivate = async (email) => {
  return await Axios.post(`/admin/admin/activate`, email);
};
/**
 * @description  An API for deactivate the admin
 * @param {String} email of the admin
 * @returns
 */
export const AdminDeactivate = async (email) => {
  return await Axios.post(`/admin/admin/deactivate`, email);
};

/**
 * @description  An API for moving an admin to the archive
 * @param {String} id of the admin
 */
export const MoveToArchive = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/admin/admin/archive/${id}`);

  return result;
};
export const GetAdminPersonalDetails = async () => {
  const {
    data: { result },
  } = await Axios.get(`/admin/admin/admin-self-profile/get`);
  console.log("[GetAdminPersonalDetails][API_RESPONSE] =>", result);
  return result;
};

