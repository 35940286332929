import { InputField } from "Components/Common/InputField/InputField";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { phoneNumberFormatter } from "Helpers/Common";

const EDIT = "Edit";
const VIEW = "View";

export const EditForm = ({ adminFormDetail, setAdminFormDetail }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="first-name"
          label="First Name"
          value={adminFormDetail?.firstName}
          onBlur={(fieldValue) => {
            setAdminFormDetail({
              ...adminFormDetail,
              firstName: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>

      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="last-name"
          label="Last Name"
          value={adminFormDetail?.lastName}
          onBlur={(fieldValue) => {
            setAdminFormDetail({
              ...adminFormDetail,
              lastName: fieldValue && fieldValue.trim(),
            });
          }}
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="email"
          label="Email"
          value={adminFormDetail?.email}
          isDisabled={true}
        />
      </div>

      <div className="col-span-2 lg:col-span-1">
        <InputField
          id="phone-num"
          type="tel"
          label="Phone Number"
          value={adminFormDetail?.phoneNumber}
          onBlur={(fieldValue) => {
            setAdminFormDetail({
              ...adminFormDetail,
              phoneNumber: fieldValue ? phoneNumberFormatter(fieldValue) : "",
            });
          }}
        />
      </div>
      <fieldset className="mt-4 space-y-6">
        <div className="space-y-2">
          <label className="font-semibold sapphire">
            Provider Profile Access Type
          </label>
          <RadioButton
            id="1"
            label="Can Edit"
            value={adminFormDetail?.providerAccess}
            isSelected={adminFormDetail?.providerAccess === EDIT}
            onChange={() => {
              setAdminFormDetail({
                ...adminFormDetail,
                providerAccess: EDIT,
              });
            }}
          />
          <RadioButton
            id="2"
            label="Can View"
            value={adminFormDetail?.providerAccess}
            isSelected={adminFormDetail?.providerAccess === VIEW}
            onChange={() => {
              setAdminFormDetail({
                ...adminFormDetail,
                providerAccess: VIEW,
              });
            }}
          />
        </div>
        <div className="space-y-2">
          <label className="font-semibold sapphire">
            Patient Profile Access Type
          </label>
          <RadioButton
            id="3"
            label="Can Edit"
            value={adminFormDetail?.patientAccess}
            isSelected={adminFormDetail?.patientAccess === EDIT}
            onChange={() =>
              setAdminFormDetail({
                ...adminFormDetail,
                patientAccess: EDIT,
              })
            }
          />
          <RadioButton
            id="4"
            label="Can View"
            value={adminFormDetail?.patientAccess}
            isSelected={adminFormDetail?.patientAccess === VIEW}
            onChange={() =>
              setAdminFormDetail({
                ...adminFormDetail,
                patientAccess: VIEW,
              })
            }
          />
        </div>
      </fieldset>
    </div>
  );
};
