import ClassNames from "Helpers/Common";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { ReactComponent as ForwardIcon } from "Assets/ForwardArrow.svg";
import "Colors.css";

export const PaginationBtns = ({
  onNext,
  onPrevious,
  isNextDisabled,
  isPrevDisabled,
}) => {
  return (
    <div className="flex justify-between text-sm font-medium cursor-pointer">
      <span
        className={ClassNames(
          "flex gap-2 items-center",
          isPrevDisabled ?  "light_grayish_navy" : "text-blue-chill"
        )}
        onClick={onPrevious}
      >
        <BackIcon className="h-4 w-4" />
        Previous
      </span>
      <span
        className={ClassNames(
          "flex gap-2 items-center",
          isNextDisabled ?  "light_grayish_navy" : "text-blue-chill"
        )}
        onClick={onNext}
      >
        Next
        <ForwardIcon className="h-4 w-4" />
      </span>
    </div>
  );
};
