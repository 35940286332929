import { BASE_PROVIDER_PATH } from "Constants/Views";
import { useProviderContext } from "Context/Provider";
import DetailsLayout from "Layouts/DetailsLayout/DetailLayout";
import { Content } from "Components/Provider/AddProvider/Content/Content";
import { TabsPanel } from "Components/Provider/TabsPanel/TabsPanel";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import "Pages/Provider/DetailPageCss/DetailPage.css";
import { useEffect } from "react";

export function TheAddProviderPage() {
  const { setActiveView } = useProviderContext();
  const [activeTabIndex, setActiveTabIndex] = useLocalStorage(
    "addProviderFromStep",
    0
  );
  useEffect(() => {
    setActiveTabIndex(0);
  }, []);
  return (
    <>
      <DetailsLayout
        leftColumn={
          <TabsPanel
            onBack={() => setActiveView(BASE_PROVIDER_PATH)}
            activeTabIndex={activeTabIndex}
            addProviderFlow={true}
          />
        }
      >
        <div className="DetailPage_Wrapper flex flex-col gap-4 overflow-x-hidden">
          <Content
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            addProviderFlow={true}
          />
        </div>
      </DetailsLayout>
    </>
  );
}
